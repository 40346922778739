import { Component, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { map } from 'rxjs';
import { FnCaricamentoDati, Filters, SortBy } from 'src/app/components/lista-tabellare/classes/lista-tabellare-data-source';
import { ListaTabellareComponent, Filtri, Colonna, BottoniListaEvent } from 'src/app/components/lista-tabellare/lista-tabellare.component';
import { AzioneBP, AzioneEBuonaPraticaService } from 'src/app/services/azione-e-buona-pratica/azione-e-buona-pratica.service';
import { SettoriService } from 'src/app/services/settori/settori.service';
import { UtilityService } from 'src/app/services/utility/utility.service';
import { DialogCreaCfgAzioneEBuonaPraticaComponent } from './dialog/dialog-crea-cfg-azione-e-buona-pratica/dialog-crea-cfg-azione-e-buona-pratica.component';
import { TematicheService } from 'src/app/services/tematiche/tematiche.service';
import { DialogImportazioneExcelComponent } from '../../analisi-materialita/stakeholder/dialog/dialog-importazione-excel/dialog-importazione-excel.component';
import { UtenteService } from 'src/app/services/utente/utente.service';
import { SpinnerOverlayComponent } from 'src/app/components/spinner-overlay/spinner-overlay.component';

@Component({
  selector: 'app-cfg-azione-e-buona-pratica',
  templateUrl: './cfg-azione-e-buona-pratica.component.html',
  styleUrls: ['./cfg-azione-e-buona-pratica.component.scss']
})
export class CfgAzioneEBuonaPraticaComponent {
  @ViewChild('tabella') tabella!: ListaTabellareComponent;
  @ViewChild(SpinnerOverlayComponent) spinnerOver!: SpinnerOverlayComponent;

  public filtri: Filtri[] = [

    {
      titolo: 'Titolo',
      forControlName: 'titolo',
      input: 'text',
      larghezza: 1,
      riempi: true
    },
    {
      titolo: 'Descrizione',
      forControlName: 'descrizione',
      input: 'text',
      larghezza: 1,
      riempi: true
    },
    {
      forControlName: 'settori',
      input: 'multiple-option',
      titolo: 'Settore',
      fnDatiOption: () => (this.settoriService.getSettori(0, 1000)).pipe(map((value) => value?.content || [])),
      descValueOption: 'titolo',
      idValueOption: 'id',
      riempi: true,
      larghezza: 1
    },
    {
      forControlName: 'stdr',
      input: 'multiple-option',
      titolo: 'Standard di rendicontazione',
      fnDatiOption: () => (this.tematicaService.getStdr()),
      descValueOption: 'descrizione',
      idValueOption: 'chiave',
      riempi: true
    },
    {
      titolo: 'Note',
      forControlName: 'note',
      input: 'text',
      riempi: true
    },
    {
      titolo: 'TAG',
      forControlName: 'tag',
      input: 'multiple-option-chip',
      riempi: true,
      larghezza: 3,
      fnDatiOption: (ricerca: string | undefined) => this.azioneBPService.getAzioneBPTags(0, 1000, ricerca)
    },
  ];

  public colonne: { [key: string]: Colonna } = {

    titolo: {
      title: 'Titolo',
      value: 'titolo',
      sortable: true
    },
    modifica: {
      type: 'button',
      buttonIcon: 'edit',
      title: 'Modifica',
      buttonId: 'modifica',
      buttonMostraSempre: true,
      nascondiButton: (record: any) => (!this.utenteService.isPermessoAttivo('EDIT_CONFIGURAZIONI'))
    },
    /*   duplica: {
        type: 'button',
        buttonIcon: 'content_copy',
        title: 'Duplica',
        buttonId: 'duplica',
      }, */
    elimina: {
      type: 'button',
      buttonIcon: 'delete',
      title: 'Elimina',
      buttonId: 'elimina',
      nascondiButton: (record: any) => (!this.utenteService.isPermessoAttivo('DELETE_CONFIGURAZIONI'))
    },
  };
  constructor(
    public dialog: MatDialog,
    private azioneBPService: AzioneEBuonaPraticaService,
    private utilityService: UtilityService,
    private settoriService: SettoriService,
    private tematicaService: TematicheService,
    public utenteService: UtenteService
  ) { }

  ngOnInit(): void {

  }

  /**
   * Apertura dialog filtri passati da l'esterno
   */
  creaNuovoAzioneBP(recordAzioneBP?: AzioneBP) {
    const dialogCreaAzioneBP = this.dialog.open(DialogCreaCfgAzioneEBuonaPraticaComponent, {
      data: {
        azioneBP: recordAzioneBP,
      },
      panelClass: 'dialog-container',
      disableClose: false,
      width: '95%',
      maxHeight: '95%',
      autoFocus: false,
    });

    dialogCreaAzioneBP.afterClosed().subscribe((result) => {

      this.tabella.caricaDati();
    });
  }

  public fnCaricamentoDati: FnCaricamentoDati = (
    page: number,
    pageSize: number,
    ricerca?: string,
    filters?: Filters[],
    sortBy?: SortBy[]
  ) => {
    const azioneBPSortBy: SortBy = {
      chiave: 'titolo',
      sort: 'asc'
    };
    const actualSortBy = sortBy ? sortBy : [azioneBPSortBy];

    return this.azioneBPService.getAzioneBP(page, pageSize, ricerca, filters, actualSortBy);
  };

  public bottoniListaClick(event: BottoniListaEvent) {
    switch (event.id) {
      case 'modifica':
        this.creaNuovoAzioneBP(event.data);
        break;
      case 'elimina':
        this._eliminaAzioneBP(event.data.id);
        break;
      case 'duplica':
        this._duplicaAzioneBP();
        break;
      default:
        console.warn('ATTENZIONE: bottone ' + event.id + ' non riconosciuto');
    }
  }

  /**
   * Funzione per eliminare una riga da una lista tabellare
   * @param idRiga id della riga da eliminare
   */
  private _eliminaAzioneBP(idRiga: any) {
    this.utilityService.openDialog({
      descrizione: 'Confermi di eliminare l\'AzioneBP?',
      bottoni: [
        {
          nome_btn: 'NO',
          background_color: 'var(--mat-color-accent)',
        },
        {
          nome_btn: 'SI', background_color: 'var(--mat-color-primary)', handler: () => {
            this.spinnerOver.show();
            this.azioneBPService.deleteAzioneBP(idRiga).subscribe(
              {
                next: (result: any) => {

                  this.tabella.caricaDati();
                  this.spinnerOver.hide();
                },
                error: (err: any) => {
                  this.spinnerOver.hide();
                  this.utilityService.openDialog({
                    titolo: 'Attenzione',
                    descrizione: 'Errore eliminazione' + err,
                    bottoni: [
                      {
                        nome_btn: 'Chiudi', handler: () => {
                          this.tabella.caricaDati();
                        }
                      }]
                  });
                }
              }
            );
          }
        }]
    });

  }
  /**
   * Metodo per la duplicazione di un AzioneBP
   */
  public _duplicaAzioneBP() {
    this.utilityService.openDialog({
      titolo: 'Attenzione',
      descrizione: 'Da implementare la duplicazione ',
      bottoni: [
        {
          nome_btn: 'Chiudi', handler: () => {
            this.utilityService.closeDialog();
          }
        }]
    })
  }

  public importaAzioniBP() {
    const dialogImportaAzioniBP = this.dialog.open(DialogImportazioneExcelComponent, {
      data: {
        titoloImportazione: 'Importazione Azioni e Buone Pratiche',
        subTitoloImportazione: 'Scarica il file di template excel o fai una nuova importazione delle Azioni e Buone Pratiche',
        pathTemplateExcel: 'assets/excel/azioniEBuonePratiche.xlsx',
        azienda: false,
        nomeImport: 'azioni-e-buone-pratiche',
      },
      panelClass: 'dialog-container',
      disableClose: false,
      width: '95%',
      /* maxWidth: '90%', */
      maxHeight: '95%',
      autoFocus: false,
    });

    dialogImportaAzioniBP.afterClosed().subscribe((result) => {

      this.tabella.caricaDati();
    });
  }
}
