import { AfterViewInit, Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { catchError, map, Observable, of, throwError } from 'rxjs';
import { SpinnerOverlayComponent } from 'src/app/components/spinner-overlay/spinner-overlay.component';
import { Ambito } from 'src/app/services/ambiti/ambiti.service';
import { BilancioService } from 'src/app/services/bilancio/bilancio.service';
import { PianoService } from 'src/app/services/piano/piano.service';
import { PolicyService } from 'src/app/services/policy/policy.service';
import { SintesiService } from 'src/app/services/sintesi/sintesi.service';
import { Tematica } from 'src/app/services/tematiche/tematiche.service';
import { UtilityService } from 'src/app/services/utility/utility.service';

@Component({
  selector: 'app-dialog-compila-sintesi-sostenibilita',
  templateUrl: './dialog-compila-sintesi-sostenibilita.component.html',
  styleUrls: ['./dialog-compila-sintesi-sostenibilita.component.scss']
})
export class DialogCompilaSintesiSostenibilitaComponent implements OnInit, AfterViewInit {

  @ViewChild(SpinnerOverlayComponent) spinnerOver!: SpinnerOverlayComponent;

  public compilazione: any;
  public esercizio!: number;
  public idContesto: string = '';
  public titolo: string = '';
  public risposta: any;
  public rispostaBreve: any;
  public anno: string= '';
  constructor(
    public dialogRef: MatDialogRef<DialogCompilaSintesiSostenibilitaComponent>,
    private sintesiService: SintesiService,
    private bilancioService: BilancioService,
    private policyService: PolicyService,
    private pianoService: PianoService,
    private utilityService: UtilityService,
    @Inject(MAT_DIALOG_DATA) public data: {
      idSintesiSostenibilita: string,
      idBilancioSostenibilita: string,
      idPolicySostenibilita: string,
      idPianoSostenibilita: string,
      idAmbito: string,
      idTematica: string,
      idElemento: string,
      valueToggleAnni: boolean
      idObiettivo: string,
    }) {
  }

  ngOnInit(): void {

  }

  ngAfterViewInit(): void {
    setTimeout(() => {

      this.spinnerOver.show();
      if (this.data.idSintesiSostenibilita) {
        this.titolo = 'Sintesi di Sostenibilità';
        this.idContesto = this.data.idSintesiSostenibilita;
        this.sintesiService.getElementoDaCompilare(
          this.data.idSintesiSostenibilita,
          this.data.idAmbito,
          this.data.idTematica,
          this.data.idElemento
        ).subscribe({
          next: (value) => {

            this.esercizio = (value?.esercizio?.length ? value?.esercizio[0] : 2024);
            this.compilazione = value;
            this.spinnerOver.hide();
          },
          error: (err) => {
            this.spinnerOver.hide();
            console.error(err);

            this.dialogRef.close();
            this.utilityService.opneSnackBar('Errore caricamento', '', {
              duration: 2000,
              panelClass: ['red-snackbar']
            });
          }
        })
      } else if (this.data.idBilancioSostenibilita) {
        this.titolo = 'Bilancio di Sostenibilità';
        this.idContesto = this.data.idBilancioSostenibilita;
        this.bilancioService.getElementoDaCompilare(
          this.data.idBilancioSostenibilita,
          this.data.idAmbito,
          this.data.idTematica,
          this.data.idElemento
        ).subscribe({
          next: (value) => {

            this.esercizio = (value?.esercizio?.length ? value?.esercizio[0] : 2024);
            this.compilazione = value;
            this.spinnerOver.hide();
          },
          error: (err) => {
            this.spinnerOver.hide();
            console.error(err);
            this.dialogRef.close();
            this.utilityService.opneSnackBar('Errore caricamento', '', {
              duration: 2000,
              panelClass: ['red-snackbar']
            });
          }
        })
      } else if (this.data.idPolicySostenibilita) {
        this.titolo = 'Policy di Sostenibilità';
        this.idContesto = this.data.idPolicySostenibilita;
        this.policyService.getElementoDaCompilare(
          this.data.idPolicySostenibilita,
          this.data.idAmbito,
          this.data.idTematica,
          this.data.idElemento
        ).subscribe({
          next: (value) => {

            this.esercizio = (value?.esercizio?.length ? value?.esercizio[0] : 2024);
            this.compilazione = value;
            this.spinnerOver.hide();
          },
          error: (err) => {
            this.spinnerOver.hide();
            console.error(err);
            this.dialogRef.close();
            this.utilityService.opneSnackBar('Errore caricamento', '', {
              duration: 2000,
              panelClass: ['red-snackbar']
            });
          }
        })
      } else if (this.data.idPianoSostenibilita) {
        this.titolo = 'Piano di Sostenibilità';
        this.idContesto = this.data.idPianoSostenibilita;
        this.pianoService.getElementoDaCompilare(
          this.data.idPianoSostenibilita,
          this.data.idAmbito,
          this.data.idTematica,
          this.data.idElemento,
          this.data.idObiettivo,
        ).subscribe({
          next: (value) => {

            this.esercizio = (value?.esercizio?.length ? value?.esercizio[0] : 2024);
            this.compilazione = value;
            this.spinnerOver.hide();
          },
          error: (err) => {
            this.spinnerOver.hide();
            console.error(err);
            this.dialogRef.close();
            this.utilityService.opneSnackBar('Errore caricamento', '', {
              duration: 2000,
              panelClass: ['red-snackbar']
            });
          }
        })
      }
    }, 0)
  }

  loading = (loading: boolean) => {
    if (loading) {
      this.spinnerOver.show();
    } else {
      this.spinnerOver.hide();
    }
  }


  /**
   * Metodo che mi salva la compilazione dell'eleemento (kpi-azione-impegni-impatti-certificazione)
   * @param compilazione oggetto con l'elemento compilato 
   * @param isImportazione se sono in importazione 
   * @returns 
   */
  finitaCompilazione = (compilazione: any, isImportazione?: boolean): Observable<any> => {
    if (compilazione) {

      const tematica: Tematica = this.compilazione.ambiti[0].tematiche[0];
      const ambito: Ambito = this.compilazione.ambiti[0];

      const objCall = {
        idAmbito: ambito.id,
        idTematica: tematica.id,
        idElemento: '',
        idUnitaDiRiferimento: '',
        idCategoriaUnitaDiMisura: '',
        risposta: '',
        rispostaBreve: '',
        anno: '',
      }

      if (tematica.kpis?.length) {

        const kpi = tematica.kpis[0];
        const valoriKpi = kpi.valoriKpi || [];
        const valoriTarget = kpi.valoriTarget || [];

        const risposta = [
          ...valoriKpi,
          ...valoriTarget
        ];

        Object.assign(objCall, {
          idElemento: tematica.kpis[0].id,
          idUnitaDiRiferimento: tematica.kpis[0].idUnitaDiRiferimento,
          idCategoriaUnitaDiMisura: tematica.kpis[0].idCategoriaUnitaDiMisura,
          risposta: risposta,
        });




      } else {

        if (tematica.impegni?.length) {
          Object.assign(objCall, {
            idElemento: tematica.impegni[0].id,
            risposta: tematica.impegni[0].risposta
          });
        }

        if (tematica.impatti?.length) {
          Object.assign(objCall, {
            idElemento: tematica.impatti[0].id,
            risposta: tematica.impatti[0].valoreAttribuito
          });
        }

        if (tematica.azioni?.length) {

          Object.assign(objCall, {
            idElemento: tematica.azioni[0].id,
            risposta: tematica.azioni[0].risposta,
            rispostaBreve: tematica.azioni[0].rispostaBreve,
            anno: tematica.azioni[0].anno,
          });
        }

        if (tematica.certificazioni?.length) {
          Object.assign(objCall, {
            idElemento: tematica.certificazioni[0].id,
            risposta: tematica.certificazioni[0].risposta
          });
        }

      }

      if (this.data.idSintesiSostenibilita) {

        return this.sintesiService.postElementoDaCompilare(
          this.data.idSintesiSostenibilita,
          objCall.idAmbito,
          objCall.idTematica,
          objCall.idElemento,
          objCall.risposta,
          objCall.idCategoriaUnitaDiMisura,
          objCall.idUnitaDiRiferimento
        ).pipe(
          map((risp) => {

            this.risposta = objCall.risposta;
            if (!isImportazione) {
              this.dialogRef.close({
                salvato: true,
                risposta: this.risposta
              })
            }

            catchError(error => {
              console.error(error);
              return throwError(() => error);
            })
          },
          )
        )

      }
      else if (this.data.idBilancioSostenibilita) {

        return this.bilancioService.postElementoDaCompilare(
          this.data.idBilancioSostenibilita,
          objCall.idAmbito,
          objCall.idTematica,
          objCall.idElemento,
          objCall.risposta,
          objCall.idCategoriaUnitaDiMisura,
          objCall.idUnitaDiRiferimento
        ).pipe(
          map(risp => {

            this.spinnerOver.hide();
            this.risposta = objCall.risposta;
            if (!isImportazione) {
              this.dialogRef.close({
                salvato: true,
                risposta: this.risposta
              });
            }

          }),
          catchError(error => {
            console.error(error);
            this.spinnerOver.hide();
            return of(error);
          })
        )
      }
      else if (this.data.idPolicySostenibilita) {

        return this.policyService.postElementoDaCompilare(
          this.data.idPolicySostenibilita,
          objCall.idAmbito,
          objCall.idTematica,
          objCall.idElemento,
          objCall.risposta,
          objCall.rispostaBreve,
          objCall.idCategoriaUnitaDiMisura,
          objCall.idUnitaDiRiferimento
        ).pipe(
          map(risp => {

            this.spinnerOver.hide();
            this.risposta = objCall.risposta;
            this.rispostaBreve = objCall.rispostaBreve;
            if (!isImportazione) {
              this.dialogRef.close({
                salvato: true,
                risposta: this.risposta,
                rispostaBreve: this.rispostaBreve

              });
            }

          }),
          catchError(error => {
            console.error(error);
            this.spinnerOver.hide();
            return of(error);
          })
        )
      }
      else if (this.data.idPianoSostenibilita) {

        return this.pianoService.postElementoDaCompilare(
          this.data.idPianoSostenibilita,
          objCall.idAmbito,
          objCall.idTematica,
          objCall.idElemento,
          objCall.risposta,
          objCall.rispostaBreve,
          objCall.anno,
          objCall.idCategoriaUnitaDiMisura,
          objCall.idUnitaDiRiferimento,
          this.data.idObiettivo,
        ).pipe(
          map(risp => {

            this.spinnerOver.hide();
            this.risposta = objCall.risposta;
            this.rispostaBreve = objCall.rispostaBreve;
            this.anno = objCall.anno;
            if (!isImportazione) {
              this.dialogRef.close({
                salvato: true,
                risposta: this.risposta,
                rispostaBreve: this.rispostaBreve,
                anno: this.anno

              });
            }

          }),
          catchError(error => {
            console.error(error);
            this.spinnerOver.hide();
            return of(error);
          })
        )
      }
    }
    return of();
  }
  /**
   * Metodo che mi chiude la dialog di compilazione e mi riporta i dati per visualizzarli dentro la lista delle compilazioni
   */
  chiudi() {
    if (this.risposta) {
      this.dialogRef.close({
        salvato: true,
        risposta: this.risposta,
      });

    } else {
      this.dialogRef.close();
    }
  }
}
