import { AfterViewInit, Component, Inject, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { SpinnerOverlayComponent } from 'src/app/components/spinner-overlay/spinner-overlay.component';
import { BilancioService } from 'src/app/services/bilancio/bilancio.service';
import { ImpegniService, Impegno } from 'src/app/services/impegni/impegni.service';
import { PolicyService } from 'src/app/services/policy/policy.service';
import { Settore, SettoriService } from 'src/app/services/settori/settori.service';
import { SintesiService } from 'src/app/services/sintesi/sintesi.service';
import { UtilityService } from 'src/app/services/utility/utility.service';

@Component({
  selector: 'app-dialog-crea-impegno',
  templateUrl: './dialog-crea-impegno.component.html',
  styleUrls: ['./dialog-crea-impegno.component.scss']
})
export class DialogCreaImpegnoComponent implements AfterViewInit {
  @ViewChild(SpinnerOverlayComponent) spinnerOver!: SpinnerOverlayComponent;

  public selectedSettori: string[] = [];

  public arraySettori: Settore[] = [];

  /* Form Controls */
  public formImpegni = new FormGroup({


    id: new FormControl<string | undefined>(undefined, {
      nonNullable: true,
      validators: [],
    }),

    titolo: new FormControl<string>('', {
      nonNullable: true,
      validators: [Validators.required],
    }),

    descrizione: new FormControl<string | undefined>(undefined, {
      nonNullable: true,
      validators: [],
    }),

    settori: new FormControl<Settore[]>([], {
      nonNullable: true,
    }),

    note: new FormControl<string>('', {
      nonNullable: true,
      validators: [],
    }),
    tag: new FormControl<string>('', {
      nonNullable: true,
      validators: [],
    }),
    creatoDaContesto: new FormControl<boolean>(false, {
      nonNullable: true,
      validators: [],
    }),
    selected: new FormControl<boolean>(false, {
      nonNullable: true,
      validators: [],
    }),
    compilato: new FormControl<boolean>(false, {
      nonNullable: true,
      validators: [],
    }),
    figura: new FormControl<any>(undefined, {
      nonNullable: true,
      validators: [],
    }),
  });

  constructor(
    public dialogRef: MatDialogRef<DialogCreaImpegnoComponent>,
    public impegnoService: ImpegniService,
    private utilityService: UtilityService,
    private settoriService: SettoriService,
    private sintesiService: SintesiService,
    private bilancioService: BilancioService,
    private policyService: PolicyService,

    @Inject(MAT_DIALOG_DATA) public data: {
      impegno: Impegno,
      idContest: string,
      idAmbito: string,
      idTematica: string,
      contesto: string,
    }
  ) {

    if (this.data?.impegno) {

      Object.keys(this.data.impegno).forEach((value, index, array) => {
        this.formImpegni.get(value)?.setValue((this.data.impegno as any)[value]);
      })

    }

    this._getSettori();
  }

  ngAfterViewInit(): void {

    setTimeout(() => {
      if (this.data.contesto == 'sintesi' || this.data.contesto == 'bilancio' || this.data.contesto == 'policy') {

        const descrizioneControl = this.formImpegni.get('descrizione');
        if (descrizioneControl) {
          descrizioneControl.setValidators([Validators.required]);
          descrizioneControl.updateValueAndValidity(); // Forza l'aggiornamento dei validatori
        }
      }

    }, 0);
  }
  /**
   * Funzione di salvataggio di un nuovo impegno
   * @returns 
   */
  compareSettori(settore1: Settore, settore2: Settore): boolean {
    return settore1.id === settore2.id; // Sostituisci "id" con la proprietà univoca degli impegni
  }

  public salva() {


    if (this.formImpegni.valid) {

      const id = this.formImpegni.get('id')?.value;

      // Se sono in contesto: sintesi, bilancio, policy etc...
      if (this.data.idContest) {
        return this.salvaInContesto();
      }

      if (id) {
        return this.impegnoService.putImpegno(id, this.formImpegni.getRawValue()).subscribe(
          {
            next: (result: any) => {
              this.utilityService.opneSnackBar('Salvataggio effettuato ', '', {
                duration: 2000,
                panelClass: ['success-snackbar']
              });
              this.dialogRef.close();
            },
            error: (err: any) => {
              console.error('Errore durante il salvataggio dell\'impegno:', err);

              this.utilityService.openDialog({
                titolo: 'Attenzione',
                descrizione: 'Errore nel salvataggio dell\'impegno',
                bottoni: [{ nome_btn: 'Chiudi' }]
              })
            }

          }
        );
      } else {
        return this.impegnoService.postImpegni(this.formImpegni.getRawValue()).subscribe(
          {
            next: (result: any) => {
              this.utilityService.opneSnackBar('Salvataggio effettuato ', '', {
                duration: 2000,
                panelClass: ['success-snackbar']
              });
              this.dialogRef.close();
            },
            error: (err: any) => {
              console.error('Errore durante il salvataggio degli impegni:', err);
              this.utilityService.openDialog({
                titolo: 'Attenzione',
                descrizione: 'Errore nel salvataggio degli impegni',
                bottoni: [{ nome_btn: 'Chiudi' }]
              })
            }

          }
        );
      }
    } else {
      Object.values(this.formImpegni.controls).forEach(
        (control) => {
          control.markAsTouched();
        }
      );
    }
    return null;
  }

  private _getSettori() {

    this.settoriService.getSettori(0, 1000, '', [], [{
      chiave: 'titolo',
      sort: 'desc'
    }]).subscribe({
      next: (result: any) => {


        this.arraySettori = result?.content || [];
      },
      error: (err: any) => {
        console.error('Errore durante il caricamento dei settori:', err);

        this.utilityService.openDialog({
          titolo: 'Attenzione',
          descrizione: 'Errore nel recupero dei settori',
          bottoni: [{ nome_btn: 'Ok' }]
        })
      }
    })
  }

  /**
   * Metodo che mi salva un nuovo impatto sulla materialità
   * @returns 
   */
  public salvaInContesto() {

    if (!this.data.idAmbito) {
      this.mostraMessaggio('Attenzione', 'Ambito non trovato o non riconosciuto');
      return;
    } else if (!this.data.idTematica) {
      this.mostraMessaggio('Attenzione', 'Tematica non trovata o non riconosciuta');
      return;
    }

    if (!this.formImpegni.get('id')?.value) {
      this.formImpegni.get('creatoDaContesto')?.setValue(true);
    }

    let serviceCall;

    if (this.data.contesto == 'sintesi') {
      serviceCall = this.sintesiService.putImpegniEffimeri(this.data.idContest,
        this.data.idAmbito,
        this.data.idTematica,
        this.formImpegni.getRawValue());
    } else if (this.data.contesto == 'bilancio') {
      serviceCall = this.bilancioService.putImpegniEffimeri(this.data.idContest,
        this.data.idAmbito,
        this.data.idTematica,
        this.formImpegni.getRawValue());

    } else if (this.data.contesto == 'policy') {
      serviceCall = this.policyService.putImpegniEffimeri(this.data.idContest,
        this.data.idAmbito,
        this.data.idTematica,
        this.formImpegni.getRawValue());
    }

    if (serviceCall) {

      this.spinnerOver.show();

      serviceCall.subscribe({
        next: (result: any) => {
          this.mostraMessaggioSnackbar('Salvataggio effettuato');
          this.dialogRef.close(result);
          this.spinnerOver.hide();
        },
        error: (err: any) => {
          console.error('Errore durante il salvataggio dell\'impegno:', err);
          this.mostraMessaggio('Attenzione', 'Errore nel salvataggio dell\'impegno');
          this.spinnerOver.hide();
        }
      });
    }
  }


  private mostraMessaggio(titolo: string, descrizione: string) {
    this.utilityService.openDialog({
      titolo: titolo,
      descrizione: descrizione,
      bottoni: [{ nome_btn: 'Chiudi' }]
    });
  }

  private mostraMessaggioSnackbar(messaggio: string) {
    this.utilityService.opneSnackBar(messaggio, '', {
      duration: 2000,
      panelClass: ['success-snackbar']
    });
  }
}
