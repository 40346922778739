<app-spinner-overlay></app-spinner-overlay>
<h2 mat-dialog-title>Obiettivo</h2>
<div mat-dialog-content class="dialog-center-cont">
    <form [formGroup]="formObiettivoPiano" appFormContainer [larghezza]="2">

        <mat-form-field appFormElement [larghezza]="2" [riempi]="true">
            <mat-label>Titolo</mat-label>
            <input matInput placeholder="Titolo" formControlName="titolo" />
            <mat-error appFormError></mat-error>
        </mat-form-field>
        <mat-form-field appFormElement [larghezza]="2" [riempi]="true">
            <mat-label>Descrizione</mat-label>
            <input matInput placeholder="Descrizione" formControlName="descrizione" />
            <mat-error appFormError></mat-error>
        </mat-form-field>
    </form>
</div>
<div mat-dialog-actions class="dialog-footer-cont">
    <div>
        <button mat-dialog-close mat-flat-button color="accent" class="accent-button">Chiudi</button>
    </div>
    <div>
        <button mat-flat-button color="primary" (click)=" salva()" class="primary-button">
            Salva
        </button>
    </div>
