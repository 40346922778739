import { Component, OnDestroy, OnInit, Output, ViewChild, forwardRef, EventEmitter, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { CKEditorComponent, ChangeEvent } from '@ckeditor/ckeditor5-angular';
import Editor from 'ckeditor5/build/ckeditor';

@Component({
  selector: 'app-editor',
  templateUrl: './editor.component.html',
  styleUrls: ['./editor.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => EditorComponent),
      multi: true
    }
  ]
})
export class EditorComponent implements ControlValueAccessor {
  @ViewChild(CKEditorComponent) editorComponent!: CKEditorComponent;

  @Output()
  cambioValoreEditor = new EventEmitter;

  /**
   * Serve per rimuovere/disabilitare uno o più plugin dell'editor.
   * I plugin inclusi (e quindi disattivabili alla bisogna) sono visibili in ckeditor.ts
   */
  private _removePlugins: string[] = [];

  @Input()
  set removePlugins(plugins: string[]) {
    this._removePlugins = plugins || [];
  }

  get removePlugins() {
    return this._removePlugins;
  }

  public editor = Editor;
  config: any = null;
  value = '';
  disabled = false;
  onChange: any = () => { };
  onTouched: any = () => { };

  writeValue(value: any): void {
    if (this.value !== value) {
      this.value = value;
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  changed(event: ChangeEvent) {
    this.onChange(this.editorComponent.editorInstance?.data.get());
    this.cambioValoreEditor.emit(this.editorComponent.editorInstance?.data.get())
  }

  ngOnInit() {
    this.config = {
      removePlugins: this._removePlugins || []
    }

    console.log('EDITOR CONFIG : ' , this.config);
    
  }

}
