import { Injectable } from '@angular/core';
import { WebService } from '../web/web.service';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Filters, SortBy } from 'src/app/components/lista-tabellare/classes/lista-tabellare-data-source';
import { Utente } from './utente.service';

export interface InfoUtente {
  userDetails: {
    sid: string;
    username: string;
    name: string;
    lastname: string;
    idKeycloak: string;
    roles: string[];
    others: string;
    permissions: string[];
  },
  utente: Utente;
  tipoInstallazione: 'SOSTENIBILE_CLOUD' | 'SOSTENIBILE_GO',
  nrMaxAziende?: number;
}

@Injectable({
  providedIn: 'root',
})
export class UtenteWebService {
  private nomeWs = 'utenti';

  constructor(private webService: WebService) { }

  login(datiLogin: { username: string; password: string; rememberMe: boolean; origin: string }): Observable<any> {
    return this.webService.doPost('/key/token', datiLogin, undefined, false);
  }

  logout(): void {
    this.webService.logout();
  }

  checkAuth(): Observable<boolean> {
    return this.webService.doPost('/key/check-token', {}, undefined, true).pipe(
      map(() => {
        return true;
      }),
      catchError((error) => {
        return of(false);
      })
    );
  }

  /**
   * Funzione per selezionare le righe da far vedere nella grid
   * Anche se dovrebbe essere una get, viene fatto il post per passarli dei parametri di visualizzazione 
   * @param page pagina
   * @param pageSize record per pagina
   * @param ricerca risultato per ricerca 
   * @param filters valori da filtrare 
   * @param sortBy sort asc desc
   * @returns lista di righe
   */
  public get(page: number, pageSize: number, ricerca?: string, filters?: Filters[], sortBy?: SortBy[]): Observable<any> {
    return this.webService.doPost('/api/v1/' + this.nomeWs + '/list', { page, pageSize, ricerca, filters, sortBy });
  }

  /**
   * Funzine ws per la creazione del Utente
   * @param data dati da inserire nel MongoDB
   * @returns chiama la funzione post con endpoint  utente per la creazione Utente
   */
  public post(data: any): Observable<any> {
    return this.webService.doPost('/api/v1/' + this.nomeWs + '/create-internal-user', data);
  }

  /**
   * Funzione per disattivare un utente
   * @param idUtente id della riga da eliminare
   * @returns 
   */
  public disattivaUtente(idUtente: any): Observable<any> {
    return this.webService.doDelete('/api/v1/' + this.nomeWs + '/' + idUtente + '/disattiva', {})
  }

  /**
   * Funzione per attivare un utente
   * @param idUtente id della utente da attivare
   * @returns 
   */
  public attivaUtente(idUtente: any): Observable<any> {
    return this.webService.doPost('/api/v1/' + this.nomeWs + '/' + idUtente + '/attiva', {})
  }

  /**
   * Chiamata endpoint per lista ruoli 
   * @returns lista di tutti i ruoli
   */
  public getRuoli(): Observable<any> {
    return this.webService.doGet('/api/v1/' + this.nomeWs + '/roles-list-all', {});
  }

  public getRuoliCreate(): Observable<any> {
    return this.webService.doGet('/api/v1/' + this.nomeWs + '/roles-list-create', {});
  }

  public put(keyId: string, utente: any): Observable<any> {
    return this.webService.doPut('/api/v1/' + this.nomeWs + '/update', utente, {
      keyId
    });
  }

  public getUtentiByIdAzienda(page: number, pageSize: number, ricerca?: string, filters?: Filters[], sortBy?: SortBy[]): Observable<any> {
    return this.webService.doPost('/api/v1/utenti/list', { page, pageSize, ricerca, filters, sortBy });
  }

  public simulaUtente(idUtente: string, origin: string) {
    // send-mail-reset-password
    return this.webService.doPost('/api/v1/utenti/' + idUtente + '/simula-login', {}, { origin });
  }

  public resetPassword(idUtente: string) {
    // send-mail-reset-password
    return this.webService.doPut('/api/v1/utenti/send-mail-reset-password', undefined, { userId: idUtente });
  }

  public richiediResetPassword(username: string) {
    // send-mail-reset-password
    return this.webService.doPut('/api/v1/utenti/request-reset-password', undefined, { username });
  }

  public getInfoUtente(): Observable<InfoUtente> {
    return this.webService.doGet('/api/v1/get-config/info-utente', {});
  }

  public aggiornaAziendaSelezionata(idAzienda: string): Observable<any> {
    return this.webService.doPut('/api/v1/utenti/seleziona-azienda', {}, { idAzienda });
  }

}
