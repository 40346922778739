import { Component } from '@angular/core';
import { SelezioneElementiQuestionarioComponent } from '../../selezione-elementi-questionario.component';
import { catchError, map, Observable, of } from 'rxjs';
import { DialogCreaObiettiviPianoComponent } from 'src/app/page/configurazioni/cfg-obiettivi-piano/dialog/dialog-crea-obiettivi-piano/dialog-crea-obiettivi-piano.component';

@Component({
  selector: 'app-selezione-obiettivi-piano',
  templateUrl: '../../selezione-elementi-questionario.component.html',
  styleUrls: ['../../selezione-elementi-questionario.component.scss']
})
export class SelezioneObiettiviPianoComponent extends SelezioneElementiQuestionarioComponent {
  public sonoInCtr: boolean = false;
  override editElementoListaChk(idAmbito: string, idTematica: string, obiettivo?: any): void {
    const dialogCreaObiettiviPiano = this.dialog.open(DialogCreaObiettiviPianoComponent, {
      data: {
        obiettivoPiano: obiettivo,
        idContest: this.idContest,
        idAmbito: idAmbito,
        idTematica: idTematica,
        contesto: 'piano'
      },
      panelClass: 'dialog-container',
      disableClose: false,
      width: '95%',
      maxHeight: '95%',
      autoFocus: false,
    });

    dialogCreaObiettiviPiano.afterClosed().subscribe((result) => {

      if (result) {
        this.spinnerOver.show();

        if (this.sonoInCtr) {
          obiettivo.selected = true;
          this.sonoInCtr = false;
        }

        this.salva(true).subscribe(() => {
          this._getObiettiviPiano();
        });
      } else if (this.sonoInCtr) {
        this.spinnerOver.show();
        this.sonoInCtr = false;
        obiettivo.selected = false;
        this.salva(true).subscribe(() => {
          this._getObiettiviPiano();
        });
      }
    });
  }
  override addElementoListaChk(idAmbito: string, idTematica: string): void {
    // posso passare la stessa funzione poichè la gestione è identica, Cambia solameanete il passaggio del obiettivo 
    // a vuoto nella creazione.
    this.editElementoListaChk(idAmbito, idTematica);
  }

 
  private _getObiettiviPiano() {

    this.spinnerOver.show();
    this.pianoService.getObiettiviPiano(this.idContest).subscribe({
      next: (esito) => {
        this.objQuestionario = esito
        this.spinnerOver.hide();
      },
      error: (err) => {
        console.error(err);
        this.utilityService.openDialog({
          titolo: 'Attenzione',
          descrizione: 'Errore nel recupero degli obiettivi',
          bottoni: [{ nome_btn: 'Ok' }]
        })
        this.spinnerOver.hide();
      }
    })
  }


  salva(noCtr?: boolean) {
    let obiettiviSelezionati = this.oggettoElementiSelezionati();


    if (!noCtr) {
      //Controllo se è stato selezionato almeno un impatto per tematica 
      if (this.ctrElemChecked(obiettiviSelezionati, 'obiettivo')) {
        return of(false);
      }
    }

    return this._salvaSurveyElemSel(obiettiviSelezionati);
  }


  private _salvaSurveyElemSel(obiettiviSelezionati: any): Observable<boolean> {

    if (this.idContest) {
      return this.pianoService.putObiettiviPianoSelezionati(
        this.idContest, obiettiviSelezionati).pipe(
          map((result: any) => {


            this.utilityService.opneSnackBar('Aggiornamento effettuato', '', {
              duration: 2000,
              panelClass: ['success-snackbar']
            });

            this.salvato.emit();

            return true;

          }),
          catchError((err: any) => {
            console.error('Errore :', err);

            this.utilityService.openDialog({
              titolo: 'Attenzione',
              descrizione: 'Errore salvataggio',
              bottoni: [{ nome_btn: 'Ok' }]
            });

            return of(false);
          })
        );
    } else {
      return of(false);
    }
  }
  override ctrCheck(idAmbito: string, idTematica: string, elementoChk: any, check: boolean): void {
    if (!elementoChk.descrizione && check) {
      this.sonoInCtr = true;
      this.editElementoListaChk(idAmbito, idTematica, elementoChk);
    } else {
      elementoChk.selected = check;
    }

  }
}
