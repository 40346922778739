import { AfterViewInit, Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { SpinnerOverlayComponent } from 'src/app/components/spinner-overlay/spinner-overlay.component';
import { Azienda, AziendaService } from 'src/app/services/azienda/azienda.service';
import { Responsabile, RuoliList, Utente, UtenteService } from 'src/app/services/utente/utente.service';
import { UtilityService } from 'src/app/services/utility/utility.service';
import { DialogGestioneLicenzeComponent } from '../dialog-gestione-licenze/dialog-gestione-licenze.component';
import { Observable, firstValueFrom, of } from 'rxjs';
import { MatSelectChange } from '@angular/material/select';
import { Filters } from 'src/app/components/lista-tabellare/classes/lista-tabellare-data-source';

@Component({
  selector: 'app-dialog-crea-utenti',
  templateUrl: './dialog-crea-utenti.component.html',
  styleUrls: ['./dialog-crea-utenti.component.scss'],
})
export class DialogCreaUtentiComponent implements OnInit, AfterViewInit {
  @ViewChild(SpinnerOverlayComponent) spinnerOver!: SpinnerOverlayComponent;
  public arrayRuoli: RuoliList[] = [];
  public objResponsabile: Responsabile[] = [];
  public arrayAziende: Azienda[] = [];
  public selectedAzienda: string[] = [];

  /* Form Controls */
  public formUtenti = new FormGroup({

    /* Nome */
    firstName: new FormControl<string>('', {
      nonNullable: true,
      validators: [Validators.required],
    }),
    /* Cognome */
    lastName: new FormControl<string>('', {
      nonNullable: true,
      validators: [Validators.required],
    }),
    /* email */
    email: new FormControl<string>('', {
      nonNullable: true,
      validators: [Validators.required, Validators.email, UtilityService.emailValidator],
    }),

    /* Ruoli Utente */
    ruolo: new FormControl<RuoliList | undefined>(undefined, {
      nonNullable: true,
      validators: [Validators.required],
    }),

    /* Responsabile */
    utenteResponsabile: new FormControl<Responsabile | undefined>(undefined, {
      nonNullable: true,
      validators: [],
    }),

    /* aziende */
    idAziende: new FormControl<string[]>([]),

    /* username  */
    username: new FormControl<string>(''),
    /* attivo */
    attivo: new FormControl<boolean>(true),
    /* keyId */
    keyId: new FormControl<string | undefined>(undefined),
    /* CMSId */
    CMSId: new FormControl<string | undefined>(undefined),
    /* id */
    id: new FormControl<string | undefined>(undefined),

    /* Ruoli Utente */
    codiceLicenza: new FormControl<string | undefined>(undefined, {
      nonNullable: true,
      validators: [],
    }),
  });


  constructor(
    public dialogRef: MatDialogRef<DialogCreaUtentiComponent>,
    public utenteService: UtenteService,
    public aziendaService: AziendaService,
    private utilityService: UtilityService,
    private dialog: MatDialog,

    @Inject(MAT_DIALOG_DATA) public data: {
      utente: Utente,
      idAzienda: string
    }
  ) {
  }
  ngOnInit(): void {

    if (this.data.utente && this.data.utente.ruolo.roleName === 'collaboratore') {
      this.formUtenti.get('idAziende')?.addValidators(Validators.required);
      this.formUtenti.get('utenteResponsabile')?.addValidators(Validators.required);
      this.formUtenti.get('idAziende')?.updateValueAndValidity();
      this.formUtenti.get('utenteResponsabile')?.updateValueAndValidity();

    }

    this.ctrDisableCampi();
  }

  ngAfterViewInit(): void {
    this._getRuoli();
    this._getUtentiPerCollaboratore;
    this.datiIniziali();
  }
  /**
   * Nel cambio di un ruolo utente
   * @param event 
   */
  onSelectionChangeRuolo(event: MatSelectChange) {
    const form = this.formUtenti;
    const selectedRole = event.value.roleName;

    // Se il ruolo selezionato è 'collaboratore' e l'utente corrente è un 'configuratore'
    const isCollaboratore = selectedRole === 'collaboratore';
    const isReadOnly = selectedRole === 'utente_read_only';
    const isCurrentUserConfiguratore = this.utenteService.infoUtente?.utente?.ruolo?.roleName === 'configuratore';

    if (isCollaboratore && isCurrentUserConfiguratore) {
      // Resetta i campi 'utenteResponsabile' e 'idAziende'
      form.get('utenteResponsabile')?.setValue(undefined);
      form.get('idAziende')?.setValue([]);

      form.get('utenteResponsabile')?.addValidators(Validators.required);

      // Se non ci sono responsabili, recupera la lista
      if (this.objResponsabile.length === 0) {
        this._getUtentiPerCollaboratore();
      }
    } else {
      // Rimuove i validatori se non applicabile
      form.get('utenteResponsabile')?.clearValidators();
      if (selectedRole !== 'collaboratore') {
        this._getAziendeGoOrCloud(selectedRole);
      } else { // rimuovere dopo la creazione del collaboratore go perchè basta chiamare il metodo this._getAziendeGoOrCloud(selectedRole);
        let filtroAziende: Filters[] = [{
          values: [this.utenteService.infoUtente?.tipoInstallazione],
          operator: 'eq',
          chiave: 'tipoInstallazione'
        }]

        this._getAziende(filtroAziende);
      }

    }

    // Aggiunge o rimuove i validatori in base al ruolo
    if (isCollaboratore || isReadOnly) {
      form.get('idAziende')?.addValidators(Validators.required);
    } else {
      form.get('idAziende')?.clearValidators();
    }

    // Aggiorna la validità dei campi
    form.get('utenteResponsabile')?.updateValueAndValidity();
    form.get('idAziende')?.updateValueAndValidity();
  }

  /**
   * Nel cambio del responsabile se selezionato come ruolo Collaboratore
   * @param event 
   */
  onSelectionChangeResponsabile(event: MatSelectChange) {
    let filtroAziende: Filters[] = [{
      values: event.value.idAziende,
      operator: 'eq',
      chiave: 'id'
    }]
    this._getAziende(filtroAziende)
  }

  /**
  * Funzione di salvataggio di un nuovo Utente
  * @returns 
  */
  salva() {


    if (this.formUtenti.valid) {
      const keyId = this.formUtenti.get('keyId')?.value;

      if (this.formUtenti.get('ruolo')?.value?.roleName !== 'collaboratore') {
        this.formUtenti.get('utenteResponsabile')?.setValue(undefined);
      }

      this.spinnerOver.show();
      if (keyId) {

        return this.utenteService.putUtente(keyId,
          this.formUtenti.getRawValue()).subscribe({
            next: (result: any) => {


              this.utilityService.opneSnackBar('Aggiornamento effettuato', '', {
                duration: 2000,
                panelClass: ['success-snackbar']
              });
              this.spinnerOver.hide();
              this.dialogRef.close();
            },
            error: (err: any) => {
              console.error('Errore durante l\'aggiornamento dell\'Utente: ', err);

              this.utilityService.openDialog({
                titolo: 'Attenzione',
                descrizione: err?.error?.message || 'Errore durante l\'aggiornamento dell\'Utente',
                bottoni: [{ nome_btn: 'Chiudi' }]
              },
                500);
              this.spinnerOver.hide();
            }
          })


      } else {
        return this.utenteService.postUtente(this.formUtenti.getRawValue()).subscribe({
          next: (result: any) => {
            this.utilityService.opneSnackBar('Salvataggio effettuato ', '', {
              duration: 2000,
              panelClass: ['success-snackbar']
            });
            this.dialogRef.close();
            this.spinnerOver.hide();
          },
          error: (err: any) => {
            console.error('Errore durante il salvataggio dell\'Utente:', err);
            this.utilityService.openDialog({
              titolo: 'Attenzione',
              descrizione: err?.error?.message || 'Errore nel salvataggio dell\'Utente',
              bottoni: [{ nome_btn: 'Chiudi' }]
            },
              500);
            this.spinnerOver.hide();
          }
        })
      }

    } else {
      Object.values(this.formUtenti.controls).forEach(
        (control) => {
          control.markAsTouched();
        }
      );
    }
    return null;
  }

  compareRuoli(ruolo1: RuoliList, ruolo2: RuoliList): boolean {
    return ruolo1?.roleName === ruolo2?.roleName; // Sostituisci "id" con la proprietà univoca degli ambiti
  }

  compareResponsabile(resp1: Responsabile, resp2: Responsabile): boolean {
    return resp1?.keyId === resp2?.keyId;
  }

  private _getRuoli() {

    let obsRuoli: any;

    if (this.data?.utente?.id) {
      obsRuoli = this.utenteService.getRuoli();
    } else {
      obsRuoli = this.utenteService.getRuoliCreate()
    }

    obsRuoli.subscribe(
      {
        next: (result: any) => {

          this.arrayRuoli = result || [];

        },
        error: (err: any) => {
          console.error('Errore durante il caricamento dei Ruoli:', err);

          this.utilityService.openDialog({
            titolo: 'Attenzione',
            descrizione: 'Errore nel recupero dei Ruoli',
            bottoni: [{ nome_btn: 'Ok' }]
          })
        }
      }
    )
  }

  private _getAziende(filtroAzienda?: Filters[]) {

    if (!filtroAzienda) {
      filtroAzienda = [];
    }

    this.aziendaService.getAziende(0, 1000, '', filtroAzienda, [{
      chiave: 'ragioneSociale',
      sort: 'asc'
    }]).subscribe({
      next: (result: any) => {

        this.arrayAziende = result?.content || [];

        this.formUtenti.get('idAziende')?.setValue(
          (this.formUtenti.get('idAziende')?.value || []).filter((val) => {
            return !!this.arrayAziende.find(ditta => ditta.id === val);
          })
        );

      },
      error: (err: any) => {
        console.error('Errore durante il caricamento delle aziende:', err);

        this.utilityService.openDialog({
          titolo: 'Attenzione',
          descrizione: 'Errore nel recupero delle aziende',
          bottoni: [{ nome_btn: 'Ok' }]
        })
      }
    })
  }

  public datiIniziali() {
    this._getUtentiPerCollaboratore();
    if (this.data?.utente) {
      Object.keys(this.data.utente).forEach((value, index, array) => {

        if (value === 'utenteResponsabile') {
          this.formUtenti.get('utenteResponsabile')?.setValue(this.data.utente.utenteResponsabile);
        }

        this.formUtenti.get(value)?.setValue((this.data.utente as any)[value]);
      })
      this.formUtenti.controls.email.disable();
    }

    if (this.data.idAzienda) {
      this._getAziendeByIdAzienda();
      this.formUtenti.controls.idAziende.disable();

    } else if (this.data?.utente?.utenteResponsabile?.idAziende) {
      let filtroAziendeResponsabile: Filters[] = [{
        values: this.data.utente.utenteResponsabile.idAziende,
        operator: 'eq',
        chiave: 'id'
      }]
      this._getAziende(filtroAziendeResponsabile)

    } else {
      this._getAziendeGoOrCloud(this.data?.utente?.ruolo?.roleName);
    }
  }

  private _getAziendeByIdAzienda() {

    this.aziendaService.getAziendeByIdAzienda([this.data.idAzienda], 0, 1000, '', [], [{
      chiave: 'ragioneSociale',
      sort: 'asc'
    }]).subscribe({
      next: (result: any) => {


        this.arrayAziende = result?.content || [];
        Object.values(this.arrayAziende).forEach(azienda => {
          this.selectedAzienda = [azienda.id];
        });;
      },
      error: (err: any) => {
        console.error('Errore durante il caricamento delle aziende:', err);

        this.utilityService.openDialog({
          titolo: 'Attenzione',
          descrizione: 'Errore nel recupero delle aziende',
          bottoni: [{ nome_btn: 'Ok' }]
        })
      }
    })
  }

  salvaDentro(): Observable<boolean> {

    if (this.formUtenti.valid) {
      this.spinnerOver.show();
      return new Observable(obs => {
        this.utenteService.postUtente(this.formUtenti.getRawValue()).subscribe({
          next: (result: any) => {
            this.utilityService.opneSnackBar('Salvataggio effettuato ', '', {
              duration: 2000,
              panelClass: ['success-snackbar']
            });
            this.formUtenti.get('id')?.setValue(result?.id)
            this.formUtenti.get('keyId')?.setValue(result?.keyId)
            this.spinnerOver.hide();
            obs.next(result);
            obs.complete();
            return of(true);
          },
          error: (err: any) => {
            console.error('Errore durante il salvataggio dell\'Utente:', err);
            this.utilityService.openDialog({
              titolo: 'Attenzione',
              descrizione: err?.error?.message || 'Errore nel salvataggio dell\'Utente',
              bottoni: [{ nome_btn: 'Chiudi' }]
            },
              500);
            this.spinnerOver.hide();
            obs.error(err);
            return of(false);
          }
        });
      });
    } else {
      Object.values(this.formUtenti.controls).forEach(
        (control) => {
          control.markAsTouched();
        }
      );
      return of(false);
    }
  }

  /**
   * Metodo che mi apre la dialog di Gestione Licenze per utente 
   */
  public async gestioneLicenze() {

    let resultSalvataggio: boolean = false;
    if (!this.formUtenti.get('keyId')?.value) {
      try {
        resultSalvataggio = await firstValueFrom(this.salvaDentro());
      } catch (error) {
        console.error('Errore durante l\'esecuzione del salvataggio:', error);
      }
      if (resultSalvataggio) {
        this.openDialogLicenze();
      }
    } else {
      this.openDialogLicenze();
    }
  }

  openDialogLicenze() {
    const gestioneLicenzeDialog = this.dialog.open(DialogGestioneLicenzeComponent, {
      data: {
        idUtente: this.formUtenti.get('id')?.value,
        ruoloUtente: this.formUtenti.get('ruolo')?.value,
        codiceLicenza: this.formUtenti.get('codiceLicenza')?.value,
      },
      panelClass: 'dialog-container',
      disableClose: false,
      width: '95%',
      maxHeight: '95%',
      autoFocus: false,
    });

    gestioneLicenzeDialog.afterClosed().subscribe((result) => {

      if (result?.codiceLicenza) {
        this.formUtenti.get('codiceLicenza')?.setValue(result?.codiceLicenza)
      }
    });
  }

  public canSeeLicenze() {

    if (this.utenteService.isPermessoAttivo('ABILITA_LICENZA') && (this.formUtenti.get('ruolo')?.value?.roleName !== 'collaboratore' && !this.formUtenti.get('ruolo')?.value?.roleName?.includes('_go'))) {
      return true
    }
    return false
  }

  /**
 * Metodo che mi filtra i responsabili quando selezionato ruolo collaboratore
 * Se sono un utente sotto configuratore allora il responsabile mi viene assegnato in automatico. 
 */
  private _getUtentiPerCollaboratore() {
    this.spinnerOver.show();

    let filtroUtenti: Filters[] = [{
      values: ['utente_premium', 'professionista', 'utente_premium_go'],
      operator: 'eq',
      chiave: 'roleName'
    }, {
      values: [true],
      operator: 'is',
      chiave: 'attivo'
    }]

    this.utenteService.getUtenti(0, 1000, '', filtroUtenti).subscribe({
      next: (esito => {

        this.objResponsabile = [];
        esito.content.forEach((utente: Utente) => {
          const Responsabile = {
            keyId: utente?.keyId,
            nomeUtente: utente.firstName + ' ' + utente.lastName,
            ruolo: utente.ruolo,
            idAziende: utente.idAziende,
          };
          this.objResponsabile.push(Responsabile);
        });

        this.spinnerOver.hide();
      }),
      error: (err => {
        console.error(err);
        this.spinnerOver.hide();
      })
    })

  }

  ctrDisable(ruolo: string): boolean {
    const ruoloUtenteLogin = this.utenteService.infoUtente?.utente?.ruolo?.roleName;
    const ruoloUtenteInModifica = this.data?.utente?.ruolo?.roleName;
    const isCollaboratore = (r: string) => r === 'collaboratore';
    const isModifica = this.formUtenti.get('id')?.value;

    // Disabilita il ruolo collaboratore se sono in modifica di un utente. Se l'utente in modifica
    // è un collaboratore allora disabilito tutti i ruoli 
    if (isModifica && (isCollaboratore(ruoloUtenteInModifica) || isCollaboratore(ruolo))) {
      return true;
    }

    // Non posso creare un utente con il mio stesso ruolo, tranne se sono un configuratore 
    if (ruolo === ruoloUtenteLogin && ruoloUtenteLogin !== 'configuratore') {
      return true;
    }

    return false;
  }

  private _getAziendeGoOrCloud(ruolo: string) {

    if (ruolo === 'configuratore') {
      this._getAziende();
    } else {
      //Filtro le aziende per tipo ruolo go o cloud
      let valueFiltro: any;
      if (ruolo?.includes('_go')) {
        valueFiltro = 'SOSTENIBILE_GO'
      } else {
        valueFiltro = 'SOSTENIBILE_CLOUD'
      }

      let filtroAziende: Filters[] = [{
        values: [valueFiltro],
        operator: 'eq',
        chiave: 'tipoInstallazione'
      }]

      this._getAziende(filtroAziende);
    }
  }

  ctrDisableCampi() {

    const ruoloUtenteLogin = this.utenteService.infoUtente?.utente?.ruolo?.roleName
    const keyIdUtenteLogin = this.utenteService.infoUtente?.utente?.keyId
    const idUtenteEdit = this.data?.utente?.id;
    const keyIdUtenteEdit = this.data?.utente?.keyId;



    if (idUtenteEdit) {
      this.formUtenti.get('ruolo')?.disable()
    }


    if (keyIdUtenteLogin === keyIdUtenteEdit && ruoloUtenteLogin !== 'configuratore') {
      this.formUtenti.get('idAziende')?.disable();
    }

  }

}
