import { moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { NgScrollbar } from 'ngx-scrollbar';
import { filter, firstValueFrom, Observable, of, throwError } from 'rxjs';
import { DialogEditDescrizioneIndiceComponent } from 'src/app/page/bilancio-sostebilita/dialog/dialog-edit-descrizione-indice/dialog-edit-descrizione-indice.component';
import { CfgStampa, GruppoCfgStampa } from 'src/app/services/config-stampe/config-stampe.service';
import { UtilityService } from 'src/app/services/utility/utility.service';

@Component({
  selector: 'app-cfg-stampa',
  templateUrl: './cfg-stampa.component.html',
  styleUrls: ['./cfg-stampa.component.scss']
})
export class CfgStampaComponent {

  public removePlugins = [
    'BlockQuote',
		'FontBackgroundColor',
		'FontColor',
		'FontSize',
		'Indent',
		'Link',
		'PasteFromOffice',
		'Style',
		'Table',
		'TableToolbar',
		'TextTransformation'
  ];

  @ViewChild('scrollConfStampa') scrollConfStampa!: NgScrollbar;

  private _fnGetConfigStampa: () => Observable<GruppoCfgStampa> = () => {
    return throwError(() => "Funzione di caricamento cfg stampa non inizializzata");
  }

  @Input()
  set fnGetConfigStampa(fnObs: () => Observable<GruppoCfgStampa>) {
    this._fnGetConfigStampa = fnObs;
  }

  private _fnSalvaCfgStampa: (grpCfgStampa: GruppoCfgStampa) => Observable<boolean> = (cfgStampa: GruppoCfgStampa) => {
    return throwError(() => "Funzione di salvataggio configurazione non inizializzata");
  }

  @Input()
  set fnSalvaCfgStampa(fnObs: (grpCfgStampa: GruppoCfgStampa) => Observable<boolean>) {
    this._fnSalvaCfgStampa = fnObs;
  }

  private _fnCaricaImg: (
    file: File,
    chiaveCfg: string
  ) => Observable<{
    inProgress: boolean;
    progress: number;
    type: number;
    body?: any;
  }> = (file: File, chiaveCfg: string) => {
    return throwError(() => "Funzione di caricamento file non inizializzata");
  }

  @Input()
  set fnCaricaImg(fnObs: (
    file: File,
    chiaveCfg: string
  ) => Observable<{
    inProgress: boolean;
    progress: number;
    type: number;
    body?: any;
  }>) {
    this._fnCaricaImg = fnObs;
  }

  private _fnEliminaImg: (
    chiaveCfg: string
  ) => Observable<GruppoCfgStampa> = (chiaveCfg: string) => {
    return throwError(() => "Funzione di caricamento file non inizializzata");
  }

  @Input()
  set fnEliminaImg(fnObs: (
    chiaveCfg: string
  ) => Observable<GruppoCfgStampa>) {
    this._fnEliminaImg = fnObs;
  }


  @Output()
  public loading = new EventEmitter<boolean>();

  public statoToggleTesteCfgStampa: { [chiave: string]: boolean } = {};
  public indicePanelStampa: number | undefined = undefined;
  public cfgStampa: GruppoCfgStampa | undefined;
  public descrizioneErrore: string | undefined = undefined;

  constructor(
    private dialog: MatDialog,
    private utilityService: UtilityService
  ) { }


  /**
   * Carica le configurazioni della stampa
   */
  public caricaStampa() {

    this.loading.emit(true)

    this._fnGetConfigStampa().subscribe({
      next: (esito => {
        this.cfgStampa = esito;
        this.indicePanelStampa = undefined;

        setTimeout(() => {
          this.scrollConfStampa.update();
        }, 0);

        this.loading.emit(false);
      }),
      error: (err => {
        console.error('errore get config stampe', err);

        this.utilityService.openDialog({
          titolo: 'Attenzione',
          descrizione: 'Non è stato possibile recuperare le informazioni di stampa.',
          bottoni: [{ nome_btn: 'Ok' }]
        })

        this.loading.emit(false);
      })
    });

  }


  /**
   * Salva l'ultima dialog aperta, per riaprirla al reload
   * @param event 
   */
  public openGroup(event: any) {
    if (this.cfgStampa) {
      this.indicePanelStampa = event.index;
    }
  }

  /**
  * Ordina la visualizzazione degli header drag and drop, e l'index dell'oggetto spostato
  * @param event 
  */
  public dropCfgStampa(event: any) {
    if (this.cfgStampa?.cfgStampa) {
      moveItemInArray(this.cfgStampa?.cfgStampa, event.previousIndex, event.currentIndex);

      if (event.previousIndex === this.indicePanelStampa) {
        this.indicePanelStampa = event.currentIndex;
      }

      // Aggiorna gli indici di tutti gli elementi nella lista
      this.cfgStampa.cfgStampa.forEach((obj: any, index: number) => {
        obj.index = index;
      });

    }
  }


  /**
   * Aggiunge un nuovo elemento indice dentro la lista da cfg stampe
   */
  public aggiungiNuovoIndiceStampa() {

    const dialogEditDescrizioneIndiceComponent = this.dialog.open(DialogEditDescrizioneIndiceComponent, {
      data: {
        isModifica: false,
      },
      panelClass: 'dialog-container',
      disableClose: false,
      width: '50%',
      maxHeight: '95%',
      autoFocus: false,
    });

    dialogEditDescrizioneIndiceComponent.afterClosed().subscribe((result) => {
      if (result) {
        const ultimoElemento = this.cfgStampa?.cfgStampa[this.cfgStampa?.cfgStampa.length - 1]
        if (ultimoElemento) {

          const nuovoIndice: CfgStampa = {
            index: ultimoElemento.index + 1,
            chiave: (result.split(' ').join('_')).toLowerCase(),
            titolo: result,
            descrizione: '',
            contenutoStampa: {
              content: '',
              tipo: 'blank',
            },
            toggle: true,
          }

          this.cfgStampa?.cfgStampa.push(nuovoIndice)
        }
      }
    });
  }


  /**
   * Apre la dialog per editare il titolo dell'elemento 
   * @param elemStampa 
   */
  public editTitoloElemStampa(elemStampa: CfgStampa) {
    const dialogEditDescrizioneIndiceComponent = this.dialog.open(DialogEditDescrizioneIndiceComponent, {
      data: {
        isModifica: true,
      },
      panelClass: 'dialog-container',
      disableClose: false,
      width: '50%',
      maxHeight: '95%',
      autoFocus: false,
    });

    dialogEditDescrizioneIndiceComponent.afterClosed().subscribe((result) => {
      if (result) {
        elemStampa.titolo = result
      }
    });
  }

  /**
  * Metodo che mi fa l'importazione del file immagine 
  * @param elemStampa 
  */
  public async importaImmagine(file: File[], elemStampa: CfgStampa) {

    if (!this.cfgStampa) {
      console.error('CFG STAMPA NON CARICATA');
      return;
    }

    // Controllo se il file > 1mb
    if (file[0].size > 1048576) {
      const dialog = await this.utilityService.openDialog({
        titolo: 'Dimensione file eccessiva',
        descrizione: 'La dimensione del file supera 1 Mb, la vuoi ridimensionare',
        bottoni: [
          {
            nome_btn: 'No',
            id_btn: 'N'
          },
          {
            nome_btn: 'Si',
            id_btn: 'S'
          },
        ]
      });

      const valDialog = await firstValueFrom(dialog.beforeClosed());
      if (valDialog === 'N') {
        return;
      }
    }

    if (elemStampa.contenutoStampa.content) {
      elemStampa.contenutoStampa.tipo = 'img_txt';
    } else {
      elemStampa.contenutoStampa.tipo = 'img';
    }

    if (file[0].type === "image/jpeg" || file[0].type === "image/jpg" || file[0].type === "image/png" || file[0].type === "image/gif" || file[0].type === "image/svg+xml") {
      this.loading.emit(true);

      await firstValueFrom(this._fnSalvaCfgStampa(this.cfgStampa));

      this._fnCaricaImg(file[0], elemStampa.chiave)
        .pipe(filter((res) => res?.type === 4))
        .subscribe({

          next: (result: {
            inProgress: boolean;
            progress: number;
            type: number;
            body?: any;
          }) => {
            this.cfgStampa = result?.body;

            this.utilityService.opneSnackBar('Immagine di stampa inserita', '', {
              duration: 2000,
              panelClass: ['success-snackbar']
            });

            this.loading.emit(false);

          },
          error: (err: any) => {
            console.error(err);
            this.utilityService.openDialog({
              titolo: 'Attenzione',
              descrizione: err?.error?.message || "Errore nell\'importazione",
              bottoni: [{ nome_btn: 'Chiudi' }]
            })
            this.loading.emit(false);
          }

        });
    } else {
      this.utilityService.opneSnackBar(" Attenzione! Il file d'importazione deve essere un file immagine (.png .jpeg .jpg .gif .svg)", '', {
        duration: 5000,
        panelClass: ['red-snackbar']
      });
      this.descrizioneErrore = " Attenzione! Il file d'importazione deve essere un file immagine (.png, .jpeg, .jpg, .gif, .svg)";
    }

  }

  /**
   * Cambia il tipo in 'txt' se viene compilato il campo paragrafo
   * @param elemStampa 
   */
  public ctrValoreEditor(elemStampa: CfgStampa) {

    const { contenutoStampa } = elemStampa;

    if (contenutoStampa.content) {
      contenutoStampa.tipo = (contenutoStampa.tipo === 'img' || contenutoStampa.tipo === 'img_txt') ? 'img_txt' : 'txt';
    } else if (!contenutoStampa.content && contenutoStampa.tipo === 'img_txt') {
      contenutoStampa.tipo = 'img';
    } else {
      contenutoStampa.tipo = 'blank';
    }

   /*  console.log(contenutoStampa); */
  }


  /**
   * Metodo per eliminazione immagine in cfg stampa 
   * Viene rimosso da google cloud storage 
   */

  public async eseguiEliminaImg(elemStampa: CfgStampa) {

    if (!this.cfgStampa) {
      console.error('CFG STAMPA NON CARICATA');
      return;
    }

    try {
      this.loading.emit(true);

      elemStampa.contenutoStampa.tipo = elemStampa.contenutoStampa.content ? 'txt' : 'blank';

      await firstValueFrom(this._fnSalvaCfgStampa(this.cfgStampa));


      this._fnEliminaImg(elemStampa.chiave).subscribe({
        next: (result: GruppoCfgStampa) => {
          this.cfgStampa = result;
          this.utilityService.opneSnackBar("Immagine rimossa", '', {
            duration: 2000,
            panelClass: ['red-snackbar']
          });
        },
        error: (err) => {
          console.error(err);
        },
        complete: () => {
          this.loading.emit(false);
        }
      })
    } catch (error) {
      console.error(error);
      this.loading.emit(false);
    }
  }

}
