import { AfterViewInit, Component, OnDestroy, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Filters, FnCaricamentoDati, SortBy } from 'src/app/components/lista-tabellare/classes/lista-tabellare-data-source';
import { BottoniListaEvent, Colonna, Filtri, ListaTabellareComponent } from 'src/app/components/lista-tabellare/lista-tabellare.component';
import { AutovalutazioniService } from 'src/app/services/autovalutazioni/autovalutazioni.service';
import { DialogAnteprimaComponent } from '../configurazioni/cfg-autovalutazioni/dialog/dialog-anteprima/dialog-anteprima.component';
import { Azienda, AziendaService } from 'src/app/services/azienda/azienda.service';
import { Subscription, map } from 'rxjs';
import { SettoriService } from 'src/app/services/settori/settori.service';
import { DialogCompilaAutovalutazioneComponent } from './dialog/dialog-compila-autovalutazione/dialog-compila-autovalutazione.component';
import { DialogCreaAutovalutazioneComponent } from './dialog/dialog-crea-autovalutazione/dialog-crea-autovalutazione.component';
import { UtenteService } from 'src/app/services/utente/utente.service';
import { UtilityService } from 'src/app/services/utility/utility.service';
import { LicenzeService } from 'src/app/services/licenze/licenze.service';
import { SpinnerOverlayComponent } from 'src/app/components/spinner-overlay/spinner-overlay.component';

@Component({
  selector: 'app-autovalutazioni',
  templateUrl: './autovalutazioni.component.html',
  styleUrls: ['./autovalutazioni.component.scss']
})
export class AutovalutazioniComponent implements AfterViewInit, OnDestroy {

  @ViewChild('tabella') tabella!: ListaTabellareComponent;
  @ViewChild(SpinnerOverlayComponent) spinnerOver!: SpinnerOverlayComponent;

  private _subAziendaCorrente: Subscription | undefined;

  private azienda: Azienda | undefined = undefined;

  public filtri: Filtri[] = [

    {
      titolo: 'Titolo',
      forControlName: 'titolo',
      input: 'text',
    },
    {
      forControlName: 'aziende',
      input: 'multiple-option',
      titolo: 'Aziende',
      fnDatiOption: () => (this.aziendaService.getAziende(0, 1000)).pipe(map((value) => value?.content || [])),
      descValueOption: 'ragioneSociale',
      idValueOption: 'id',
      riempi: true,
    },
    {
      forControlName: 'settore',
      input: 'multiple-option',
      titolo: 'Settore',
      fnDatiOption: () => (this.settoriService.getSettori(0, 1000)).pipe(map((value) => value?.content || [])),
      descValueOption: 'titolo',
      idValueOption: 'id',
      riempi: true
    }
  ];

  public colonne: { [key: string]: Colonna } = {
    /*    azienda: {
         title: 'Azienda',
         value: (record: any) => (record?.aziende?.length ? record?.aziende[0]?.ragioneSociale : '')
       }, */
    anno: {
      title: 'Anno',
      sortable: true,
      campiSort: ['esercizio'],
      value: (record) => record?.esercizio[0]
    },
    titolo: {
      title: 'Titolo',
      sortable: true,
      value: (record) => record?.titolo
    },
    figura: {
      title: 'Figura',
      sortable: true,
      value: (record) => {
        if (record?.figura?.length) {
          return (record?.figura[0]?.nome || '') + ' - ' + (record?.figura[0]?.figura || '');
        } else {
          return ''
        }
      }
    },
    /* esrcizio: {
      title:'Esercizio',
      value: (record)=> record.esercizio[0]
    }, */
    punteggio: {
      title: 'Punteggio',
      sortable: true,
      campiSort: ['punteggioPercentuale'],
      value: (record: any) => {

        if (record?.stato === 'PUBBLICATO') {
          if (record?.punteggioPercentuale < 50) {
            return '<div style="display: flex;justify-content: center;align-items:center;background-color:#FFE199;width:130px;text-align: center;border-radius: 15px;height:27px"><p style="color:#C7A045;font-size:13px">' + record?.punteggioPercentuale + '/100</p></div>'
          } else if (record?.punteggioPercentuale >= 50) {
            return '<div style="display: flex;justify-content: center;align-items:center;background-color:#CCDBCD;width:130px;text-align: center;border-radius: 15px;height:27px"><p style="color:#729373;font-size:13px">' + record?.punteggioPercentuale + '/100</p></div>'
          } else {
            return '';
          }
        } else {
          return '<div style="font-weight: bold; color: var(--mat-color-accent)">' + 'Completare l\'assessment' + '</div>'
        }


      }
    },
    /*     dataCompilazione: {
          title: 'Data Compilazione',
          value: (record: any) => (record?.dataPubblicazione || '')
        }, */
    notifica: {
      type: 'button',
      buttonIcon: 'notifications_active',
      title: 'Invia notifica',
      buttonId: 'notifica',
      nascondiButton: (record) => record.stato === 'PUBBLICATO',
      buttonMostraSempre: true
    },
    compilaOanteprima: {
      type: 'button',
      buttonIcon: (record: any) => (record.stato === 'BOZZA' ? 'history_edu' : 'visibility'),
      title: (record: any) => (record.stato === 'BOZZA' ? 'Compila' : 'Anteprima'),
      buttonId: (record: any) => (record.stato === 'BOZZA' ? 'compilazione' : 'anteprima'),
      buttonMostraSempre: true,
      nascondiButton: (record: any) => (!this.utenteService.isPermessoAttivo('EDIT_AUTOVALUTAZIONI'))
    },
    /* NON IMPLEMENTATO */
    /* elimina: {
      type: 'button',
      buttonIcon: 'delete',
      title: 'Elimina',
      buttonId: 'elimina',
    }, */
  };
  constructor(
    public dialog: MatDialog,
    private autovalutazioniService: AutovalutazioniService,
    private aziendaService: AziendaService,
    private settoriService: SettoriService,
    public utenteService: UtenteService,
    private utilityService: UtilityService,
    private licenzeService: LicenzeService,
  ) { }

  ngAfterViewInit(): void {
    this._subAziendaCorrente = this.aziendaService.aziendaCorrente.subscribe((azienda) => {

      let cambiato = false;

      if (this.azienda && azienda && this.azienda.id !== azienda.id) {
        cambiato = true;
      }

      this.azienda = azienda;

      if (cambiato) {
        this.tabella?.caricaDati();
      }
    });
  }

  ngOnDestroy(): void {
    this._subAziendaCorrente?.unsubscribe();
  }



  public fnCaricamentoDati: FnCaricamentoDati = (page: number, pageSize: number, ricerca?: string, filters?: Filters[], sortBy?: SortBy[]) => {

    return this.autovalutazioniService.getAutovalutazioniAzienda(page, pageSize, ricerca, filters, sortBy);
  }

  public bottoniListaClick(event: BottoniListaEvent) {
    switch (event.id) {
      case 'anteprima':
        this.anteprima(event.data);
        break;
      case 'compilazione':
        this.compilazione(event.data);
        break;
      case 'notifica':
        this.inviaNotifica(event.data);
        break;
      default:
        console.warn('ATTENZIONE: bottone ' + event.id + ' non riconosciuto');
    }
  }

  compilazione(compilazione: any) {
    const comp = compilazione;
    const esercizio = compilazione.esercizio[0];
    const id = compilazione.id;
    const dialogFiltro = this.dialog.open(DialogCompilaAutovalutazioneComponent, {
      data: {
        comp,
        esercizio,
        idAutovalutazioneCompilata: id
      },
      panelClass: 'dialog-container',
      disableClose: false,
      width: '100%',
      maxHeight: '95%',
      autoFocus: false,
    });

    dialogFiltro.afterClosed().subscribe((result) => {

      this.tabella.caricaDati();
    });
  }

  inviaNotifica(compilazione: any) {

    this.tabella.setLoading(true);

    this.autovalutazioniService.inviaNotifica(compilazione.id).subscribe({
      next: (esito) => {


        this.tabella.setLoading(false);

        this.utilityService.opneSnackBar('Notifica inviata', '', {
          duration: 2000,
          panelClass: ['success-snackbar']
        });


      },
      error: (err) => {
        console.error('ERRORE INVIO NOTIFICA : ', err);

        this.tabella.setLoading(false);

        this.utilityService.openDialog({
          titolo: 'Attenzione',
          descrizione: err?.error?.message || '',
          bottoni: [{ nome_btn: 'Ok' }]
        });

      }
    });

  }

  anteprima(autovalutazione: any) {

    const ambiti = autovalutazione.ambiti;

    const dialogFiltro = this.dialog.open(DialogAnteprimaComponent, {
      data: {
        ambito: ambiti,
        compilate: true,
        autovalutazione
      },
      panelClass: 'dialog-container',
      disableClose: false,
      width: '100%',
      maxHeight: '95%',
      height: '95%',
      autoFocus: false,
    });

    dialogFiltro.afterClosed().subscribe((result) => {

    });
  }

  /**
 * Metodo di modifica o creazione nuovo assessment 
 * @param record 
 */
  crea(record?: any) {
    this.spinnerOver.show();
    this.licenzeService.getUserHasLicense('Autovalutazione').subscribe({
      next: (esito) => {
        this.spinnerOver.hide();
        this.apriDialogCreaAutovalutazione();
      },
      error: (err) => {
        this.spinnerOver.hide();
        this.utilityService.openDialog({
          titolo: 'Attenzione',
          descrizione: err?.error?.message,
          bottoni: [{ nome_btn: 'Ok' }]
        });
      }
    });
  }


  private apriDialogCreaAutovalutazione() {
    const dialogFiltro = this.dialog.open(DialogCreaAutovalutazioneComponent, {
      data: {

      },
      panelClass: 'dialog-container',
      disableClose: false,
      width: '65%',
      maxHeight: '95%',
      autoFocus: false,
    });

    dialogFiltro.afterClosed().subscribe((result) => {

      this.tabella.caricaDati();
    });
  }

}
