import { Filters, SortBy } from 'src/app/components/lista-tabellare/classes/lista-tabellare-data-source';
import { AziendaWebService } from './azienda-web.service';
import { Injectable } from '@angular/core';
import { Ateco, JsonFileService } from '../json-file/json-file.service';
import { BehaviorSubject, Observable, Subject, debounceTime, firstValueFrom, from, of, switchMap, take } from 'rxjs';
import { UtilityService } from '../utility/utility.service';

export interface Azienda {
  id: string;
  CMSId: string;
  ragioneSociale: string;
  piva: string;
  codiciAteco: string[];
  indirizzo: string;
  regione: string;
  comune: string;
  provincia: string;
  cap: string;
  dipendenti: number;
  fatturato: number;
  descrizione: string;
  sitoWeb: string;
  attivo: boolean;
  idUtenti: string[];
  urlLogo?: string;
  tipoInstallazione: 'SOSTENIBILE_CLOUD' | 'SOSTENIBILE_GO',
}

export interface TipoInstallazione {
  chiave: string;
  descrizione: string;
}

@Injectable({
  providedIn: 'root'
})
export class AziendaService {
  private codiciAtecoArray: Ateco[] | undefined = undefined;

  private _aziendaCorrente = new BehaviorSubject<Azienda | undefined>(undefined);
  public aziendaCorrente = this._aziendaCorrente.asObservable();

  private _listaAziende = new BehaviorSubject<Azienda[]>([]);
  public listaAziende = this._listaAziende.pipe(debounceTime(500));

  public nrChiamataDialogAzd: number = 0;

  public sogliaNrDipendenti: number = 0;
  public sogliaFatturato: number = 0;

  constructor(
    private aziendaWebService: AziendaWebService,
    private jsonFileService: JsonFileService,
    private utilityService: UtilityService
  ) { }

  /**
  * Funzione per selezionare le righe da far vedere nella grid
  * @param page pagina
  * @param pageSize record per pagina
  * @param ricerca risultato per ricerca 
  * @param filters valori da filtrare 
  * @param sortBy sort asc desc
  * @returns lista di righe
  */
  public getAziende(
    page: number,
    pageSize: number,
    ricerca?: string,
    filters?: Filters[],
    sortBy?: SortBy[]
  ) {
    return this.aziendaWebService.get(page, pageSize, ricerca, filters, sortBy);
  }

  /**
     * Funzine ws per la creazione del Azienda
     * @param data dati da inserire nel MongoDB
     * @returns chiama la funzione postAzienda per la creazione Azienda
     */
  public postAzienda(data: any) {
    return this.aziendaWebService.post(data);
  }

  /**
    * Funzione per eliminare una riga da una lista 
    * @param idRiga id della riga da eliminare
    * @returns 
    */
  public deleteAzienda(idRiga: any) {
    return this.aziendaWebService.delete(idRiga);
  }

  /**
     * Azienda
     * @param id 
     * @param azienda 
     * @returns 
     */
  public putAzienda(id: string, azienda: any) {
    return this.aziendaWebService.put(id, azienda);
  }

  /*  public getUtentiByIdAzienda(id: string) {
     return this.aziendaWebService.getUtentiByIdAzienda(id);
   } */
  public getAziendeByIdAzienda(
    id: string[],
    page: number,
    pageSize: number,
    ricerca?: string,
    filters?: Filters[],
    sortBy?: SortBy[]
  ) {
    if (!filters?.length) {
      filters = [{
        values: id,
        operator: "eq",
        chiave: "id"
      }];
    } else {

      filters = filters.filter((val) => { return val.chiave !== 'id' });

      filters.push({
        values: id,
        operator: "eq",
        chiave: "id"
      });
    }
    return this.aziendaWebService.getAziendeByIdAzienda(page, pageSize, ricerca, filters, sortBy);
  }


  public getAziendaByIdAzienda(id: string) {
    return this.aziendaWebService.getAziendaByIdAzienda(id);
  }

  public getSettoriAzienda() {
    return from(this.azienda).pipe(
      switchMap((azienda) => {
        if (azienda) {
          return this.aziendaWebService.getSettoriAzienda(azienda.id);
        } else {
          return of([]);
        }
      })
    )
  }

  public setCodiciAteco() {

    this.jsonFileService.getAteco().subscribe(
      {
        next: (ateco: Ateco[]) => {
          this.codiciAtecoArray = ateco;
        },
        error: (err: any) => {
          console.error('errore get ateco', err);
        }
      }
    );
  }

  public getCodiciAteco() {
    return this.codiciAtecoArray;
  }

  get azienda(): Promise<Azienda | undefined> {
    return firstValueFrom(this.aziendaCorrente.pipe(take(1)));
  }

  set azienda(azienda: Azienda | undefined) {
    this._aziendaCorrente.next(azienda);
  }

  public setNrChiamataDialogAzd(chiamata: number) {
    if (chiamata === 0) {
      this.nrChiamataDialogAzd = 0;
    } else {
      this.nrChiamataDialogAzd += chiamata;
    }
  }

  public getNrChiamataDialogAzd() {
    return this.nrChiamataDialogAzd;
  }

  /**
   * Carica le aziende e le memorizza nel behaviorSubject.
   * In questo modo, i componenti che sono iscritti al behaviorSubject
   * ricevono le aziende aggiornate ogni volta che viene effettuata una richiesta.
   */
  public caricaAziende() {

    this.getAziende(0, 1000, '', [], [{
      chiave: 'ragioneSociale',
      sort: 'asc'
    }]).subscribe({
      next: (result: any) => {


        const aziende: Azienda[] = result?.content || [];

        this._listaAziende.next(aziende);

        this._aziendaCorrente.pipe(take(1)).subscribe((azCorrente) => {
          if (azCorrente) {
            // Azienda già selezionata, si aggiorna con i dati appena scaricati
            const azRiestratta = aziende.find((az) => {
              return az.id === azCorrente.id;
            });

            if (azRiestratta) {
              this.azienda = azRiestratta;
            } else {
              this.azienda = undefined;
            }
          }
        });

      },
      error: (err: any) => {
        console.error('Errore durante il caricamento delle aziende:', err);

        this.utilityService.openDialog({
          titolo: 'Attenzione',
          descrizione: 'Errore nel recupero delle aziende',
          bottoni: [{ nome_btn: 'Ok' }]
        })
      }
    });

  }

  /****************************************************  FIGURE AZIENDALI  ************************/

  /**
   * Aggiorna figura aziendale
   * @param idAzienda 
   * @param id 
   * @param figuraAz 
   * @returns 
   */
  public putFigureAz(idAzienda: string, id: string, figuraAz: any) {
    return this.aziendaWebService.putFigureAz(idAzienda, id, figuraAz);
  }

  /**
   * inserisci figura aziendale
   * @param idAzienda 
   * @param data 
   * @returns 
   */
  public postFigureAz(idAzienda: string, data: any) {
    return this.aziendaWebService.postFigureAz(idAzienda, data);
  }

  /**
   * lista figura aziendale
   * @param idAzienda 
   * @param page 
   * @param pageSize 
   * @param ricerca 
   * @param filters 
   * @param sortBy 
   * @returns 
   */
  public getFigureAz(idAzienda: string, page: number, pageSize: number, ricerca?: string, filters?: Filters[], sortBy?: SortBy[]) {
    return this.aziendaWebService.getFigureAz(idAzienda, page, pageSize, ricerca, filters, sortBy);
  }

  /**
  * Funzione per eliminare una riga da una lista 
  * @param idRiga id della riga da eliminare
  * @returns 
  */
  public deleteFigureAz(idAzienda: string, idFiguraAziendale: string) {
    return this.aziendaWebService.deleteFigureAz(idAzienda, idFiguraAziendale);
  }


  private getSoglia(idAzienda?: string): Observable<any> {
    return this.aziendaWebService.getSoglia(idAzienda);
  }

  public setSoglia(idAzienda?: string) {
    this.getSoglia(idAzienda).subscribe({
      next: (esito) => {

        this.sogliaNrDipendenti = esito.sogliaNrDipendenti;
        this.sogliaFatturato = esito.sogliaFatturato;
      },
      error: (err) => {
        console.error(err);
      }
    })
  }

  public getListaTipiInstallazione(): Observable<any> {
    return this.aziendaWebService.getListaTipiInstallazione();
  }

}
