import { AfterViewInit, OnInit, Component, Input, ViewChild, EventEmitter, Output, OnDestroy } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { BehaviorSubject, Observable, Subject, debounceTime, filter, forkJoin, take, tap } from 'rxjs';
import { Filters, FnCaricamentoDati, FnCaricamentoStyle, ListaTabellareDataSource } from './classes/lista-tabellare-data-source';
import { DialogFiltroTabellareComponent } from './dialog/dialog-filtro-tabellare/dialog-filtro-tabellare.component';
import { MatDialog } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { FiltriService } from 'src/app/services/filtri/filtri.service';


export interface Colonna {
  title?: string | ((record: any) => string); // Titolo della colonna
  value?: string | ((record: any) => string); // Valore del json

  type?: 'button' | 'value' | 'checkbox' | 'filebutton' | 'hamburger' | 'draggable' | 'select';
  width?: string | number;
  align?: 'left' | 'right' | 'center'; // Default: 'left'
  buttonId?: string | ((record: any) => string);
  buttonIcon?: string | ((record: any) => string);
  buttonMostraSempre?: boolean; // Se true non viene mai messo nel menù hamburger
  nascondiButton?: boolean | ((record: any) => boolean);
  sortable?: boolean;
  campiSort?: string[];
}

export interface Filtri {
  titolo: string;
  forControlName?: string;
  input: 'text' | 'number' | 'date' | 'option' | 'multiple-option' | 'separatore' | 'multiple-option-chip';
  value?: string | number | string[] | number[] | boolean;
  larghezza?: number;
  riempi?: boolean;
  fnDatiOption?: FnDatiOptions;
  descValueOption?: string;
  idValueOption?: string;
  scssStyle?: string;
  nascondi?: boolean | ((record: any) => boolean);
}

export interface FnDatiOptions {
  (
    ricerca?: string,
  ): Observable<any[]>
}

export interface BottoniListaEvent {
  id?: string | ((record: any) => string);
  data: any;
  index: number;
}


@Component({
  selector: 'app-lista-tabellare',
  templateUrl: './lista-tabellare.component.html',
  styleUrls: ['./lista-tabellare.component.scss']
})
export class ListaTabellareComponent implements OnInit, AfterViewInit, OnDestroy {

  @ViewChild('paginator') paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  public nrColAzioni: number = 1;
  /**
   * Nomi colonne della tabella 
   */
  public nomiColonne: string[] = [];

  /**
 * Subject per intercettare il cambio del campo ricerca con debounce
 */
  private _ricercaInputCambiato = new Subject<string>();

  /**
 * Conteiene i tasti all'interno del menù hamburger
 */
  public buttonHamburger: Colonna[] = [];

  /**
 * Contiene il valore del campo di ricerca.
 */
  public searchValue = '';

  /**
 * Datasource della lista
 */
  public dataSource!: ListaTabellareDataSource;

  /**
   * Volori del filtro valorizzati dalla dialog del filtro
   */
  private _valoriFiltro: Filters[] = [];

  /**
 * Emette un evento quando un bottone è premuto
 */
  @Output() bottoniListaClick: EventEmitter<BottoniListaEvent> = new EventEmitter<BottoniListaEvent>();

  /**
 * Colonne mostrate sulla lista
 */
  private _colonne: { [key: string]: Colonna } = {};

  /**
   * Emette true quando il paginatore è stato preparato ed impostato nel datasource.
   */
  private _paginatorReady = new BehaviorSubject<boolean>(false);

  /**
   * Emette true quando il sorter è stato preparato ed impostato nel datasource.
   */
  private _sortReady = new BehaviorSubject<boolean>(false);

  @Input()
  get colonne() {
    return this._colonne;
  }

  set colonne(_colonne: { [key: string]: Colonna }) {
    Object.assign(this._colonne, (_colonne || {}));
  }

  public _isFiltri: boolean = true;

  @Input()
  set isFiltri(isFiltri: boolean) {
    this._isFiltri = isFiltri;
  }
  get isFiltri() {
    return this._isFiltri;
  }


  /**
   * Funzione di caricamento dati
   */
  private _fnCaricamentoDati!: FnCaricamentoDati;

  @Input()
  get fnCaricamentoDati() {
    return this._fnCaricamentoDati;
  }

  set fnCaricamentoDati(_fnCaricamentoDati: FnCaricamentoDati) {

    this._fnCaricamentoDati = _fnCaricamentoDati;
  }

  /**
   * Funzione di caricamento style
   */
  private _fnCaricamentoStyle!: FnCaricamentoStyle;

  @Input()
  get fnCaricamentoStyle() {
    return this._fnCaricamentoStyle;
  }

  set fnCaricamentoStyle(_fnCaricamentoStyle: FnCaricamentoStyle) {

    this._fnCaricamentoStyle = _fnCaricamentoStyle;
  }

  /**
 * Numero di righe per pagina di default
 */
  private _defaultPageSize = 10;

  @Input()
  get defaultPageSize() {
    return this._defaultPageSize;
  }

  set defaultPageSize(_defaultPageSize: number) {
    this._defaultPageSize = _defaultPageSize || 10;
  }

  /**
 * Indica il massimo numero di bottoni visualizzabile sulla griglia.
 * Se il numero di bottoni è maggiore, vengono tutti nascosti in un menu hamburger.
 */
  private _maxNrButton = 1;

  @Input()
  get maxNrButton() {
    return this._maxNrButton;
  }

  set maxNrButton(maxNrButton: number) {
    this._maxNrButton = maxNrButton || 1;
  }

  /**
   * Titolo lista tabellare
   */
  private _titoloTabella = '';

  @Input()
  get titoloTabella() {
    return this._titoloTabella;
  }

  set titoloTabella(titoloTabella: string) {
    this._titoloTabella = titoloTabella;
  }

  /**
   * Filtri impostati da interfaccia
   */
  private _filtri: Filtri[] = [];

  @Input()
  get filtri() {
    return this._filtri;
  }

  set filtri(filtri: Filtri[]) {
    this._filtri = filtri;
  }

  /**
   * Larghezza della direttiva formContainer usata nei filtri
   */
  private _larghezzaFiltro: number = 3;

  @Input()
  get larghezzaFiltro() {
    return this._larghezzaFiltro;
  }

  set larghezzaFiltro(larghezzaFiltro: number) {
    this._larghezzaFiltro = larghezzaFiltro;
  }

  constructor(
    private filtriService: FiltriService,
    private sanitizer: DomSanitizer,
    public dialog: MatDialog
  ) { }

  ngOnInit(): void {

    this.nomiColonne = Object.keys(this.colonne);

    this._preparaHamb();
    this.aggiustaDatiColonne();
    let noColonneButton = Object.values(this.colonne).slice().reverse();
    let cnt = 0;
    for (let colonna of noColonneButton) {
      if (colonna.type === 'button' || colonna.type === 'hamburger') {
        cnt++;
      } else {
        break;
      }
    }
    this.nrColAzioni = cnt;
    this.dataSource = new ListaTabellareDataSource(this.fnCaricamentoDati, this.colonne, true, this.sanitizer, this.fnCaricamentoStyle);

    // ASCOLTO IL CAMBIO RICERCA
    this._ricercaInputCambiato.pipe(debounceTime(200)).subscribe((value) => {
      this.paginator.firstPage();
      this.caricaDati();
    });

    // Costruisce il filtro di default
    const datiFiltroDefault: { [key: string]: any } = {};
    this._filtri.forEach((filtro) => {
      if (filtro.forControlName && filtro.value) {
        datiFiltroDefault[filtro.forControlName] = filtro.value;
      }
    })

    const datiFiltroCostruiti = this.filtriService.costruisciJsonFiltri(datiFiltroDefault, this._filtri);

    if (datiFiltroCostruiti?.length) {
      this._valoriFiltro = datiFiltroCostruiti;
    }

  }

  ngAfterViewInit() {

    this.caricaDati();

    this._preparaPaginator();
    this._preparaSortable();
  }

  ngOnDestroy(): void {
    this._ricercaInputCambiato.unsubscribe();
  }


  /**
   * Bottone dall'interfaccia determinato dalle colonne
   * @param colonna 
   * @param record 
   * @param index 
   */
  lanciaButtonPressed(colonna: Colonna, record: any, index: number) {
    if (!colonna.buttonId) {
      console.warn('ATTENZIONE: PREMUTO BOTTONE SENZA ID');
    }

    this.bottoniListaClick.emit({
      id: this.idBottone(colonna, record),
      data: record,
      index
    });
  }

  /**
   * Ricarico i dati della tabella (es: cancello i dati della tabella e ricarico la lista)
   */
  caricaDati() {

    forkJoin({
      paginator: this._paginatorReady.pipe(
        filter((value) => !!value), // Passano solo valori "true", quindi emette solo quando il paginatore è pronto
        take(1) // Dopo il primo valore completa l'observable e non prende altri valori
      ),
      sort: this._sortReady.pipe(
        filter((value) => !!value), // Passano solo valori "true", quindi emette solo quando il paginatore è pronto
        take(1) // Dopo il primo valore completa l'observable e non prende altri valori
      )
    }).subscribe(() => {
      this.dataSource.caricaDati(this.searchValue, this._valoriFiltro);
    });

  }

  applicaFiltroRicerca(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this._ricercaInputCambiato.next(filterValue);
  }

  /**
   * Apertura dialog filtri passati da l'esterno
   */
  apriFiltro() {

    const dialogFiltro = this.dialog.open(DialogFiltroTabellareComponent, {
      data: {
        larghezza: this._larghezzaFiltro,
        filtri: this.filtri,
        valoriFiltro: this._valoriFiltro
      },
      panelClass: 'dialog-container',
      disableClose: false,
      width: '90%',
      maxHeight: '90%',
      autoFocus: false
    });

    dialogFiltro.afterClosed().subscribe(result => {
      if (result) {
        this._valoriFiltro = result.filtri;
        this.caricaDati();
      }
    });
  }


  /**
 * Imposta lo stato di caricamento della lista. Mostra o nasconde lo spinner.
 * @param isLoading
 */
  setLoading(isLoading: boolean) {
    this.dataSource?.setLoading(isLoading);
  }


  /**
   * Aggiusta e fissa alcuni dati che potrebbero essere vuoti o incerti
   */
  aggiustaDatiColonne() {
    Object.keys(this._colonne).forEach((nomeColonna, index, array) => {

      const colonna = this._colonne[nomeColonna];
      this._aggiustaDatiColonna(colonna);

    });
  }

  private _preparaPaginator() {
    // Prepara paginator
    this.dataSource.setPaginator(this.paginator);

    this.paginator.page.subscribe(() => this.caricaDati());

    this._paginatorReady.next(true);
  }

  private _preparaSortable() {

    this.dataSource.setSort(this.sort);

    this.sort.sortChange.subscribe((value) => {
      this.caricaDati();
    });

    this._sortReady.next(true);
  }

  private _preparaHamb() {

    const nomiColonneInversi = this.nomiColonne.slice().reverse();
    let nomiColonnePerHamburger = [];

    let nrBtn = 0;
    for (const nome of nomiColonneInversi) {
      const colonna = this._colonne[nome] as Colonna;
      if (colonna.type === 'button') {
        nrBtn++;

        if (!colonna.buttonMostraSempre) {
          // La colonna è impostata per essere sempre mostrata, quindi non si mette tra quelle da nascondere.
          // Conta comunque nel conteggio delle colonne di tipo button
          nomiColonnePerHamburger.push(nome);
        }
      } else {
        break;
      }
    }

    if (nomiColonnePerHamburger.length > 0) { //Se non c'è un bottone da aggiungere all'hamburger non valorizzo la colonna hamburger 

      if (nrBtn > this._maxNrButton) {
        // Superato il limite di bottoni, si mettono in hamburger
        nomiColonnePerHamburger = nomiColonnePerHamburger.reverse();

        for (const nome of nomiColonnePerHamburger) {
          const colonna = this._colonne[nome];

          this._aggiustaDatiColonna(colonna);

          this.buttonHamburger.push(colonna);

          delete this._colonne[nome];

          const posNomeCol = this.nomiColonne.indexOf(nome);

          if (posNomeCol >= 0) {
            this.nomiColonne.splice(posNomeCol, 1);
          }

        }

        this._colonne['__hamburger'] = {
          type: 'hamburger',
          title: 'Altro',
          buttonIcon: 'more_vert',
          buttonId: '__hamburger',
          sortable: false
        };
        this.nomiColonne.push('__hamburger');
      }
    }

  }


  /**
 * Aggiusta e fissa alcuni dati della colonna passata che potrebbero essere vuoti o incerti
 */
  private _aggiustaDatiColonna(colonna: Colonna) {

    // WIDTH
    let width = 'auto';

    if (colonna.width) {
      width = colonna.width + (typeof colonna.width === 'number' ? 'px' : '');
    } else if (colonna.type === 'button' || colonna.type === 'filebutton' || colonna.type === 'hamburger') {
      width = '1%';
    }

    colonna.width = width;

    // ALIGN
    if (!colonna.align) {
      colonna.align = 'left';
    }
  }

  public nascondiColonna(colonna: Colonna, record: any) {
    if (colonna.nascondiButton) {

      if (typeof colonna.nascondiButton === 'boolean') {
        return colonna.nascondiButton;
      } else {
        return colonna.nascondiButton(record);
      }
    }
    return false;
  }

  public titoloIcona(colonna: Colonna, record: any) {
    if (colonna.title) {

      if (typeof colonna.title === 'string') {
        return colonna.title;
      } else {
        return colonna.title(record);
      }
    }
    return false;
  }

  public iconaBottone(colonna: Colonna, record: any) {
    if (colonna.buttonIcon) {

      if (typeof colonna.buttonIcon === 'string') {
        return colonna.buttonIcon;
      } else {
        return colonna.buttonIcon(record);
      }
    }
    return false;
  }
  public idBottone(colonna: Colonna, record: any) {
    if (colonna.buttonId) {

      if (typeof colonna.buttonId === 'string') {
        return colonna.buttonId;
      } else {
        return colonna.buttonId(record);
      }
    }
    return '';
  }
}
