<app-spinner-overlay></app-spinner-overlay>
<h2 mat-dialog-title>Figure Aziendali</h2>
<div mat-dialog-content class="dialog-center-cont">
    <form [formGroup]="formFigAz" appFormContainer [larghezza]="4">

        <mat-form-field appFormElement [larghezza]="2" [riempi]="true">
            <mat-label>Nome e Cognome</mat-label>
            <input matInput placeholder="Nome e Cognome" formControlName="nome" />
            <mat-error appFormError></mat-error>
        </mat-form-field>

        <mat-form-field appFormElement [larghezza]="2" [riempi]="true">
            <mat-label>Email</mat-label>
            <input matInput placeholder="Email" formControlName="email" type="email" />
            <mat-error appFormError></mat-error>
        </mat-form-field>

        <mat-form-field appFormElement [larghezza]="2" [riempi]="true">
            <mat-label>Figura</mat-label>
            <input matInput placeholder="Figura" formControlName="figura" />
            <mat-error appFormError></mat-error>
        </mat-form-field>

        <ng-container *ngIf="!isUtenteGo">
            <mat-form-field appFormElement [larghezza]="2" class="input-form">
                <mat-label>Comitati</mat-label>
                <mat-select formControlName="comitati" placeholder="Comitati" multiple [compareWith]="compareById">
                    <mat-option *ngFor="let com of arrayComitati" [value]="com">{{com.descrizione}}</mat-option>
                </mat-select>
            </mat-form-field>
        </ng-container>

    </form>
</div>
<div mat-dialog-actions class="dialog-footer-cont">
    <div>
        <button mat-dialog-close mat-flat-button color="accent" class="accent-button">Chiudi</button>
    </div>
    <div>
        <button mat-flat-button color="primary" (click)="salva()" class="primary-button">
            Salva
        </button>
    </div>
</div>
