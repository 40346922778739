<h2 mat-dialog-title>
  Utente
</h2>
<div mat-dialog-content class="dialog-center-cont">
  <app-spinner-overlay></app-spinner-overlay>
  <form [formGroup]="formUtenti" appFormContainer [larghezza]="3">

    <mat-form-field appFormElement [larghezza]="1" [riempi]="true">
      <mat-label>Nome</mat-label>
      <input matInput placeholder="Nome" formControlName="firstName" />

      <mat-error appFormError></mat-error>
    </mat-form-field>

    <mat-form-field appFormElement [larghezza]="1" [riempi]="true">
      <mat-label>Cognome</mat-label>
      <input matInput placeholder="Cognome" formControlName="lastName" />
      <mat-error appFormError></mat-error>
    </mat-form-field>

    <mat-form-field appFormElement [larghezza]="1" [riempi]="true">
      <mat-label>Email</mat-label>
      <input matInput placeholder="Email" formControlName="email" type="email" />
      <mat-error appFormError></mat-error>
    </mat-form-field>

    <mat-form-field class="input-form" appFormElement [larghezza]="3">
      <mat-label>Ruoli Utente</mat-label>
      <mat-select formControlName="ruolo" placeholder="Ruoli Utente" [compareWith]="compareRuoli"
        (selectionChange)="onSelectionChangeRuolo($event)">
        <mat-option *ngFor="let rol of arrayRuoli" [value]="rol"
         [disabled]="ctrDisable(rol.roleName)">{{rol.descrizione}}</mat-option>
      </mat-select>
    </mat-form-field>

    <ng-container
      *ngIf="this.formUtenti.get('ruolo')?.value?.roleName === 'collaboratore' && this.utenteService.infoUtente?.utente?.ruolo?.roleName === 'configuratore'">
      <mat-form-field class="input-form" appFormElement [larghezza]="3">
        <mat-label>Responsabile</mat-label>
        <mat-select formControlName="utenteResponsabile" placeholder="Responsabile" [compareWith]="compareResponsabile"
          (selectionChange)="onSelectionChangeResponsabile($event)">
          <mat-option *ngFor=" let responsabile of objResponsabile" [value]="responsabile">{{
            (responsabile.ruolo?.descrizione?.toUpperCase()
            || '') + ' - ' + responsabile.nomeUtente }}</mat-option>
        </mat-select>
      </mat-form-field>
    </ng-container>

    <div class="separatore" appFormElement [larghezza]="3">
      <app-separatore-label titoloSeparatore="Assegnazione Aziende"></app-separatore-label>
    </div>

    <mat-form-field class="input-form" appFormElement [larghezza]="3">
      <mat-label>Aziende</mat-label>
      <mat-select formControlName="idAziende" placeholder="Aziende" [(value)]="selectedAzienda" multiple>
        <mat-option *ngFor="let azd of arrayAziende" [value]="azd.id">{{azd.ragioneSociale}}</mat-option>
      </mat-select>
    </mat-form-field>

  </form>
</div>
<div mat-dialog-actions class="dialog-footer-cont">
  <div>
    <button mat-dialog-close mat-flat-button color="accent" class="accent-button">Chiudi</button>
  </div>
  <div>
    <button mat-flat-button color="primary" (click)="salva()" class="primary-button">
      Salva
    </button>
  </div>
  <div *ngIf="canSeeLicenze() ">
    <button mat-flat-button color="accent" class="accent-button" (click)="gestioneLicenze()">Gestione Licenze</button>
  </div>
</div>
