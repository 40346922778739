import { NestedTreeControl } from '@angular/cdk/tree';
import { AfterViewInit, Component, OnDestroy, ViewChild } from '@angular/core';
import { MatSelect } from '@angular/material/select';
import { MatTreeNestedDataSource } from '@angular/material/tree';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { Azienda, AziendaService } from 'src/app/services/azienda/azienda.service';
import { TemaService } from 'src/app/services/tema/tema.service';
import { InfoUtente } from 'src/app/services/utente/utente-web.service';
import { UtenteService } from 'src/app/services/utente/utente.service';
import { UtilityService } from 'src/app/services/utility/utility.service';

/**
 * Struttura del menu sidenav
 * la struttura può avere anche sottovoci(children)
 */
interface VociMenu {
  sezione: string;
  routerMenu: string;
  icon: string;
  sottoSezione?: VociMenu[];
  disabled?: boolean;
  mostrato?: boolean
}


@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnDestroy, AfterViewInit {

  @ViewChild(MatSelect) matSelect!: MatSelect;

  public infoUtente: InfoUtente | undefined;
  public isMenuOpen: boolean = true;

  public logoPath: string = '';

  treeControl = new NestedTreeControl<VociMenu>((node) => node.sottoSezione);
  dataSource = new MatTreeNestedDataSource<VociMenu>();
  public isActive: boolean = false;

  private _subAziendaCorrente: Subscription | undefined;
  private _subTema: Subscription | undefined;
  private _isUtenteGo: boolean = false;

  public azienda: Azienda | undefined;
  public arrayAziende: Azienda[] = [];

  constructor(
    public router: Router,
    public activatedRoute: ActivatedRoute,
    private aziendaService: AziendaService,
    private utilityService: UtilityService,
    private utenteService: UtenteService,
    private temaService: TemaService
  ) {

    this.temaService.temaUpdated.subscribe((tipoInstallazione) => {
      this.logoPath = temaService.logoPath;
      if (tipoInstallazione === 'SOSTENIBILE_GO') {
        this._isUtenteGo = true;
      } else {
        this._isUtenteGo = false;
      }
      this._getMenu(this.azienda?.id)
    })

    this.infoUtente = this.utenteService.infoUtente;

    this._subAziendaCorrente = this.aziendaService.aziendaCorrente.subscribe((azienda: Azienda | undefined) => {

      this.azienda = azienda;
      this._getMenu(azienda?.id);

    });

    this.aziendaService.listaAziende.subscribe((listaAziende) => {
      this.arrayAziende = listaAziende || [];

      this.aziendaService.azienda.then((val) => {
        this.azienda = val;
      });
    });

    if (this.azienda?.id) {
      this.aziendaService.setSoglia(this.azienda?.id);
    }
  }

  ngAfterViewInit(): void {

  }

  ngOnDestroy(): void {
    this._subAziendaCorrente?.unsubscribe();
  }


  hasChild = (_: number, node: VociMenu) =>
    !!node.sottoSezione && node.sottoSezione.length > 0;

  azionaMenu(pagina: any) {


    if (pagina?.disabled === true) {

      this.utilityService.openDialog({
        titolo: 'Attenzione',
        descrizione: 'Seleziona un azienda',
        bottoni: [{
          nome_btn: 'Ok', handler: () => {
            this.matSelect.open();
          }
        }]
      })

    } else {
      this.router.navigate([pagina.routerMenu], {
        relativeTo: this.activatedRoute,
      });
    }
  }


  public compareAzienda(azienda1: Azienda, azienda2: Azienda): boolean {
    return azienda1?.id === azienda2?.id; // Sostituisci "id" con la proprietà univoca degli ambiti
  }


  public cambioAzienda(azienda: Azienda) {

    this.aziendaService.azienda = azienda;
  }

  public logout() {
    this.utenteService.logout();
  }


  private _getMenu(idAzienda?: string) {
    this.dataSource.data = [
      {
        sezione: 'Dashboard',
        routerMenu: 'dashboard',
        icon: 'dashboard',
        sottoSezione: [],
        mostrato: true
      },
      /*  {
        sezione: 'dashboard-azienda',
        routerMenu: 'dashboard-azienda',
        icon: 'dashboard',
        sottoSezione: [],
      }, */
      {
        sezione: 'Assessment di Sostenibilità',
        routerMenu: 'assessment',
        icon: 'star_border',
        sottoSezione: [],
        disabled: (idAzienda ? false : true),
        mostrato: this.utenteService.isPermessoAttivo('READ_AUTOVALUTAZIONI'),
      },
      {
        sezione: 'Policy di Sostenibilità',
        routerMenu: 'policy-sostenibilita',
        icon: 'policy',
        sottoSezione: [],
        disabled: (idAzienda ? false : true),
        mostrato: this.utenteService.isPermessoAttivo('READ_POLICY'),
      },
      {
        sezione: 'Piano di Sostenibilità',
        routerMenu: 'piano-sostenibilita',
        icon: 'event_note',
        sottoSezione: [],
        disabled: (idAzienda ? false : true),
        mostrato: this.utenteService.isPermessoAttivo('READ_PIANO'),
      },
      {
        sezione: 'Analisi Materialità',
        routerMenu: 'analisi-materialità',
        icon: 'text_snippet',
        mostrato: this.utenteService.isPermessoAttivo('READ_STAKEHOLDERS') || this.utenteService.isPermessoAttivo('READ_MATERIALITA'),
        sottoSezione: [
          {
            sezione: 'Stakeholder',
            routerMenu: 'stakeholder',
            icon: '•',
            disabled: (idAzienda ? false : true),
            mostrato: this.utenteService.isPermessoAttivo('READ_STAKEHOLDERS'),
          },
          {
            sezione: 'Materialità',
            routerMenu: 'materialita',
            icon: '•',
            disabled: (idAzienda ? false : true),
            mostrato: this.utenteService.isPermessoAttivo('READ_MATERIALITA'),
          },
        ],
      },
      {
        sezione: 'Sintesi di Sostenibilità',
        routerMenu: 'sostenibilita',
        icon: 'spa',
        disabled: (idAzienda ? false : true),
        mostrato: this.utenteService.isPermessoAttivo('READ_SINTESI'),
      },
      {
        sezione: this._isUtenteGo ? 'Report Tematico' : 'Bilancio di Sostenibilità',
        routerMenu: 'bilanci',
        icon: 'balance',
        disabled: (idAzienda ? false : true),
        mostrato: this.utenteService.isPermessoAttivo('READ_BILANCIO'),
      },
      /*   {
        sezione: 'Questionari',
        routerMenu: 'questionari',
        icon: 'indeterminate_check_box',
        sottoSezione: [
          {
            sezione: 'Lista compilazioni',
            routerMenu: 'listaCompilazioni',
            icon: '•',
          },
          { sezione: 'Compila questionario', routerMenu: '', icon: '•' },
        ],
      }, */
      /* {
        sezione: 'Statistiche',
        routerMenu: 'statistiche',
        icon: 'bar_chart',
        sottoSezione: [],
      }, */
      {
        sezione: 'Aziende',
        routerMenu: 'aziende',
        icon: 'apartment',
        mostrato: this.utenteService.isPermessoAttivo('READ_AZIENDA') || this.utenteService.isPermessoAttivo('READ_FIGURA_AZ'),
        sottoSezione: [
          {
            sezione: 'Aziende',
            routerMenu: 'aziende',
            icon: '•',
            mostrato: this.utenteService.isPermessoAttivo('READ_AZIENDA')
          },
          {
            sezione: 'Figure Aziendali',
            routerMenu: 'figure-aziendali',
            icon: '•',
            disabled: (idAzienda ? false : true),
            mostrato: this.utenteService.isPermessoAttivo('READ_FIGURA_AZ'),
          },
        ],
      },
      {
        sezione: 'Utenti',
        routerMenu: 'utenti',
        icon: 'group',
        sottoSezione: [],
        mostrato: this.utenteService.isPermessoAttivo('READ_UTENTI'),
      },
      {
        sezione: 'Configurazioni',
        routerMenu: 'configurazioni',
        icon: 'settings_application',
        mostrato: this.utenteService.isPermessoAttivo('READ_CONFIGURAZIONI'),
        sottoSezione: [
          {
            sezione: 'Ambiti',
            routerMenu: 'configurazioni-ambiti',
            icon: '•',
            mostrato: this.utenteService.isPermessoAttivo('READ_CONFIGURAZIONI')
          },
          {
            sezione: 'Tematiche',
            routerMenu: 'configurazioni-tematiche',
            icon: '•',
            mostrato: this.utenteService.isPermessoAttivo('READ_CONFIGURAZIONI')
          },
          {
            sezione: 'Impatti',
            routerMenu: 'configurazioni-impatti',
            icon: '•',
            mostrato: this.utenteService.isPermessoAttivo('READ_CONFIGURAZIONI')
          },
          {
            sezione: 'Impegni',
            routerMenu: 'configurazioni-impegni',
            icon: '•',
            mostrato: this.utenteService.isPermessoAttivo('READ_CONFIGURAZIONI')
          },
          {
            sezione: 'Azioni e Buone Pratiche',
            routerMenu: 'configurazioni-azione-e-buona-pratica',
            icon: '•',
            mostrato: this.utenteService.isPermessoAttivo('READ_CONFIGURAZIONI')
          },
          {
            sezione: 'KPI',
            routerMenu: 'configurazioni-KPI',
            icon: '•',
            mostrato: this.utenteService.isPermessoAttivo('READ_CONFIGURAZIONI')
          },
          {
            sezione: 'Assessment di Sostenibilità',
            routerMenu: 'configurazione-autovalutazioni',
            icon: '•',
            mostrato: this.utenteService.isPermessoAttivo('READ_CONFIGURAZIONI')
          },
          {
            sezione: 'Categorie Stakeholder',
            routerMenu: 'configurazioni-cate-stakeholder',
            icon: '•',
            mostrato: this.utenteService.isPermessoAttivo('READ_CONFIGURAZIONI')
          },
          {
            sezione: 'Guide Scrittura Impatto',
            routerMenu: 'configurazioni-scrittura-impatto',
            icon: '•',
            mostrato: this.utenteService.isPermessoAttivo('READ_CONFIGURAZIONI')
          },
          /* {
            sezione: 'Statistiche',
            routerMenu: 'dashboard',
            icon: '•',
            mostrato: this.utenteService.isPermessoAttivo('READ_CONFIGURAZIONI')
          }, */
          {
            sezione: 'Informativa Aziendale',
            routerMenu: 'configurazioni-informativa-aziendale',
            icon: '•',
            mostrato: this.utenteService.isPermessoAttivo('READ_CONFIGURAZIONI')
          },
          {
            sezione: 'Settori',
            routerMenu: 'configurazioni-settori',
            icon: '•',
            mostrato: this.utenteService.isPermessoAttivo('READ_CONFIGURAZIONI')
          },
          {
            sezione: 'Unità di misura',
            routerMenu: 'configurazioni-unita-di-misura',
            icon: '•',
            mostrato: this.utenteService.isPermessoAttivo('READ_CONFIGURAZIONI')
          },
          {
            sezione: 'Certificazioni',
            routerMenu: 'configurazioni-certificazioni',
            icon: '•',
            mostrato: this.utenteService.isPermessoAttivo('READ_CONFIGURAZIONI')
          },
          {
            sezione: 'Comitati',
            routerMenu: 'configurazioni-comitati',
            icon: '•',
            mostrato: this.utenteService.isPermessoAttivo('READ_CONFIGURAZIONI')
          },
          {
            sezione: 'Destinatari Policy',
            routerMenu: 'configurazioni-destinatari-policy',
            icon: '•',
            mostrato: this.utenteService.isPermessoAttivo('READ_CONFIGURAZIONI')
          },
          {
            sezione: 'Perimetro di Applicazione Policy',
            routerMenu: 'configurazioni-perimetro-app-policy',
            icon: '•',
            mostrato: this.utenteService.isPermessoAttivo('READ_CONFIGURAZIONI')
          },
          {
            sezione: 'Linee guida stampe e Mail',
            routerMenu: 'linee-guida-stampe',
            icon: '•',
            mostrato: this.utenteService.isPermessoAttivo('READ_CONFIGURAZIONI')
          },
          {
            sezione: 'Obiettivi',
            routerMenu: 'configurazioni-obiettivi-piano',
            icon: '•',
            mostrato: this.utenteService.isPermessoAttivo('READ_CONFIGURAZIONI')
          },
          {
            sezione: 'Obiettivi Aziendali',
            routerMenu: 'configurazioni-obiettivi-aziendali-piano',
            icon: '•',
            mostrato: this.utenteService.isPermessoAttivo('READ_CONFIGURAZIONI')
          },
          {
            sezione: 'Modelli Sviluppo Piano',
            routerMenu: 'configurazioni-modelli-sviluppo-piano',
            icon: '•',
            mostrato: this.utenteService.isPermessoAttivo('READ_CONFIGURAZIONI')
          },
        ],
      },
    ].map((voce) => {
      (voce.sottoSezione as VociMenu[]) = (voce.sottoSezione as VociMenu[] || []).filter((sottoVoce: VociMenu) => sottoVoce.mostrato);
      return voce;
    }).filter((voce) => voce.mostrato);

  }
}
