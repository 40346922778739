<app-spinner-overlay></app-spinner-overlay>
<div class="titolo-container" *ngIf="data?.compilate === true && data.autovalutazione">
    <div class="titolo">
        {{data.autovalutazione.titolo}}
    </div>
    <div class="punteggi">
        <div>Punteggio: <span class="punteggio">{{data.autovalutazione.punteggioPercentuale}}/100</span></div>
        <div> Data Compilazione: <span style="font-weight: bold;">{{dataCompilazione}}</span></div>
    </div>
</div>
<div mat-dialog-content class="dialog-center-cont">
    <ng-container *ngFor="let amb of ambiti">
        <div style="padding-top:0px !important">

            <div class="ambito">
                <div *ngIf="amb.titolo" class="titolo">
                    {{amb.titolo}}
                </div>
                <div *ngIf="amb.descrizione" class="desc">
                    {{amb.descrizione}}
                </div>

                <!-- <div *ngIf="amb.note" class="note">{{amb.note}}</div> -->
            </div>


            <ng-container *ngFor="let tema of amb.tematiche">

                <div class="tematiche">

                    <div *ngIf="tema.domanda" class="domanda">
                        {{tema.domanda}}
                    </div>

                    <div *ngIf="tema.descrizione" class="descrizione">
                        {{tema.descrizione}}
                    </div>

                    <!-- <div *ngIf="tema.note" class="note">{{tema.note}}</div> -->

                    <ng-container *ngIf="tema.tipo === 'scelta_singola'">
                        <mat-radio-group aria-labelledby="example-radio-group-label" class="radio-group"
                            [value]="tema.risposte?.length ? tema.risposte![0] : null" disabled="true">

                            <ng-container *ngFor="let opz of tema.opzioni">

                                <mat-radio-button [value]="opz.chiave">
                                    {{opz.valore}}
                                </mat-radio-button>

                                <div class="sotto-opzioni"
                                    *ngIf="opz.sottoOpzioni?.length && (!data?.compilate || (tema.risposte?.length  && tema.risposte![0] === opz.chiave)) ">

                                    <div *ngFor="let opzSec of opz.sottoOpzioni; let idxOpz = index"
                                        class="radio-group">

                                        <ng-container *ngIf="opzSec.tipo === 'checkbox'">

                                            <mat-checkbox [value]="opzSec.chiave" [checked]="opzSec.checked"
                                                disabled="true">
                                                {{opzSec.valore}}
                                            </mat-checkbox>

                                        </ng-container>

                                        <ng-container *ngIf="opzSec.tipo === 'azione'">

                                            <mat-label class="descrizione"
                                                style="padding-bottom: 15px;">{{opzSec.descrizione}}</mat-label>

                                            <mat-form-field>
                                                <mat-label>Risposta</mat-label>
                                                <textarea matInput [placeholder]="'Risposta'" rows="4" [disabled]="true"
                                                    [(ngModel)]="opzSec.risposta"></textarea>
                                                <mat-error appFormError></mat-error>
                                            </mat-form-field>

                                        </ng-container>

                                        <ng-container *ngIf="opzSec.tipo === 'kpi'">

                                            <mat-label class="descrizione"
                                                style="padding-bottom: 15px;">{{opzSec.descrizione}}</mat-label>

                                            <div appFormContainer [larghezza]="3">

                                                <mat-form-field appFormElement [larghezza]="1">
                                                    <mat-label>Unità di Misura</mat-label>
                                                    <mat-select [placeholder]="'Unità di Misura'" [disabled]="true"
                                                        [(ngModel)]="opzSec.valoriKpi[0].idUnitaDiRiferimento">
                                                        <mat-option
                                                            *ngFor="let um of arrayUm[opzSec.valoriKpi[0].idCategoriaUnitaDiMisura]"
                                                            [value]="um.id">{{um.descrizione}}</mat-option>
                                                    </mat-select>
                                                </mat-form-field>

                                                <mat-form-field appFormElement [larghezza]="1">
                                                    <mat-label>Risposta</mat-label>
                                                    <input matInput [placeholder]="'Risposta'" [disabled]="true"
                                                        [(ngModel)]="opzSec.valoriKpi[0].valoreAttribuito" type="number"
                                                        appInputNumber step="0.01" inputmode="numeric" />
                                                    <mat-error appFormError></mat-error>
                                                </mat-form-field>

                                            </div>

                                        </ng-container>

                                    </div>

                                </div>

                            </ng-container>

                        </mat-radio-group>
                    </ng-container>

                    <ng-container *ngIf="tema.tipo === 'scelta_multipla'">

                        <div class="checkbox-group" *ngFor="let opz of tema.opzioni; let idxOpz = index">

                            <mat-checkbox disabled="true" #checkboxOpz [value]="opz.chiave"
                                [checked]="(tema?.risposte || []).indexOf(opz.chiave) > -1">
                                {{opz.valore}}
                            </mat-checkbox>


                            <div class="sotto-opzioni" *ngIf="opz.sottoOpzioni?.length && checkboxOpz.checked">

                                <div *ngFor="let opzSec of opz.sottoOpzioni">

                                    <ng-container *ngIf="opzSec.tipo === 'checkbox'">
                                        <div class="radio-group">
                                            <mat-checkbox disabled="true" [value]="opzSec.chiave"
                                                [checked]="opzSec.checked">
                                                {{opzSec.valore}}
                                            </mat-checkbox>
                                        </div>
                                    </ng-container>

                                    <ng-container *ngIf="opzSec.tipo === 'azione' && !!(opzSec.risposta || '').trim()">
                                        <div class="radio-group">
                                            <mat-checkbox disabled="true" #checkSottoOpzioneAttiva
                                                [checked]="!!(opzSec.risposta || '').trim()">
                                                <mat-label class="descrizione"
                                                    style="padding-bottom: 15px;">{{opzSec.descrizione}}</mat-label>
                                            </mat-checkbox>

                                            <ng-container *ngIf="checkSottoOpzioneAttiva.checked">

                                                <mat-form-field>
                                                    <mat-label>Risposta</mat-label>
                                                    <textarea matInput [placeholder]="'Risposta'" rows="4"
                                                        [(ngModel)]="opzSec.risposta" [disabled]="true"></textarea>
                                                    <mat-error appFormError></mat-error>
                                                </mat-form-field>
                                            </ng-container>
                                        </div>
                                    </ng-container>

                                    <ng-container
                                        *ngIf="opzSec.tipo === 'kpi' && !!(opzSec.valoriKpi[0].valoreAttribuito !== null && opzSec.valoriKpi[0].valoreAttribuito !== 0) ">
                                        <div class="radio-group">
                                            <mat-checkbox disabled="true" #checkSottoOpzioneAttiva
                                                [checked]="!!((opzSec.valoriKpi[0].valoreAttribuito ?? '')+'')">
                                                <mat-label class="descrizione"
                                                    style="padding-bottom: 15px;">{{opzSec.descrizione}}</mat-label>
                                            </mat-checkbox>

                                            <div appFormContainer [larghezza]="3"
                                                *ngIf="checkSottoOpzioneAttiva.checked">

                                                <mat-form-field appFormElement [larghezza]="1">
                                                    <mat-label>Unità di Misura</mat-label>
                                                    <mat-select [placeholder]="'Unità di Misura'" [disabled]="true"
                                                        [(ngModel)]="opzSec.valoriKpi[0].idUnitaDiRiferimento">
                                                        <mat-option
                                                            *ngFor="let um of arrayUm[opzSec.valoriKpi[0].idCategoriaUnitaDiMisura]"
                                                            [value]="um.id">{{um.descrizione}}</mat-option>
                                                    </mat-select>
                                                </mat-form-field>

                                                <mat-form-field appFormElement [larghezza]="1">
                                                    <mat-label>Risposta</mat-label>
                                                    <input matInput [placeholder]="'Risposta'" [disabled]="true"
                                                        [(ngModel)]="opzSec.valoriKpi[0].valoreAttribuito" type="number"
                                                        appInputNumber step="0.01" inputmode="numeric" />
                                                    <mat-error appFormError></mat-error>
                                                </mat-form-field>

                                            </div>
                                        </div>

                                    </ng-container>

                                </div>

                            </div>

                        </div>

                    </ng-container>
                </div>
            </ng-container>

        </div>

    </ng-container>
</div>
<div mat-dialog-actions class="dialog-footer-cont">
    <div>
        <button mat-dialog-close mat-flat-button color="accent" class="accent-button">Chiudi</button>
    </div>
    <button *ngIf="this.data.autovalutazione?.id" mat-flat-button color="accent" (click)="stampa()"
        class="accent-button" style="margin: 0 !important;">
        <mat-icon class="material-icons-outlined">
            print
        </mat-icon>Stampa
    </button>
</div>