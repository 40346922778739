<div class="contenitore">

    <h2 mat-dialog-title>Invia un anteprima Mail</h2>

    <div mat-dialog-content class="dialog-center-cont">

        <form class="form">
            <mat-form-field class="full-width">
                <mat-label>Email</mat-label>
                <input type="email" matInput [formControl]="emailFormControl" [errorStateMatcher]="matcher"
                    placeholder="esempio@example.com">
                <!--      <mat-hint>Invio mail non </mat-hint> -->
                <mat-error *ngIf="emailFormControl.hasError('email') && !emailFormControl.hasError('required')">
                    Si prega di inserire una mail valida!
                </mat-error>
                <mat-error *ngIf="emailFormControl.hasError('required')">
                    La mail è <strong>obbligatoria</strong>
                </mat-error>
            </mat-form-field>
        </form>
    </div>
    <div mat-dialog-actions class="dialog-footer-cont">
        <div>
            <button mat-dialog-close mat-flat-button color="accent" class="accent-button">Chiudi</button>
        </div>
        <div>
            <button mat-flat-button color="primary" (click)=" invia()" class="primary-button">
                Invia
            </button>
        </div>
    </div>
</div>
