import { StepperSelectionEvent } from '@angular/cdk/stepper';
import { Component, Inject, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatButton } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSelectChange } from '@angular/material/select';
import { MatStepper } from '@angular/material/stepper';
import { catchError, debounceTime, finalize, firstValueFrom, forkJoin, map, merge, Observable, of, Subscription, switchMap, tap, throwError } from 'rxjs';
import { CfgQuestionarioComponent } from 'src/app/components/cfg-questionario/cfg-questionario.component';
import { Filters, FnCaricamentoDati, FnCaricamentoStyle, SortBy } from 'src/app/components/lista-tabellare/classes/lista-tabellare-data-source';
import { Filtri, Colonna, ListaTabellareComponent, BottoniListaEvent } from 'src/app/components/lista-tabellare/lista-tabellare.component';
import { SelezioneObiettiviPianoComponent } from 'src/app/components/selezione-elementi-questionario/metodi-selezione/selezione-obiettivi-piano/selezione-obiettivi-piano.component';
import { SpinnerOverlayComponent } from 'src/app/components/spinner-overlay/spinner-overlay.component';
import { DialogCreaMaterialitaComponent } from 'src/app/page/analisi-materialita/materialita/dialog/dialog-crea-materialita/dialog-crea-materialita.component';
import { DialogCreaBilancioSostenibilitaComponent } from 'src/app/page/bilancio-sostebilita/dialog/dialog-crea-bilancio-sostenibilita/dialog-crea-bilancio-sostenibilita.component';
import { DialogCreaPolicySostenibilitaComponent } from 'src/app/page/policy-sostenibilita/dialog/dialog-crea-policy-sostenibilita/dialog-crea-policy-sostenibilita.component';
import { DialogCreaSintesiSostenibilitaComponent } from 'src/app/page/sintesi-sostenibilita/dialog/dialog-crea-sintesi-sostenibilita/dialog-crea-sintesi-sostenibilita.component';
import { AutovalutazioneCompilata, AutovalutazioniService } from 'src/app/services/autovalutazioni/autovalutazioni.service';
import { AziendaService } from 'src/app/services/azienda/azienda.service';
import { BilancioService, BilancioSostenibilita } from 'src/app/services/bilancio/bilancio.service';
import { MaterialitaService } from 'src/app/services/materialita/materialita.service';
import { PianoService, PianoSostenibilita } from 'src/app/services/piano/piano.service';
import { PolicyService, PolicySostenibilita } from 'src/app/services/policy/policy.service';
import { Settore, SettoriService, SottoSettore } from 'src/app/services/settori/settori.service';
import { AnalisiMaterialita, ElementiCruscotto, SintesiService, SintesiSostenibilita } from 'src/app/services/sintesi/sintesi.service';
import { UtilityService } from 'src/app/services/utility/utility.service';
import { DialogAssociaTargetObiettiviComponent } from '../dialog-associa-target-obiettivi/dialog-associa-target-obiettivi.component';
import { DialogAssegnazioniComponent } from 'src/app/page/sintesi-sostenibilita/dialog/dialog-assegnazioni/dialog-assegnazioni.component';
import { DialogAssociaFiguraAziendaleComponent } from 'src/app/components/selezione-elementi-questionario/dialog/dialog-associa-figura-aziendale/dialog-associa-figura-aziendale.component';
import { DialogCreaTemplateMailComponent } from 'src/app/page/analisi-materialita/materialita/dialog/dialog-crea-template-mail/dialog-crea-template-mail.component';
import { DialogCompilazioneFigureComponent } from 'src/app/page/sintesi-sostenibilita/dialog/dialog-compilazione-figure/dialog-compilazione-figure.component';
import { es } from 'date-fns/locale';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { CfgObiettiviAziendaliComponent } from 'src/app/page/configurazioni/cfg-obiettivi-aziendali/cfg-obiettivi-aziendali.component';
import { DialogCreaObiettivoAziendaliComponent } from 'src/app/page/configurazioni/cfg-obiettivi-aziendali/dialog/dialog-crea-obiettivo-aziendali/dialog-crea-obiettivo-aziendali.component';
import { DialogCreaCfgModelliSviluppoPianoComponent } from 'src/app/page/configurazioni/cfg-modelli-sviluppo-piano/dialog-crea-cfg-modelli-sviluppo-piano/dialog-crea-cfg-modelli-sviluppo-piano.component';



@Component({
  selector: 'app-dialog-crea-piano-sostenibilita',
  templateUrl: './dialog-crea-piano-sostenibilita.component.html',
  styleUrls: ['./dialog-crea-piano-sostenibilita.component.scss']
})
export class DialogCreaPianoSostenibilitaComponent {

  @ViewChild('stepper') stepper!: MatStepper;
  @ViewChild('btnAvanti') btnAvanti!: MatButton;
  @ViewChild(SpinnerOverlayComponent) spinnerOver!: SpinnerOverlayComponent;
  @ViewChild(CfgQuestionarioComponent) componenteSurvey!: CfgQuestionarioComponent;
  @ViewChild('tabellaTarget') tabellaTarget!: ListaTabellareComponent;
  @ViewChild('tabellaCruscotto') tabellaCruscotto!: ListaTabellareComponent;
  @ViewChild(SelezioneObiettiviPianoComponent) componenteSelObiettiviPiano!: SelezioneObiettiviPianoComponent;

  public mappaAnnoSettore: { [anno: string]: { [settore: string]: boolean } } = {};
  public objQuestionarioObiettivi = [];
  public arrayAnni: string[] = [];
  public settoriAzienda: Settore[] = [];
  public guidaDescrizione: string | undefined = undefined;
  public mostraDescrizione: boolean = false;
  public arraySottoSettori: SottoSettore[] = [];
  public settoreGenerale: Settore | undefined = undefined;

  public selectedAnalisiMaterialita: string[] = [];
  public arrayAnalisiMaterialita: AnalisiMaterialita[] = [];
  public arrayAnalisiAutovalutazione: AutovalutazioneCompilata[] = [];
  public arraySintesiSostenibilita: SintesiSostenibilita[] = [];
  public arrayPolicySostenibilita: PolicySostenibilita[] = [];
  public arrayBilancioSostenibilita: BilancioSostenibilita[] = [];
  public loadingAnalisiMaterialita = false;
  public loadingAutovalutazione = false;
  public mostraBottoneAssegnazioni: boolean = false;
  public idQuestionario: string | undefined = undefined;

  private _subCambioValoriSettoreSottoSettore: Subscription | undefined = undefined;
  private _subValueChangeSettore: Subscription | undefined = undefined;
  private _subValueChangeTipoSettore: Subscription | undefined = undefined;
  private _piano: any = {};

  public isTargetNonCompletto: boolean = false;

  public tQualitativiCompilati: number = 0;
  public tQualitativiNonCompilati: number = 0;

  public tQuantitativiCompilati: number = 0;
  public tQuantitativiNonCompilati: number = 0;

  public objDocumento: any;

  get piano() {
    return this._piano;
  }

  public formPianoSostenibilita = new FormGroup({

    id: new FormControl<string | undefined>(undefined, {
      nonNullable: true,
      validators: [],
    }),

    idAzienda: new FormControl<string>('', {
      nonNullable: true,
      validators: [Validators.required],
    }),

    anno: new FormControl<string>('', {
      nonNullable: true,
      validators: [Validators.required],
    }),

    tipoSettore: new FormControl<'GENERALE' | 'SPECIFICO' | undefined>(undefined, {
      nonNullable: true,
      validators: [Validators.required],
    }),

    settori: new FormControl<Settore | undefined>(undefined, {
      nonNullable: true,
      validators: [Validators.required],
    }),

    sottoSettori: new FormControl<SottoSettore[]>([], {
      nonNullable: true
    }),

    idMaterialita: new FormControl<string[] | AnalisiMaterialita>({ disabled: true, value: [] }, {
      nonNullable: true,
      validators: [],
    }),

    idAutovalutazioneCompilata: new FormControl<string | AutovalutazioneCompilata>('', {
      nonNullable: true,
      validators: [],
    }),

    idSintesiSostenibilita: new FormControl<string | SintesiSostenibilita>('', {
      nonNullable: true,
      validators: [],
    }),

    idBilancioSostenibilita: new FormControl<string | BilancioSostenibilita>('', {
      nonNullable: true,
      validators: [],
    }),

    idPolicySostenibilita: new FormControl<string | PolicySostenibilita>('', {
      nonNullable: true,
      validators: [],
    }),

    tipo: new FormControl<string>('PIANO', {
      nonNullable: true,
      validators: [],
    }),
  });

  public colonneTarget: { [key: string]: Colonna } = {
    obiettiviSelezionati: {
      title: 'Obiettivi Selezionati',
      value: 'titolo',
      sortable: true
    },
    idTargetQualitativi: {
      title: 'Target Qualitativi',
      value: (record: any) => (record?.idTargetQualitativi.length),
      sortable: true
    },
    idTargetQuantitativi: {
      title: 'Target Quantitativi',
      value: (record: any) => (record?.idTargetQuantitativi.length),
      sortable: true
    },
    aggiungiTarget: {
      type: 'button',
      buttonIcon: 'add',
      title: 'Aggiungi Target',
      buttonId: 'aggiungiTarget',
      buttonMostraSempre: true,
    },
  };


  public filtriCruscotto: Filtri[] = [
    {
      titolo: 'Figure Aziendali',
      forControlName: 'figureAziendali',
      input: 'text',
    },
    {
      titolo: 'Nome',
      forControlName: 'nome',
      input: 'text',
    },
    {
      titolo: 'Cognome',
      forControlName: 'cognome',
      input: 'text',
    },
    {
      titolo: 'Email',
      forControlName: 'email',
      input: 'text',
    },
  ];

  public colonneCruscotto: { [key: string]: Colonna } = {
    stakeholder: {
      title: 'Figure Aziendali',
      value: 'figura'
    },
    tipologia: {
      title: 'Nome',
      value: 'nome'
    },
    email: {
      title: 'Email',
      value: 'email'
    },
    stato: {
      title: 'Stato',
      value: (record: any) => {
        if (record?.stato === 'ROSSO') { // non compilato
          return '<div style="display: flex;justify-content: center;align-items:center;background-color:#cf5b61;width:25px;text-align: center;border-radius: 15px;height:25px"></div>'
        } else if (record?.stato === 'GIALLO') { // ha inviato la mail ma non è stato compilato (valore solo per gli stakeholder con modalita compilazione "INVIAMAIL")
          return '<div style="display: flex;justify-content: center;align-items:center;background-color:#c7a045;width:25px;text-align: center;border-radius: 15px;height:25px"></div>'
        } else if (record?.stato === 'VERDE') { // compilato
          return '<div style="display: flex;justify-content: center;align-items:center;background-color:#729373;width:25px;text-align: center;border-radius: 15px;height:25px"></div>'
        } else {
          return '';
        }
      }
    },
    history: {
      type: 'button',
      buttonIcon: 'history_edu',
      title: 'Compila',
      buttonId: 'compila',
      buttonMostraSempre: true
    },

    modifica: {
      type: 'button',
      buttonIcon: 'edit',
      title: 'Modifica',
      buttonId: 'modifica',
      nascondiButton: (record) => (record?.stato === 'VERDE'),
      buttonMostraSempre: true
    },
  };
  constructor(
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<DialogCreaPianoSostenibilitaComponent>,
    private materialitaService: MaterialitaService,
    private utilityService: UtilityService,
    private aziendaService: AziendaService,
    private settoriService: SettoriService,
    private sintesiService: SintesiService,
    private bilancioService: BilancioService,
    private policyService: PolicyService,
    private pianoService: PianoService,
    private autovalutazioniService: AutovalutazioniService,
    @Inject(MAT_DIALOG_DATA) public data: {
      piano: PianoSostenibilita
    }) {
    const annoCorrente = new Date().getFullYear();

    // Aggiungi gli anni indietro di 5 anni e in avanti di 1 anno all'array
    for (let i = annoCorrente - 5; i <= annoCorrente + 1; i++) {
      this.arrayAnni.push(i.toString());
    }

    this.aziendaService.azienda.then((azienda) => {
      if (azienda) {
        this.formPianoSostenibilita.get('idAzienda')?.setValue(azienda.id);

      }
    });

  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.init();
    }, 0);
  }

  async init() {

    this.spinnerOver.show();

    await this._caricaSettori();


    // Ad ogni cambio di settore o sottosettore bisogna refreshare materialità e autovalutazioni (assesment).
    // Si usa un merge per evitare che partano chiamate multiple parallele quando cambiano settori e/o sottoSettori.
    this._subCambioValoriSettoreSottoSettore = merge(
      this.formPianoSostenibilita.get('settori')!.valueChanges,
      this.formPianoSostenibilita.get('sottoSettori')!.valueChanges
    ).pipe(
      debounceTime(500)
    ).subscribe((value) => {
      if (this.formPianoSostenibilita.get('settori')?.value && !this.formPianoSostenibilita.get('id')?.value) {

        if (this.formPianoSostenibilita.get('settori')?.value) {
          this._cambioSelSettore();
        }
      }
    });

    this._subValueChangeSettore = this.formPianoSostenibilita.get('settori')!.valueChanges.subscribe((value) => {

      if (value) {
        this.arraySottoSettori = this.settoriAzienda.find(sett => sett.id === value.id)?.sottoSettori || [];
      }

      this._updateSottoSettoriSelezionati();

    });

    this._subValueChangeTipoSettore = this.formPianoSostenibilita.get('tipoSettore')?.valueChanges.subscribe((value) => {
      if (value === 'GENERALE') {
        this.formPianoSostenibilita.get('settori')?.setValue(this.settoreGenerale);
      } else if (value === 'SPECIFICO') {
        this.formPianoSostenibilita.get('settori')?.setValue(this.settoriAzienda?.length ? this.settoriAzienda[0] : undefined);
      }
    });

    if (this.data.piano) {
      await this._setDatiPiano(this.data.piano);

    } else if (this.settoriAzienda?.length) {
      // Preselezione settore
      this.formPianoSostenibilita.get('tipoSettore')?.setValue('SPECIFICO');
      this.formPianoSostenibilita.get('settori')?.setValue(this.settoriAzienda[0]);
    } else {
      this.formPianoSostenibilita.get('tipoSettore')?.setValue('GENERALE');
    }




    this.formPianoSostenibilita.get('idSintesiSostenibilita')?.disable();
    this.formPianoSostenibilita.get('idPolicySostenibilita')?.disable();
    this.formPianoSostenibilita.get('idMaterialita')?.disable();
    this.formPianoSostenibilita.get('idAutovalutazioneCompilata')?.disable();
    this.formPianoSostenibilita.get('idBilancioSostenibilita')?.disable();
    this.spinnerOver.hide();

  }

  ngOnDestroy(): void {
    this._subValueChangeSettore?.unsubscribe();
    this._subValueChangeTipoSettore?.unsubscribe();
    this._subCambioValoriSettoreSottoSettore?.unsubscribe();
  }

  /**
   * Metodo che nel cambio del settore mi ricalcola le selezioni
   * di sintesi materialità, bilancio e assessment pubblicate
   */
  private _cambioSelSettore() {
    this.spinnerOver.show()
    this.formPianoSostenibilita.get('idSintesiSostenibilita')?.enable();
    this.formPianoSostenibilita.get('idPolicySostenibilita')?.enable();
    this.formPianoSostenibilita.get('idMaterialita')?.enable();
    this.formPianoSostenibilita.get('idAutovalutazioneCompilata')?.enable();
    this.formPianoSostenibilita.get('idBilancioSostenibilita')?.enable();

    const anno = this.formPianoSostenibilita.get('anno')?.value;
    const idSettore = this.formPianoSostenibilita.get('settori')?.value?.id;
    const idSottoSettori: string[] = (this.formPianoSostenibilita.get('sottoSettori')?.value?.map(sottoSet => sottoSet.id).filter(val => val !== undefined) || []) as string[];

    if (idSettore) {
      firstValueFrom(this.getForkDatiIniziali(idSettore, idSottoSettori));
    }

    if (anno && idSettore) {
      firstValueFrom(this._getAutovalutazioniCompilateObs(anno, idSettore, idSottoSettori));
    }

  }

  /**
* Setta nel formGroup i dati della piano
* @param piano
*/
  private async _setDatiPiano(piano: PianoSostenibilita) {

    let settoreSel: Settore | undefined = piano.settori?.length ? piano.settori[0] : undefined;
    let anno: string | undefined = piano.anno;

    const idSottoSettori: string[] = ((piano.sottoSettori || []).map((sottoSet: SottoSettore) => sottoSet.id).filter((val: string | undefined) => val !== undefined) || []) as string[];

    if (settoreSel) {
      await firstValueFrom(this.getForkDatiIniziali(settoreSel.id, idSottoSettori))
      if (anno) {
        await firstValueFrom(this._getAutovalutazioniCompilateObs(anno, settoreSel.id, idSottoSettori))
      }
    }

    Object.keys(piano).forEach((value) => {

      if (value === 'settori') {
        // Settore si imposta per ultimo, per non far scattare il change quando non è ancora settato l'array dei sottosettori
        settoreSel = (piano as any)[value][0];
      } else {
        this.formPianoSostenibilita.get(value)?.setValue((piano as any)[value]);
      }
    });

    if (settoreSel) {

      if (!this.settoriAzienda.find((set) => set.id === settoreSel!.id)) {
        // Il settore del bilancio non è negli array dei settori azienda.
        // Potrebbe essere stato tolto a posteriori, dopo la creazione del piano.
        // Per non lasciare il campo vuoto, si setta nell'array il settore che viene dal piano.
        this.settoriAzienda = [settoreSel];
      }

      this.formPianoSostenibilita.get('settori')?.setValue(settoreSel);
    }

    if (piano.idMaterialita) {
      // cerco il nome della materilita in base all id
      const analisiMaterialita = this.arrayAnalisiMaterialita.find(analisi => analisi.idMaterialita === piano.idMaterialita)
      if (analisiMaterialita) {
        this.formPianoSostenibilita.get('idMaterialita')?.setValue(analisiMaterialita);
        this._cambiaInfoBoxDesc(analisiMaterialita.anno);
      }
    }

    if (piano.idSintesiSostenibilita) {
      const sintesiSostenibilita = this.arraySintesiSostenibilita.find(sintesi => sintesi.id === piano.idSintesiSostenibilita)
      if (sintesiSostenibilita) {
        this.formPianoSostenibilita.get('idSintesiSostenibilita')?.setValue(sintesiSostenibilita);
      }
    }
    if (piano.idPolicySostenibilita) {
      const policySostenibilita = this.arrayPolicySostenibilita.find(policy => policy.id === piano.idPolicySostenibilita)
      if (policySostenibilita) {
        this.formPianoSostenibilita.get('idPolicySostenibilita')?.setValue(policySostenibilita);
      }
    }

    if (piano.idBilancioSostenibilita) {
      const bilancioSostenibilita = this.arrayBilancioSostenibilita.find(bilancio => bilancio.id === piano.idBilancioSostenibilita)
      if (bilancioSostenibilita) {
        this.formPianoSostenibilita.get('idBilancioSostenibilita')?.setValue(bilancioSostenibilita);
      }
    }

    if (piano.idAutovalutazioneCompilata) {
      const autovalutazioneCompilata = this.arrayAnalisiAutovalutazione.find(aut => aut.id === piano.idAutovalutazioneCompilata)
      if (autovalutazioneCompilata) {
        this.formPianoSostenibilita.get('idAutovalutazioneCompilata')?.setValue(autovalutazioneCompilata);
      }
    }

    //Disabilito campi in modifica
    if (this.formPianoSostenibilita.get('id')?.value) {
      this.formPianoSostenibilita.get('anno')?.disable();
      this.formPianoSostenibilita.get('settori')?.disable();
      this.formPianoSostenibilita.get('sottoSettori')?.disable();
    }
  }
  /**
   * Bottone indietro
   * @returns step precedente
   */
  public indietro() {
    switch (this.stepper.selectedIndex) {
      case 0:
        return true;
      case 1:
      case 2:
      case 3:
      case 4:
      case 5:
      case 6:
      case 7:
      case 8:
      case 9:
        this.stepper.previous();
        return true;
      default:
        return null;
    }
  }

  /**
   * Bottone avanti che salva i dati sullo step attuale
   * @returns metodo in base allo step attuale
   */
  public avanti() {
    switch (this.stepper.selectedIndex) {
      case 0:
        return this._salvaStepPiano();
      case 1:
        return this._salvaStepTematiche();

      case 2:
        return this._salvaStepObiettivi();

      case 3:
        return this._ctrTarget();

      case 4:
        return this.stepper.next();
      case 5:
        return this.stampa();
      default:
        return;
    }
  }

  /* Mi carica i dati nello step selezionato */
  cambioStep(event: StepperSelectionEvent) {
    this.mostraBottoneAssegnazioni = false;

    switch (event.selectedIndex) {
      case 0:
        return;

      case 1:
        this.btnAvanti.disabled = true;
        return this._caricaStepTematiche();

      case 2:
        this.mostraBottoneAssegnazioni = true;
        this.btnAvanti.disabled = true;
        return this._getObiettiviPiano();

      case 3:
        this.spinnerOver.hide();
        return this.tabellaTarget.caricaDati();

      case 4:
        this.spinnerOver.show();
        return this.caricaStepCruscotto();

      case 5:
        this.spinnerOver.show();
        return this.caricaDocumento();

      default:
        return;
    }
  }

  onSelectionChangeMaterialita(event: MatSelectChange) {
    if (event?.value?.anno) {
      this._cambiaInfoBoxDesc(event?.value?.anno)
    }
  }

  onSelectionChangeAnno() {

    const anno = this.formPianoSostenibilita.get('anno')?.value;
    const idSettore = this.formPianoSostenibilita.get('settori')?.value?.id;
    const annoMaterialita = (this.formPianoSostenibilita.get('idMaterialita')?.value as AnalisiMaterialita).anno;
    const idSottoSettori: string[] = (this.formPianoSostenibilita.get('sottoSettori')?.value?.map(sottoSet => sottoSet.id).filter(val => val !== undefined) || []) as string[];

    if (anno && idSettore) {
      firstValueFrom(this._getAutovalutazioniCompilateObs(anno, idSettore, idSottoSettori));
    }
    if (annoMaterialita) {
      this._cambiaInfoBoxDesc(annoMaterialita)
    }

  }

  onSelectionChangeAutovalutazione(event: MatSelectChange) { }

  compareById(elemento1: any, elemento2: any): boolean {
    return elemento1?.id === elemento2?.id; // Sostituisci "id" con la proprietà univoca dei settori
  }

  compareAnalisiMaterialita(materialita1: AnalisiMaterialita, materialita2: AnalisiMaterialita): boolean {
    return materialita1.idMaterialita === materialita2.idMaterialita; // Sostituisci "id" con la proprietà univoca delle materialità
  }

  compareAnalisiAutovalutazione(autovalutazione1: AutovalutazioneCompilata, autovalutazione2: AutovalutazioneCompilata): boolean {
    return autovalutazione1.id === autovalutazione2.id;
  }

  onSelectionChangePolicy(event: MatSelectChange) {
  }
  comparePolicySostenibilita(policy1: PolicySostenibilita, policy2: PolicySostenibilita): boolean {
    return policy1.idMaterialita === policy2.idMaterialita; // Sostituisci "id" con la proprietà univoca delle policy
  }

  onSelectionChangeSintesi(event: MatSelectChange) {
  }

  compareSintesiSostenibilita(sintesi1: SintesiSostenibilita, sintesi2: SintesiSostenibilita): boolean {
    return sintesi1.idMaterialita === sintesi2.idMaterialita; // Sostituisci "id" con la proprietà univoca delle sintesi
  }

  onSelectionChangeBilancio(event: MatSelectChange) {
  }

  compareBilancioSostenibilita(bilancio1: BilancioSostenibilita, bilancio2: BilancioSostenibilita): boolean {
    return bilancio1.idMaterialita === bilancio2.idMaterialita; // Sostituisci "id" con la proprietà univoca delle bilancio
  }

  /* Gestione cancellazione in mat select */
  hasValue(formControlName: string): boolean {
    const control = this.formPianoSostenibilita.get(formControlName);
    const controlValue = control?.value;

    if (formControlName === 'idMaterialita') {
      return !!(this.data.piano || !controlValue || (Array.isArray(controlValue) && controlValue.length === 0) || control.status === 'DISABLED');
    } else {
      return !!(this.data.piano || !controlValue || control.status === 'DISABLED');
    }
  }

  clearInput(formControlName: string, event?: Event): void {
    const field = this.formPianoSostenibilita.get(formControlName);
    if (!field?.disabled) {
      field?.reset();
      field?.markAsDirty();
      field?.markAsTouched();
    }
    if (event) event.stopPropagation();

    const idMaterialitaValue = this.formPianoSostenibilita.get('idMaterialita')?.value;
    if (!idMaterialitaValue || !Array.isArray(idMaterialitaValue) || idMaterialitaValue.length === 0) {
      this.mostraDescrizione = false;
    }
  }

  mostraDescrizioneAssessment() {
    return this.arrayAnalisiAutovalutazione.length ? 'Assessment' : 'Assessment non selezionato'
  }
  mostraDescrizioneMaterialita() {
    return this.arrayAnalisiMaterialita.length ? 'Analisi Materialità' : 'Materialità non selezionata'
  }
  mostraDescrizioneSintesi() {
    return this.arraySintesiSostenibilita.length ? 'Sintesi di Sostenibilità' : 'Sintesi non selezionata'
  }
  mostraDescrizioneBilancio() {
    return this.arraySintesiSostenibilita.length ? 'Bilancio di Sostenibilità' : 'Bilancio non selezionato'
  }
  mostraDescrizionePolicy() {
    return this.arrayPolicySostenibilita.length ? 'Policy di Sostenibilità' : 'Policy non selezionato'
  }

  /**
   * Metodo gestione infobox quando scelta la materialità
   * Controllo se anno della materialità ha anzianità >= 2 anni
   * @param annoMaterialita
   */
  private _cambiaInfoBoxDesc(annoMaterialita: string) {

    const annoControl = this.formPianoSostenibilita.get('anno');
    if (annoControl) {

      const annoSintesi = parseInt(annoControl.value, 10);
      const annoMaterialitaNum: number = parseInt(annoMaterialita, 10);

      const diffAnno: number = annoSintesi - annoMaterialitaNum;
      if (diffAnno >= 2) {
        this.guidaDescrizione = "Sono passati 2 anni dall'Analisi di Materialità selezionata";
        this.mostraDescrizione = true;
      } else {
        this.mostraDescrizione = false;
      }
    }
  }

  /**
   * Gestione caricamento settori e sotto settori
   */
  private async _caricaSettori() {
    this.spinnerOver.show();
    try {
      const risp = await firstValueFrom(
        forkJoin({
          generale: this.settoriService.getSettoreGenerale().pipe(
            catchError((err) => {
              console.error('ERRORE GET SETTORE GENERALE : ', err);
              // Errore recupero settore generale, potrebbe non esistere. Si torna undefined
              return of(undefined);
            })
          ),
          specifici: this.aziendaService.getSettoriAzienda(),
          pianiCreate: this.pianoService.getPianoSostenibilita(0, 1000, '', []).pipe(
            catchError((err) => {
              console.error('ERRORE GET PIANI CREATE : ', err);
              return of(undefined);
            })
          ),
        })
      );

      this.settoriAzienda = risp?.specifici || [];
      this.settoreGenerale = risp?.generale;

      for (const piano of risp?.pianiCreate?.content) {
        const anno = piano.anno;
        const settore = piano.settori[0]?.id;

        if (!this.mappaAnnoSettore[anno]) {
          this.mappaAnnoSettore[anno] = {};
        }

        this.mappaAnnoSettore[anno][settore] = true;
      }


      if (!this.settoriAzienda?.length && !this.settoreGenerale) {
        console.error('NESSUN SETTORE DISPONIBILE, IMPOSSIBILE PROSEGUIRE');
      }

      this.arraySottoSettori = this.settoriAzienda.find(sett => sett.id === this.formPianoSostenibilita.get('settori')?.value?.id)?.sottoSettori || [];

      this.spinnerOver.hide();

    } catch (error) {

      console.error(error);

      this.spinnerOver.hide();

      this.utilityService.openDialog({
        titolo: 'Attenzione',
        descrizione: 'Errore nel recupero dei settori',
        bottoni: [{ nome_btn: 'Ok' }]
      });

      this.dialogRef.close();

    }

  }

  private _updateSottoSettoriSelezionati() {
    const sottoSettoriSel = this.formPianoSostenibilita.get('sottoSettori')?.value || [];

    const sottoSettoriaggiornati = sottoSettoriSel.filter(
      (sottoSettSel) => {
        return this.arraySottoSettori && sottoSettSel.id && !!this.arraySottoSettori.find(
          (sottoSett) => {
            return sottoSett.id === sottoSettSel.id;
          });
      }
    );

    this.formPianoSostenibilita.get('sottoSettori')?.setValue(sottoSettoriaggiornati || []);
  }

  /**
     * ForkJoin che mi valorizza array delle sintesi bilancio e materialità pubblicate
     * @param idSettore
     * @param idSottoSettori
     * @returns
     */
  public getForkDatiIniziali(idSettore: string, idSottoSettori?: string[]): Observable<any> {
    if (idSettore) {
      return forkJoin({
        materialita: this._getMaterialitaPubblicateObs(idSettore),
        sintesi: this._getSintesiPubblicateObs(idSettore, idSottoSettori),
        bilnacio: this._getBilanciPubblicatiObs(idSettore, idSottoSettori),
        policy: this._getPolicyPubblicateObs(idSettore, idSottoSettori),
      }).pipe(
        switchMap((results) => {
          this.arrayAnalisiMaterialita = results.materialita;
          this.arraySintesiSostenibilita = results.sintesi;
          this.arrayBilancioSostenibilita = results.bilnacio;
          this.arrayPolicySostenibilita = results.policy;
          this.spinnerOver.hide();
          return of(true)
        }),
        catchError((err) => {
          console.error(err);
          this.spinnerOver.hide();
          return of(false)
        })
      )
    } else {
      this.spinnerOver.hide();
      return of(false)
    }
  }

  /**
  * Metodo che mi ritorna la lista delle materialità consolidate
  */
  private _getMaterialitaPubblicateObs(idSettore: string): Observable<any> {
    const sottoSettori = (this.formPianoSostenibilita.get('sottoSettori')?.value || []).map(s => s.id).filter(id => id !== undefined) as string[];
    return this.materialitaService.getMaterialitaPubblicate(idSettore, sottoSettori).pipe(
      map((esito: any) => esito.map((item: any) => ({
        idMaterialita: item.id,
        nome: 'Analisi Materialità ' + item.anno,
        anno: item.anno
      }))),
      catchError(() => {
        this.utilityService.openDialog({
          titolo: 'Attenzione',
          descrizione: 'Errore reperimento materialità pubblicate',
          bottoni: [{ nome_btn: 'Ok' }]
        });
        return of(false);
      })
    );
  }

  /**
  * Metodo che mi ritorna la lista delle sintesi pubblicate
  */
  private _getSintesiPubblicateObs(idSettore?: string, idSottoSettore?: string[]): Observable<any> {

    return this.sintesiService.getSintesiPubblicate(idSettore, idSottoSettore).pipe(
      map(esito => esito.map((item: any) => ({
        id: item.id,
        nome: 'Sintesi Sostenibilita ' + item.anno,
        anno: item.anno
      }))),
      catchError((err: any) => {
        this.utilityService.openDialog({
          titolo: 'Attenzione',
          descrizione: 'Errore reperimento sintesi pubblicate',
          bottoni: [{ nome_btn: 'Ok' }]
        });
        return of(false);
      })
    );
  }

  /**
  * Metodo che mi ritorna la lista delle policy pubblicate
  */
  private _getPolicyPubblicateObs(idSettore?: string, idSottoSettore?: string[]): Observable<any> {
    return this.policyService.getPolicyPubblicate(idSettore, idSottoSettore).pipe(
      map(esito => esito.map((item: any) => ({
        id: item.id,
        nome: 'Policy Sostenibilita ' + item.anno,
        anno: item.anno
      }))),
      catchError((err: any) => {
        this.utilityService.openDialog({
          titolo: 'Attenzione',
          descrizione: 'Errore reperimento policy pubblicate',
          bottoni: [{ nome_btn: 'Ok' }]
        });
        return of(false);
      })
    )
  }

  /**
* Metodo che mi ritorna la lista dei bilanci pubblicati
*/
  private _getBilanciPubblicatiObs(idSettore?: string, idSottoSettore?: string[]): Observable<any> {

    return this.bilancioService.getBilanciPubblicati(idSettore, idSottoSettore).pipe(
      map(esito => esito.map((item: any) => ({
        id: item.id,
        nome: 'Bilancio Sostenibilita ' + item.anno,
        anno: item.anno
      }))),
      catchError((err: any) => {
        this.utilityService.openDialog({
          titolo: 'Attenzione',
          descrizione: 'Errore reperimento dei bilanci pubblicati',
          bottoni: [{ nome_btn: 'Ok' }]
        });
        return of(false);
      })
    );
  }

  /**
  * Metodo che mi ritorna la lista delle autovalutazioni compilate
  */
  private _getAutovalutazioniCompilateObs(anno: string, idSettore: string, idSottoSettori?: string[]): Observable<any> {

    this.spinnerOver.show();

    return this.autovalutazioniService.getAutovalutazioniCompilate(anno, idSettore, idSottoSettori).pipe(
      switchMap((result) => {
        this.arrayAnalisiAutovalutazione = result;

        // Controlla il valore già presente nella form. Se non esiste nell'array, si azzera
        const autovalPreSelez = this.formPianoSostenibilita.get('idAutovalutazioneCompilata')?.value;
        let autovalutazioneTrovata: AutovalutazioneCompilata | undefined = undefined;
        if (autovalPreSelez && typeof autovalPreSelez !== 'string') {
          autovalutazioneTrovata = this.arrayAnalisiAutovalutazione.find(aut => aut.id === autovalPreSelez.id);
        }

        if (!autovalutazioneTrovata) {
          // valore preselezionato non trovato, si azzera
          this.formPianoSostenibilita.get('idAutovalutazioneCompilata')?.setValue('');
        }

        this.formPianoSostenibilita.get('idAutovalutazioneCompilata')?.updateValueAndValidity();

        this.spinnerOver.hide();
        return of(true)
      }),
      catchError((err) => {
        console.error(err);
        this.spinnerOver.hide();
        return of(false)
      })
    )
  }

  /**
   * Metodo per aggiungere una materialità se lista materialità consolidate è vuota
   */
  addMaterialita() {
    let idSettore = this.formPianoSostenibilita.get('settori')?.value?.id;

    if (idSettore) {

      const dialogCreaAmbito = this.dialog.open(DialogCreaMaterialitaComponent, {
        data: {},
        panelClass: 'dialog-container',
        disableClose: false,
        width: '100%',
        height: '99%',
        maxHeight: '99%',
        autoFocus: false,
      });

      dialogCreaAmbito.afterClosed().subscribe(() => {

        idSettore && this._getMaterialitaPubblicateObs(idSettore).subscribe();

      });

    } else {
      this.utilityService.opneSnackBar('Selezionare prima un settore', '', {
        duration: 2000,
        panelClass: ['red-snackbar']
      });
    }
  }

  /**
 * Metodo per aggiungere una sintesi se lista sintesi pubblicate è vuota
 */
  addSintesi() {
    if (this.formPianoSostenibilita.get('settori')?.value?.id) {

      let idSettore = this.formPianoSostenibilita.get('settori')?.value?.id;

      const dialogCreaAmbito = this.dialog.open(DialogCreaSintesiSostenibilitaComponent, {
        data: {
          /* sintesi: record */
        },
        panelClass: 'dialog-container',
        disableClose: false,
        width: '100%',
        height: '99%',
        maxHeight: '99%',
        autoFocus: false,
      });

      dialogCreaAmbito.afterClosed().subscribe((result) => {
        this._getSintesiPubblicateObs(idSettore).subscribe();
      });
    } else {
      this.utilityService.opneSnackBar('Selezionare prima un settore', '', {
        duration: 2000,
        panelClass: ['red-snackbar']
      });
    }
  }
  /**
 * Metodo per aggiungere una policy se lista policy pubblicate è vuota
 */
  addPolicy() {
    if (this.formPianoSostenibilita.get('settori')?.value?.id) {

      let idSettore = this.formPianoSostenibilita.get('settori')?.value?.id;

      const dialogCreaPolicy = this.dialog.open(DialogCreaPolicySostenibilitaComponent, {
        data: {
          /* policy: record */
        },
        panelClass: 'dialog-container',
        disableClose: false,
        width: '100%',
        height: '99%',
        maxHeight: '99%',
        autoFocus: false,
      });

      dialogCreaPolicy.afterClosed().subscribe((result) => {
        this._getPolicyPubblicateObs(idSettore).subscribe();
      });
    } else {
      this.utilityService.opneSnackBar('Selezionare prima un settore', '', {
        duration: 2000,
        panelClass: ['red-snackbar']
      });
    }
  }

  /**
  * Metodo per aggiungere una sintesi se lista bilanci pubblicati è vuota
  */
  addBilancio() {
    if (this.formPianoSostenibilita.get('settori')?.value?.id) {

      let idSettore = this.formPianoSostenibilita.get('settori')?.value?.id;

      const dialogCreaBilancio = this.dialog.open(DialogCreaBilancioSostenibilitaComponent, {
        data: {
          /* sintesi: record */
        },
        panelClass: 'dialog-container',
        disableClose: false,
        width: '100%',
        height: '99%',
        maxHeight: '99%',
        autoFocus: false,
      });

      dialogCreaBilancio.afterClosed().subscribe((result) => {
        this._getBilanciPubblicatiObs(idSettore).subscribe();
      });
    } else {
      this.utilityService.opneSnackBar('Selezionare prima un settore', '', {
        duration: 2000,
        panelClass: ['red-snackbar']
      });
    }
  }

  /**
   * Metodo che mi fa la get del questionario della Piano
   * @param id id Piano
   * @returns
   */
  private _getQuestionarioPiano(id: string) {

    return this.pianoService.getQuestionarioPiano(id).pipe(catchError((err) => {
      console.error(err);
      if (err.status === 404) {
        return of(false);
      } else {
        return throwError(() => err);
      }
    })).pipe(map((piano) => {
      if (piano) {
        this._piano = piano;
      }
      return piano;
    }));
  }

  public bottoniListaClickCruscotto(event: BottoniListaEvent) {
    switch (event.id) {
      case 'modifica':
        this.modificaFigura(event.data);
        break;
      case 'compila':
        this.eleCompilazioneFigura(event.data.elementi, event.data.nome, event.data.figura, event.data.id);

        break;
      case 'azione':
        if (event.data) {
          if (event.data.ultimoInvio) {

            this._inviaNotificaSingola(event.data);
          } else {

            this._inviaMailSingola(event.data);
          }
        }
        break;
      default:
        console.warn('ATTENZIONE: bottone ' + event.id + ' non riconosciuto');
    }
  }
  /**
   * Metodo che mi invia una notifica singola 
   * @param cruscotto 
   * @returns 
   */
  private async _inviaNotificaSingola(cruscotto: any) {
    const idPianoSostenibilita = this.formPianoSostenibilita.get('id')?.value;

    if (idPianoSostenibilita) {

      const testiMail = await firstValueFrom(this.pianoService.getTemplateMail(idPianoSostenibilita))

      if (!testiMail?.mailInvio) {
        this.utilityService.openDialog({
          titolo: 'Attenzione',
          descrizione: 'Prima di fare l\'invio compilare i Testi Mail',
          bottoni: [{ nome_btn: 'Ok' }]
        })
        return;
      }


      const dialog = await this.utilityService.openDialog({
        titolo: 'Confermare invio?',
        descrizione: 'Sarà inviata una notifica alla figura selezionata',
        bottoni: [
          {
            nome_btn: 'No',
            id_btn: 'N'
          },
          {
            nome_btn: 'Si',
            id_btn: 'S'
          },
        ]
      });

      const valDialog = await firstValueFrom(dialog.beforeClosed());
      if (valDialog === 'N') {
        return;
      }


      try {
        this.spinnerOver.show();

        await firstValueFrom(this.pianoService.inviaNotifica(idPianoSostenibilita, cruscotto.id));
        await firstValueFrom(this._getQuestionarioPiano(idPianoSostenibilita));

        this.tabellaCruscotto.caricaDati();

        this.utilityService.opneSnackBar('Notifica inviata ', '', {
          duration: 2000,
          panelClass: ['success-snackbar']
        });
        this.spinnerOver.hide();
      } catch (error: any) {

        console.error(error);
        this.utilityService.openDialog({
          titolo: 'Attenzione',
          descrizione: error?.error?.message || 'Errore invio notifica',
          bottoni: [{ nome_btn: 'Ok' }]
        });
        this.spinnerOver.hide();
      }

    }
  }
  async notificaTutti() {

    const idPianoSostenibilita = this.formPianoSostenibilita.get('id')?.value;

    if (idPianoSostenibilita) {

      const testiMail = await firstValueFrom(this.pianoService.getTemplateMail(idPianoSostenibilita))

      if (!testiMail?.mailInvio) {
        this.utilityService.openDialog({
          titolo: 'Attenzione',
          descrizione: 'Prima di fare l\'invio compilare i Testi Mail',
          bottoni: [{ nome_btn: 'Ok' }]
        })
        return;
      }


      const dialog = await this.utilityService.openDialog({
        titolo: 'Confermare invio?',
        descrizione: 'Sarà inviata una notifica a tutte le figure che hanno ricevuto la mail',
        bottoni: [
          {
            nome_btn: 'No',
            id_btn: 'N'
          },
          {
            nome_btn: 'Si',
            id_btn: 'S'
          },
        ]
      });

      const valDialog = await firstValueFrom(dialog.beforeClosed());
      if (valDialog === 'N') {
        return;
      }

      try {
        this.spinnerOver.show();
        await firstValueFrom(this.pianoService.inviaNotificaMultiple(idPianoSostenibilita));
        await firstValueFrom(this._getQuestionarioPiano(idPianoSostenibilita))

        this.tabellaCruscotto.caricaDati();

        this.utilityService.opneSnackBar('Notifica inviata ', '', {
          duration: 2000,
          panelClass: ['success-snackbar']
        });
        this.spinnerOver.hide();
      } catch (error: any) {
        console.error(error);
        this.utilityService.openDialog({
          titolo: 'Attenzione',
          descrizione: error?.error?.message || 'Errore invio notifiche',
          bottoni: [{ nome_btn: 'Ok' }]
        });
        this.spinnerOver.hide();
      }

    }

  }

  private async _inviaMailSingola(cruscotto: any) {

    const idPianoSostenibilita = this.formPianoSostenibilita.get('id')?.value;

    if (idPianoSostenibilita) {

      const testiMail = await firstValueFrom(this.pianoService.getTemplateMail(idPianoSostenibilita))

      if (!testiMail?.mailInvio) {
        this.utilityService.openDialog({
          titolo: 'Attenzione',
          descrizione: 'Prima di fare l\'invio compilare i Testi Mail',
          bottoni: [{ nome_btn: 'Ok' }]
        })
        return;
      }


      const dialog = await this.utilityService.openDialog({
        titolo: 'Confermare invio?',
        descrizione: 'Invia mail ',
        bottoni: [
          {
            nome_btn: 'No',
            id_btn: 'N'
          },
          {
            nome_btn: 'Si',
            id_btn: 'S'
          },
        ]
      });

      const valDialog = await firstValueFrom(dialog.beforeClosed());
      if (valDialog === 'N') {
        return;
      }

      try {
        this.spinnerOver.show();
        await firstValueFrom(this.pianoService.inviaMail(idPianoSostenibilita, cruscotto.id));
        await firstValueFrom(this._getQuestionarioPiano(idPianoSostenibilita));
        this.tabellaCruscotto.caricaDati();

        this.utilityService.opneSnackBar('Mail inviata ', '', {
          duration: 2000,
          panelClass: ['success-snackbar']
        });
        this.spinnerOver.hide();
      } catch (error: any) {
        console.error(error);
        this.utilityService.openDialog({
          titolo: 'Attenzione',
          descrizione: error?.error?.message || 'Errore invio mail',
          bottoni: [{ nome_btn: 'Ok' }]
        });
        this.spinnerOver.hide();
      }

    }
  }

  async inviaTutti() {
    const idPianoSostenibilita = this.formPianoSostenibilita.get('id')?.value;

    if (idPianoSostenibilita) {

      const testiMail = await firstValueFrom(this.pianoService.getTemplateMail(idPianoSostenibilita))

      if (!testiMail?.mailInvio) {
        this.utilityService.openDialog({
          titolo: 'Attenzione',
          descrizione: 'Prima di fare l\'invio compilare i Testi Mail',
          bottoni: [{ nome_btn: 'Ok' }]
        })
        return;
      }

      if (this._piano.stato !== 'PUBBLICATO') {
        const risultatoObservable = await this._pubblicazionePiano('con l\'invio mail');
        if (risultatoObservable) {
          const risultato = await firstValueFrom(risultatoObservable);
          if (!risultato) {
            return;
          }
        }
        this.spinnerOver.hide();
      }

      try {
        this.spinnerOver.show();
        await firstValueFrom(this.pianoService.inviaMailMultiple(idPianoSostenibilita));
        await firstValueFrom(this._getQuestionarioPiano(idPianoSostenibilita));
        this.tabellaCruscotto.caricaDati();
        this.utilityService.opneSnackBar('Mail inviata ', '', {
          duration: 2000,
          panelClass: ['success-snackbar']
        });
        this.spinnerOver.hide();
      } catch (error: any) {
        console.error(error);
        this.utilityService.openDialog({
          titolo: 'Attenzione',
          descrizione: error?.error?.message || 'Errore invio mail',
          bottoni: [{ nome_btn: 'Ok' }]
        });
        this.spinnerOver.hide();
      }
    }
  }

  /**
   * Modifica figura su cruscotto
   * @param dati 
   */
  modificaFigura(dati: any) {

    const dialogAssociaFiguraAziendale = this.dialog.open(DialogAssociaFiguraAziendaleComponent, {
      data: {
        figura: { id: dati.id }
      },
      panelClass: 'dialog-container',
      disableClose: false,
      width: '50%',
      maxHeight: '95%',
      autoFocus: false,
    });

    dialogAssociaFiguraAziendale.afterClosed().subscribe((idFiguraNuova) => {

      const idFiguraPrecedente = dati?.id;

      const idPianoSostenibilita = this.formPianoSostenibilita.get('id')?.value;

      if (idPianoSostenibilita && idFiguraPrecedente && idFiguraNuova) {

        this.pianoService.putCambiaFigura(
          idPianoSostenibilita,
          idFiguraPrecedente,
          idFiguraNuova
        ).subscribe({
          next: () => {
            this.tabellaCruscotto.caricaDati();
          },
          error: (err) => {
            this.tabellaCruscotto.caricaDati();
            console.error(err);
          }
        });
      }

    });
  }

  //! DA RIVEDERE L'IMPLEMENTAZIONE E L'ADATTAMENTO 
  template() {
    const idPianoSostenibilita = this.formPianoSostenibilita.get('id')?.value

    if (idPianoSostenibilita) {

      const dialogCreaTemplateMail = this.dialog.open(DialogCreaTemplateMailComponent, {
        data: {
          idPianoSostenibilita: idPianoSostenibilita,
        },
        panelClass: 'dialog-container',
        disableClose: false,
        width: '100%',
        height: '95%',
        autoFocus: false,
      });

      dialogCreaTemplateMail.afterClosed().subscribe(() => {
      });

    }
  }

  private async _pubblicazionePiano(desc?: string): Promise<Observable<boolean> | undefined> {
    const idPianoSostenibilita = this.formPianoSostenibilita.get('id')?.value;
    let titolo: string = 'Pubblicazione Piano di Sostenibilità';
    let descrizione: string = `
      <div>
        <p>
          <strong>Si prega di notare che questa operazione pubblicherà il Piano di Sostenibilità.</strong>
        </p>
        <ul>
          <li>La pubblicazione è definitiva e <strong>NON</strong> potrà essere annullata.</li>
          <li>Inoltre non sarà più possibile <strong>aggiungere</strong> o <strong>modificare</strong> le informazioni precedentemente configurate.</li>
        </ul>
        <p><strong>Vuoi procedere ${desc ? desc : ' '}?</strong></p>
      </div>
    `;

    const dialog = await this.utilityService.openDialog({
      titolo: titolo,
      descrizione: descrizione,
      fontWeight: '500',
      bottoni: [
        { nome_btn: 'No', id_btn: 'N' },
        { nome_btn: 'Si', id_btn: 'S' }
      ]
    });

    const valDialog = await firstValueFrom(dialog.beforeClosed());
    if (valDialog === 'N') {
      return of(false);
    }

    // PUBBLICO IL BILANCIO E ASPETTO LA RISPOSTA 
    this.spinnerOver.show();
    await firstValueFrom(this.pianoService.pubblicaPiano(idPianoSostenibilita!));
    return of(true);
  }

  /**
   * Get cruscotto filtrato per figura
   * @param idFigura 
   * @param idPianoSostenibilita 
   * @returns 
   */
  getFiguraCruscotto(idFigura: string, idPianoSostenibilita: string) {
    return new Promise((resolve, reject) => {

      let filtroFiguraSelezionata: Filters = {
        values: [idFigura],
        operator: 'eq',
        chiave: 'id'
      };

      this.pianoService.getCruscotti(idPianoSostenibilita ?? '', 0, 1000, '', [filtroFiguraSelezionata])
        .subscribe({
          next: data => resolve(data),
          error: err => reject(new Error(err))
        });
    });
  }

  /**
   *
   * @param elementiCruscotto 
   * @param nome 
   * @param figura 
   */
  public async eleCompilazioneFigura(elementiCruscotto: ElementiCruscotto[], nome: string, figura: string, idFigura: string) {

    const idPianoSostenibilita = this.formPianoSostenibilita.get('id')?.value;

    if (idPianoSostenibilita) {

      if (this._piano.stato !== 'PUBBLICATO') {

        /* --- Modifica richiesta: Se ci sono elementi precompilati lo posso sapere solamente se pubblico 
          il questionario, una volta pubblicato, il BE calcola le precompilazioni, mi rifaccio la get del cruscotto filtrato
          per idFigura selezionata e mi reperisco gli elementi aggiornati e precompilati. --- */

        const risultatoObservable = await this._pubblicazionePiano('con la compilazione');
        if (risultatoObservable) {
          const risultato = await firstValueFrom(risultatoObservable);
          if (!risultato) {
            return;
          }
          try {
            // CHIAMO LA FIGURA FILTRTA E ASPETTO RISPOSTA 
            const figura: any = await this.getFiguraCruscotto(idFigura!, idPianoSostenibilita!);
            elementiCruscotto = figura?.content[0]?.elementi;
          } catch (err) {
            console.error(err);
          }
        }
        this.spinnerOver.hide();
      }


      const dialogCompilazioneFigure = this.dialog.open(DialogCompilazioneFigureComponent, {
        data: {
          elementiCruscotto,
          idPianoSostenibilita,
          nome,
          figura
        },
        panelClass: 'dialog-container',
        disableClose: false,
        width: '100%',
        maxHeight: '95%',
        autoFocus: false,
      });


      dialogCompilazioneFigure.afterClosed().pipe(
        switchMap(() => {
          this.spinnerOver.show();
          return this._getQuestionarioPiano(idPianoSostenibilita)
        })
      ).subscribe(() => {
        this.tabellaCruscotto.caricaDati();
        this.caricaTotaliCruscotto(idPianoSostenibilita);
      })
    }
  }

  public bottoniListaClickTarget(event: BottoniListaEvent) {
    if (event.id === 'aggiungiTarget') {
      this._aggiungiTarget(event.data);
    } else {
      console.warn('ATTENZIONE: bottone ' + event.id + ' non riconosciuto');
    }
  }

  private _aggiungiTarget(dati: any) {
    const listaId = {
      idPianoSostenibilita: this.formPianoSostenibilita.get('id')?.value,
      idAmbito: dati.idAmbito,
      idTematica: dati.idTematica,
      idObiettivo: dati.id,
    }
    console.log(dati)
    const dialogAssociaTargetObiettivi = this.dialog.open(DialogAssociaTargetObiettiviComponent, {
      data: {
        listaId: listaId,
        stato: this.piano?.stato,
        titoloObiettivo: dati?.titolo
      },
      panelClass: 'dialog-container',
      disableClose: false,
      width: '100%',
      height: '95%',
      maxHeight: '99%',
      autoFocus: false,
    });

    dialogAssociaTargetObiettivi.afterClosed().subscribe(() => {

      this.tabellaTarget.caricaDati();

    });
  }

  assegnazioni() {
    const dialogAssegnazioniFigAzz = this.dialog.open(DialogAssegnazioniComponent, {
      data: {
        idContesto: this.formPianoSostenibilita.get('id')?.value,
        stato: this.piano?.stato,
        contesto: 'piano'
      },
      panelClass: 'dialog-container',
      disableClose: false,
      width: '100%',
      maxHeight: '95%',
      autoFocus: false,
    });

    dialogAssegnazioniFigAzz.afterClosed().subscribe(() => {
      if (this.piano?.stato === 'PUBBLICATO') {
        return;
      }
      this.tabellaTarget.caricaDati();
    });
  }

  //******************************* METODI CARICAMENTO PER cambioStep() *************************************/

  private _caricaStepTematiche() {
    const id = this.formPianoSostenibilita.get('id')?.value;

    const idSettore = (this.formPianoSostenibilita.get('settori')?.value as Settore).id
    this.componenteSurvey.datiInizialiCfgQuestionario(idSettore);

    if (!id) {
      return;
    }

    this.spinnerOver.show();

    this._getQuestionarioPiano(id).subscribe(
      {
        next: (piano: any) => {

          if (piano) {

            this._piano = piano;

          }
          this.btnAvanti.disabled = false;
          this.spinnerOver.hide();
        },
        error: (err: any) => {
          console.error(err);
          this.btnAvanti.disabled = false;

          this.utilityService.openDialog({
            titolo: 'Attenzione',
            descrizione: 'Errore nel recupero della piano',
            bottoni: [{ nome_btn: 'Ok' }]
          })
          this.spinnerOver.hide();
        }
      }
    )
  }

  /**
* Get ws Obiettivi piano
* @returns
*/
  private _getObiettiviPiano() {
    const id = this.formPianoSostenibilita.get('id')?.value;

    if (!id) {
      return;
    }
    this.spinnerOver.show();
    this.pianoService.getObiettiviPiano(id).subscribe({
      next: (esito) => {
        this.objQuestionarioObiettivi = esito
        this.btnAvanti.disabled = false;
        this.spinnerOver.hide();
      },
      error: (err) => {
        console.error(err);
        this.utilityService.openDialog({
          titolo: 'Attenzione',
          descrizione: 'Errore nel recupero degli obiettivi',
          bottoni: [{ nome_btn: 'Ok' }]
        })
        this.spinnerOver.hide();
      }
    })
  }

  /*   private provaObj = {
      content: [
        {
          titolo: "obbiettivo titolo", idTargetQualitativi: [], idTargetQuantitativi: []
        }
      ]
    } */

  /* per Alert */
  public ctrAssegnazioniTarget(esito: any): void {
    this.isTargetNonCompletto = false;
    if (esito.content.some((elem: any) => (elem.idTargetQualitativi.length === 0 && elem.idTargetQuantitativi.length === 0))) {
      this.isTargetNonCompletto = true;
    }
  }

  /**
   * Gestico lo stile della singola riga della tabella 
   * @param record riga 
   * @returns style 
   */
  public fnCaricamentoStyleTarget: FnCaricamentoStyle = (record: any) => {

    const colore = record?.idTargetQualitativi.length === 0 && record?.idTargetQuantitativi.length === 0 ? '#D90000' : 'black'

    return {
      color: colore,
      fontWeight: 'bold'
    }
  }

  public fnCaricamentoDatiTarget: FnCaricamentoDati = (
    page: number,
    pageSize: number,
    ricerca?: string,
    filters?: Filters[],
    sortBy?: SortBy[]
  ) => {
    const idPianoSostenibilita = this.formPianoSostenibilita.get('id')?.value;

    if (idPianoSostenibilita) {

      return this.pianoService.getObiettiviTarget(idPianoSostenibilita, page, pageSize, ricerca, filters, sortBy).pipe(
        map((esito) => {
          this.ctrAssegnazioniTarget(esito);
          return esito;
        })
      )
    } else {
      return of(false)
    }
  }

  public fnCaricamentoDatiCruscotto: FnCaricamentoDati = (
    page: number,
    pageSize: number,
    ricerca?: string,
    filters?: Filters[],
    sortBy?: SortBy[]
  ) => {
    const idPianoSostenibilita = this.formPianoSostenibilita.get('id')?.value

    if (idPianoSostenibilita) {
      return this.pianoService.getCruscotti(idPianoSostenibilita, page, pageSize, ricerca, filters, sortBy);
    } else {
      return of(false);
    }

  };


  /**
 * TOTALI CRUSCOTTO 
 * @param idPianoSostenibilita 
 */
  caricaTotaliCruscotto(idPianoSostenibilita: string) {
    this.pianoService.getTotaliCruscotto(idPianoSostenibilita).subscribe({
      next: (totaliCruscotto) => {
        this.tQuantitativiCompilati = totaliCruscotto.nrKpiCompilati || 0;
        this.tQuantitativiNonCompilati = (totaliCruscotto.nrKpi || 0) - this.tQualitativiCompilati;

        this.tQualitativiCompilati = totaliCruscotto.nrAzioniCompilate || 0;
        this.tQualitativiNonCompilati = (totaliCruscotto.nrAzioni || 0) - this.tQuantitativiCompilati;
        this.spinnerOver.hide();
      },
      error: (err) => {
        console.error(err);
        this.spinnerOver.hide();
      },
    })
  }

  caricaStepCruscotto() {
    this.spinnerOver.show();
    this.tabellaCruscotto.caricaDati();

    const idPianoSostenibilita = this.formPianoSostenibilita.get('id')?.value
    if (idPianoSostenibilita) {
      this.caricaTotaliCruscotto(idPianoSostenibilita);
    }
  }

  caricaDocumento() {
    const idPianoSostenibilita = this.formPianoSostenibilita.get('id')?.value
    this.spinnerOver.show();
    if (idPianoSostenibilita) {

      this.pianoService.getDocumento(idPianoSostenibilita).subscribe({
        next: (esito) => {
          console.log(esito)
          this.objDocumento = esito;
          this.spinnerOver.hide();
        },
        error: (err) => {
          console.error(err)
          this.spinnerOver.hide();
        }
      })
    }
  }

  //******************************* METODI SALVATAGGIO PER avanti() *************************************/

  /**
* Metodo salvataggio STEP 1 Piano
* @returns
*/
  private async _salvaStepPiano() {
    const idPiano = this.formPianoSostenibilita.get('id')?.value;

    if (idPiano) {
      return this.stepper.next();
    }

      if (this.formPianoSostenibilita.valid) {
        const dialog = await this.utilityService.openDialog({
          titolo: 'Avviso',
          descrizione: 'Continuando non sarà più possibile modificare queste informazioni.',
          bottoni: [
            {
              nome_btn: 'Annulla',
              id_btn: 'N'
            },
            {
              nome_btn: 'Avanti',
              id_btn: 'S'
            },
          ]
        });

        const valDialog = await firstValueFrom(dialog.beforeClosed());
        if (valDialog === 'N') {
          return;
        }
      }
    

    if (this.formPianoSostenibilita.valid) {
      this.spinnerOver.show();

      let formCrtSostenibilita = this.formPianoSostenibilita.getRawValue();

      const nuovaPiano = {
        ...formCrtSostenibilita,
        idMaterialita: (formCrtSostenibilita.idMaterialita as AnalisiMaterialita)?.idMaterialita || '',
        idSintesiSostenibilita: (formCrtSostenibilita.idSintesiSostenibilita as SintesiSostenibilita)?.id || '',
        idAutovalutazioneCompilata: (formCrtSostenibilita.idAutovalutazioneCompilata as AutovalutazioneCompilata)?.id || '',
        idBilancioSostenibilita: (formCrtSostenibilita.idBilancioSostenibilita as BilancioSostenibilita)?.id || '',
        idPolicySostenibilita: (formCrtSostenibilita.idPolicySostenibilita as PolicySostenibilita)?.id || '',
      };

      return this.pianoService.postCreazionePianoStep1(nuovaPiano).subscribe({
        next: (esito => {
          this.formPianoSostenibilita.get('id')?.setValue(esito.id)

          this.formPianoSostenibilita.get('anno')?.disable();
          this.formPianoSostenibilita.get('settori')?.disable();
          this.formPianoSostenibilita.get('sottoSettori')?.disable();
          this.formPianoSostenibilita.get('idSintesiSostenibilita')?.disable();
          this.formPianoSostenibilita.get('idPolicySostenibilita')?.disable();
          this.formPianoSostenibilita.get('idMaterialita')?.disable();
          this.formPianoSostenibilita.get('idAutovalutazioneCompilata')?.disable();
          this.formPianoSostenibilita.get('idBilancioSostenibilita')?.disable();

          this.stepper.next();
        }),
        error: (err => {
          this.utilityService.openDialog({
            titolo: 'Attenzione',
            descrizione: err.error.message,
            bottoni: [{ nome_btn: 'Ok' }]
          })
          this.spinnerOver.hide();

        })
      })

    } else {
      Object.values(this.formPianoSostenibilita.controls).forEach(
        (control) => {
          control.markAsTouched();
        }
      );
    }
    return null;
  }

  /**
    * Metodo salvataggio STEP  TEMATICHE
    * @returns
    */
  private _salvaStepTematiche() {

    if (this.componenteSurvey.stato === 'PUBBLICATO') {
      this.stepper.next();
    } else {
      this.spinnerOver.show();
      this.componenteSurvey.salva().subscribe((esito) => {
        if (esito) {
          this.stepper.next();
        } else {
          this.spinnerOver.hide();
        }
      });
    }
    return true;
  }

  /**
  * Metodo salvataggio STEP 6 Obiettivi
  * @returns
  */
  private _salvaStepObiettivi() {

    if (this.componenteSurvey.stato === 'PUBBLICATO') {
      this.stepper.next();

    } else {
      this.spinnerOver.show();
      this.componenteSelObiettiviPiano.salva().subscribe((esito) => {
        if (esito) {
          this.stepper.next();
        } else {
          this.spinnerOver.hide();
        }
      });
    }
    return true;
  }

  private _ctrTarget() {
    if (this.isTargetNonCompletto) {
      this.utilityService.opneSnackBar('Selezionare almeno un target Qualitativo o Quantitativo ', '', {
        duration: 2000,
        panelClass: ['red-snackbar']
      });
    } else {
      this.stepper.next();
    }
  }

  /**
   * STAMPA DOCUMENTO
   */
  async stampa() {
    const idPiano = this.formPianoSostenibilita.get('id')?.value;
    try {
      if (idPiano) {
        await firstValueFrom(this.salvaDocumento())

        this.spinnerOver.show()

        console.log(this.objDocumento);

        this.pianoService.postStampaPiano(idPiano).subscribe({
          next: (urlStampa) => {
            window.open(urlStampa?.body, '_blank');

            this.spinnerOver.hide();
          },
          error: (err) => {
            console.error(err);

            this.spinnerOver.hide();

            this.utilityService.openDialog({
              titolo: 'Attenzione',
              descrizione: 'Stampa non riuscita. Verificare le configurazioni e riprovare.',
              bottoni: [{ nome_btn: 'Ok' }]
            });
          }
        })
      }
    } catch (error) {
      console.error(error);
    }

  }

  salvaDocumentoInHTML() {
    this.salvaDocumento().subscribe()
  }

  /**
   * SALVA DOCUMENTO
   * @param idPiano 
   * @returns 
   */
  salvaDocumento(): Observable<any> {

    const idPiano = this.formPianoSostenibilita.get('id')?.value;
    if (idPiano) {
      this.spinnerOver.show();
      return this.pianoService.putDocumento(idPiano, this.objDocumento).pipe(
        map(response => {
          console.log(response);
        }),
        catchError(err => {
          console.error(err);
          return of({ error: true, message: 'Errore nel salvataggio del documento' });
        }),
        tap({
          finalize: () => this.spinnerOver.hide()
        })
      )
    } else {
      return of();
    }
  }

  /**
 * Metodo che mi crea / modifica Obiettivo aziendale 
 * @param obiettivoAziendale 
 */
  async modificaObiettivoAziendale(obiettivoAziendale?: any) {
    try {
      await firstValueFrom(this.salvaDocumento());

      const dialogModificaElemento = this.dialog.open(DialogCreaObiettivoAziendaliComponent, {
        data: {
          obiettivoAziendalePiano: obiettivoAziendale,
          idContest: this.formPianoSostenibilita.get('id')?.value
        },
        panelClass: 'dialog-container',
        disableClose: true,
        width: '70%',
        maxHeight: '95%',
        autoFocus: false,
      });

      dialogModificaElemento.afterClosed().subscribe((esito: any) => {
        if (esito) {
          this.caricaDocumento();
        }
      });
    } catch (error) {
      console.error(error);

    }
  }

  /**
 * Metodo che mi crea / modifica modello di sviluppo
 * @param modelloSviluppo 
 */
  async modificaModelloSviluppo(modelloSviluppo?: any) {

    try {
      await firstValueFrom(this.salvaDocumento());

      const dialogModificaElemento = this.dialog.open(DialogCreaCfgModelliSviluppoPianoComponent, {
        data: {
          modelloSviluppoPiano: modelloSviluppo,
          idContest: this.formPianoSostenibilita.get('id')?.value
        },
        panelClass: 'dialog-container',
        disableClose: true,
        width: '70%',
        maxHeight: '95%',
        autoFocus: false,
      });

      dialogModificaElemento.afterClosed().subscribe((esito: any) => {
        if (esito) {
          this.caricaDocumento();
        }
      });

    } catch (error) {
      console.error(error);

    }
  }

  ctrAnnoPianoUtilizzata(anno: string) {
    return this.isAnnoSettoreValid(anno, this.formPianoSostenibilita.get('settori')?.value?.id);
  }

  ctrSettorePianoUtilizzata(settore: Settore) {
    const anno = this.formPianoSostenibilita.get('anno')?.value;
    return this.isAnnoSettoreValid(anno, settore?.id);
  }

  private isAnnoSettoreValid(anno: string | undefined, settoreId: string | undefined) {
    return anno && settoreId && this.mappaAnnoSettore[anno]?.[settoreId] ? true : false;
  }
}
