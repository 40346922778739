import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { BehaviorSubject, map } from 'rxjs';
import { AziendaService } from 'src/app/services/azienda/azienda.service';
import { CategoriaStakeholders, Categorie, Stakeholder, StakeholderService } from 'src/app/services/stakeholder/stakeholder.service';
import { UtilityService } from 'src/app/services/utility/utility.service';
import { Filters, SortBy } from '../../lista-tabellare/classes/lista-tabellare-data-source';
import { DialogFiltroGenericoComponent } from '../../dialog-filtro-generico/dialog-filtro-generico.component';
import { DialogCreaStakeholderComponent } from 'src/app/page/analisi-materialita/stakeholder/dialog/dialog-crea-stakeholder/dialog-crea-stakeholder.component';
import { MaterialitaService } from 'src/app/services/materialita/materialita.service';

@Component({
  selector: 'app-seleziona-stakeholders',
  templateUrl: './seleziona-stakeholders.component.html',
  styleUrls: ['./seleziona-stakeholders.component.scss']
})
export class SelezionaStakeholdersComponent implements OnInit {

  public arrayGroupCateStake: CategoriaStakeholders[] = [];
  private _filteredArrayGroupCateStake: CategoriaStakeholders[] = [];

  // public totPeso = 0;
  public nrSelezionati: number = 0; // ? usato nell'interfaccia per decidere le chack selezionate

  public searchValue: string = '';

  public btnSelezionaTutto = true;


  private _totPeso = new BehaviorSubject<number>(0);
  public totPeso = this._totPeso.asObservable();

  public _categorie: Categorie[] = [];

  /**
 * Tipologia di stakeholder
 */
  private _tipo: 'INTERNI' | 'ESTERNI' = 'INTERNI';

  @Input()
  get tipo() {
    return this._tipo;
  }

  set tipo(_tipo: 'INTERNI' | 'ESTERNI') {
    this._tipo = _tipo;
  }

  private _idMaterialita: string | undefined;

  @Input()
  get idMaterialita(): string | undefined {
    return this._idMaterialita;
  }

  set idMaterialita(idMaterialita: string | undefined) {
    this._idMaterialita = idMaterialita;
    this._getStakeholders();
  }

  private _dataUltimaConferma: string | undefined;

  @Input()
  get dataUltimaConferma(): string | undefined {
    return this._dataUltimaConferma;
  }

  set dataUltimaConferma(dataUltimaConferma: string | undefined) {
    this._dataUltimaConferma = dataUltimaConferma;
  }

  /**
 * Volori del filtro valorizzati dalla dialog del filtro
 */
  private _valoriFiltro: Filters[] = [];

  @Input()
  get valFiltri(): Filters[] {
    return this._valoriFiltro;
  }

  /**
 * Filtri impostati da iterfaccia
 */
  private _filtri: { [key: string]: any }[] = [
    {
      titolo: 'Nome',
      forControlName: 'nome',
      input: 'text',
    },
    {
      titolo: 'Cognome',
      forControlName: 'cognome',
      input: 'text',
    },
    {
      titolo: 'Email',
      forControlName: 'email',
      input: 'text',
    },
    {
      titolo: 'Categoria',
      forControlName: 'categoria',
      fnDatiOption: () => this._getCategorie(),
      idValueOption: 'id',
      descValueOption: 'titolo',
      input: 'multiple-option'
    },
  ];


  allComplete: { [key: string]: boolean } = {};

  constructor(
    private sanitizer: DomSanitizer,
    public dialog: MatDialog,
    private utilityService: UtilityService,
    private stakeholderService: StakeholderService,
    private aziendaService: AziendaService,
    private materialitaService: MaterialitaService
  ) { }


  ngOnInit(): void {
    this._getCategorie().subscribe({
      next: (result) => {
        this._categorie = result;
      },
      error: (err) => {
        console.error('Errore caricamento categorie : ', err);
        this.utilityService.openDialog({
          titolo: 'Attenzione',
          descrizione: 'Impossibile caricare le categorie',
          bottoni: [{ nome_btn: 'Ok' }]
        })
      }
    })
  }

  public applicaFiltroRicerca(event: Event) {

    const filterValue = (event.target as HTMLInputElement).value;
    this.searchValue = filterValue;

    if (!this.searchValue || this.searchValue === '') {
      this.arrayGroupCateStake = this._filteredArrayGroupCateStake;
      return;
    }

    this.arrayGroupCateStake = this._filteredArrayGroupCateStake.map(item => {

      let stake = item.stakeholders.filter((st) =>
        (st.cognome.toLowerCase().includes(this.searchValue?.toLowerCase() || "") || st.nome.toLowerCase().includes(this.searchValue?.toLowerCase() || ""))
      );

      return Object.assign({}, item, {
        stakeholders: stake
      })

    }).filter((cate) => cate.stakeholders.length);

  }

  public selezionaTutto() {

    this.btnSelezionaTutto = false;

    this.arrayGroupCateStake.forEach((cateStake) => {

      const filtCateStak = this._filteredArrayGroupCateStake.find((filt) => {
        return filt.id === cateStake.id;
      });

      cateStake.stakeholders.forEach((stake) => {
        stake.selected = true;

        let siltStake = filtCateStak?.stakeholders.find((findStake) => {
          return findStake.id === stake.id;
        });

        if (siltStake) {
          siltStake.selected = true;
        }
      })

      this.updateAllComplete(cateStake);
    })
  }

  public deselezionaTutto() {
    this.btnSelezionaTutto = true;

    this.arrayGroupCateStake.forEach((cateStake) => {

      const filtCateStak = this._filteredArrayGroupCateStake.find((filt) => {
        return filt.id === cateStake.id;
      });

      cateStake.stakeholders.forEach((stake) => {
        stake.selected = false;

        let siltStake = filtCateStak?.stakeholders.find((findStake) => {
          return findStake.id === stake.id;
        });

        if (siltStake) {
          siltStake.selected = false;
        }
      })

      this.updateAllComplete(cateStake);
    })
  }

  public apriFiltro() {

    const dialogFiltro = this.dialog.open(DialogFiltroGenericoComponent, {
      data: {
        filtri: this._filtri,
        valoriFiltro: this._valoriFiltro
      },
      panelClass: 'dialog-container',
      disableClose: false,
      width: '90%',
      maxHeight: '90%',
      autoFocus: false
    });

    dialogFiltro.afterClosed().subscribe(result => {
      if (result) {
        this._valoriFiltro = result.filtri;

        if (this._valoriFiltro) {

          const categoria = this._valoriFiltro.find((filt) => filt.chiave === 'categoria');

          this.arrayGroupCateStake = this._filteredArrayGroupCateStake.filter((cta) => {
            if (categoria) {
              return categoria.values.includes(cta.id);
            } else {
              return true;
            }
          }).map(item => {

            item.titolo.toLowerCase().includes(this.searchValue.toLowerCase());

            const stFilt = item.stakeholders.filter(st => {

              return this._valoriFiltro.map((filtro) => {

                return this._checkStakeholder(st, filtro);

              }).reduce((previous, check) => {

                return previous && check;

              }, true);

            });

            return Object.assign({}, item, { stakeholders: stFilt });
          });
        }
      }
    });
  }


  public addStake(catst: CategoriaStakeholders) {

    const cat = this._categorie.find((val) => val.id === catst.id);

    if (!cat) {
      this.utilityService.openDialog({
        titolo: 'Attenzione',
        descrizione: 'Categoria non trovata',
        bottoni: [{ nome_btn: 'Ok' }]
      });
      return;
    }

    const st = {
      categoria: cat,
      tipologia: this._tipo
    }

    const dialogCreaAmbito = this.dialog.open(DialogCreaStakeholderComponent, {
      data: {
        stakeholder: st,
        isStakeHolderInMat: true,
      },
      panelClass: 'dialog-container',
      disableClose: false,
      width: '50%',
      maxHeight: '95%',
      autoFocus: false,
    });
    dialogCreaAmbito.afterClosed().subscribe((result) => {


      //** SALVO GLI STAKEHOLDER SELEZIONATI PER EVITARE DI PERDERE I VALORI DELLA MATERIALITA

      if (result && this.idMaterialita) {
        this.materialitaService.putMateStake(
          this.idMaterialita,
          this.tipo,
          this.getValoriSelezionati()
        ).subscribe({
          next: (stakeholder) => {


            this._getStakeholders();
          },
          error: (err) => {
            console.error(err);
          }
        })
      }
    });
  }

  public modifica(st: any) {
    const dialogCreaAmbito = this.dialog.open(DialogCreaStakeholderComponent, {
      data: {
        stakeholder: st,
        isStakeHolderInMat: true,
      },
      panelClass: 'dialog-container',
      disableClose: false,
      width: '50%',
      maxHeight: '95%',
      autoFocus: false,
    });
    dialogCreaAmbito.afterClosed().subscribe((result) => {


      //** SALVO GLI STAKEHOLDER SELEZIONATI PER EVITARE DI PERDERE I VALORI DELLA MATERIALITA

      if (result && this.idMaterialita) {
        this.materialitaService.putMateStake(
          this.idMaterialita,
          this.tipo,
          this.getValoriSelezionati()
        ).subscribe({
          next: (stakeholder) => {


            this._getStakeholders();
          },
          error: (err) => {
            console.error(err);
          }
        })
      }

    });
  }

  onKeyDown(event: KeyboardEvent): void {
    // Verifica se il tasto premuto è il carattere "-"
    if (event.key === '-') {
      // Impedisce l'inserimento del carattere "-"
      event.preventDefault();
    }
  }

  cambioPeso(event: InputEvent, catst: CategoriaStakeholders) {
    let valore = (event.target as any).value;

    if (valore) {
      if (valore < 0) {
        valore = 0;
      }
      if (valore > 100) {
        valore = 100;
      }

      catst.peso = parseFloat(valore);

      const categoriaNoFilter = this._filteredArrayGroupCateStake.find((cat) => cat.id === catst.id);

      if (categoriaNoFilter) {
        categoriaNoFilter.peso = catst.peso;
      }

    }

    this._totPeso.next(this._filteredArrayGroupCateStake.reduce((prev: number, current: CategoriaStakeholders) => {
      return prev + (current.peso || 0);
    }, 0))

    this.ctrPeso();


  }

  public updateAllComplete(catst: CategoriaStakeholders) {
    setTimeout(() => {
      this.allComplete[catst.id] = catst.stakeholders?.length > 0 && catst.stakeholders.every(t => t.selected);
    }, 0);
  }

  public someComplete(catst: CategoriaStakeholders): boolean {
    if (!catst.stakeholders) {
      return false;
    }
    //** controllare la dichiarazione prima di cambiare
    this.nrSelezionati = catst.stakeholders.filter(t => t.selected).length;
    return !!this.nrSelezionati && this.nrSelezionati != catst.stakeholders.length;
  }

  public setAll(selected: boolean, catst: CategoriaStakeholders) {
    if (catst.stakeholders == null) {
      return;
    }
    catst.stakeholders.forEach(t => (t.selected = selected));
  }

  //** CHIAMATO DALL'ESTERNO
  public getValoriSelezionati() {
    return this.arrayGroupCateStake.map((categoria) => {
      return Object.assign({}, categoria, {
        stakeholders: categoria.stakeholders.filter((st) => st.selected)
      });
    }).filter((cat) => cat.peso || cat.stakeholders?.length);
  }


  public refreshStakeholders(isMaterialita?: boolean) {
    this._getStakeholders(isMaterialita);
  }



  private _checkStakeholder(stakeholder: Stakeholder, filtro: Filters) {
    const valStake = stakeholder[filtro.chiave as keyof Stakeholder];
    switch (filtro.operator) {
      case 'lk':
        const like = filtro.values.find((val) => {
          if (typeof valStake === 'string') {
            return valStake.indexOf(val) >= 0
          } else {
            return valStake === val;
          }
        })
        return !!like;
      case 'eq':
        if (typeof valStake === 'object') {
          return filtro.values.includes((valStake as Categorie).id);
        } else {
          return filtro.values.includes(valStake);
        }
      default:
        return false;
    }
  }

  private _getStakeholders(isMaterialita?: boolean) {
    if (this.idMaterialita) {
      this.stakeholderService.getStakeMate(this.idMaterialita, this.tipo).subscribe((stakeMat) => {

        if (isMaterialita) {
          this._categorie = stakeMat;
        }

        this.arrayGroupCateStake = stakeMat;

        this._filteredArrayGroupCateStake = stakeMat;

        this.arrayGroupCateStake.forEach(cat => this.updateAllComplete(cat));


        if (this.arrayGroupCateStake.filter((val) => val.stakeholders?.length > 0).length === 1) {
          if (!this.arrayGroupCateStake[0].peso) {
            this.arrayGroupCateStake[0].peso = 100;
          }
          this.allComplete[this.arrayGroupCateStake[0].id] = true;
          this.setAll(true, this.arrayGroupCateStake[0]);
        }

        this._totPeso.next(this._getTooPeso());
      })
    }
  }

  private _getTooPeso() {
    return this._filteredArrayGroupCateStake.reduce((prev: number, current: CategoriaStakeholders) => {
      return prev + (current.peso || 0);
    }, 0)
  }

  private _getCategorie() {
    return this.stakeholderService.getCategorieStake(0, 1000, '', [{
      chiave: 'tipologia',
      operator: 'eq',
      values: [this.tipo]
    }]).pipe(map((value) => value?.content || []));
  }

  public ctrPeso(): boolean {
    if (this._totPeso.value > 100) {
      this.utilityService.opneSnackBar('Il peso totale non può essere maggiore di 100', '', {
        duration: 2000,
        panelClass: ['red-snackbar']
      });
      return true;
    }
    return false;
  }
}
