<app-spinner-overlay></app-spinner-overlay>
<div class="cont-comp">
    <div appFormElement [larghezza]="1" [riempi]="true">
        <div class="titolo-sezione-dialog">
            Selezione dei Perimetri e dei Destinatari
        </div>
        <div class="descrizione-sezione-dialog">
            Seleziona i Perimetri e i Destinatari da gestire in Azienda. Una volta selezionati, gestisci le figure
            aziendali associate e spiega il ruolo del comitato rispetto alla sostenibilità in azienda.
        </div>

    </div>
</div>

<div class="cont-titolo">
    <div>
        <mat-label class="titolo">Seleziona e compila gli elementi</mat-label>
    </div>
</div>
<div style="flex: 1;">
    <ng-scrollbar>
        <mat-accordion>
            <mat-expansion-panel [expanded]="step === idx" (opened)="setStep(idx)"
                *ngFor="let elem of arrayPerimetriDestinatari; index as idx" exp>
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        <span class="titolo-panel">{{elem.descrizione}}</span>
                        <span class="nrSel">{{numeroSelezionatiCount(elem)}}/{{elem?.perimetriDestinatari?.length}}</span>
                    </mat-panel-title>
                </mat-expansion-panel-header>

                <div appFormContainer [larghezza]="3" *ngFor="let elemento of elem.perimetriDestinatari">
                    <div appFormElement [larghezza]="0.7" [riempi]="true">
                        <mat-checkbox [(ngModel)]="elemento.selected" (change)="onChangeCheck($event, elemento, idx)"
                            (mousedown)="$event.stopPropagation()" color="primary">

                            <div class="nome-figura">{{elemento.descrizione}}</div>
                        </mat-checkbox>
                    </div>

                    <div appFormElement [larghezza]="1.3" [riempi]="true" style="display: flex; align-items: center;">
                        <div class="nome-figura">
                            {{elemento.lineaGuidaCompilata}}</div>
                    </div>
                    <ng-container *ngIf="elemento.selected">
                        <button class="icone-button" mat-icon-button
                            (click)="compilaPerimetro(elemento, idx); $event.stopPropagation();" [title]="elemento.lineaGuidaCompilata ? 'Modifica' : 'Compila'"
                            [attr.aria-label]="elemento.lineaGuidaCompilata ? 'Modifica' : 'Compila'">
                            <mat-icon class="icone">
                                {{elemento.lineaGuidaCompilata ? 'edit' : 'history_edu'}}
                            </mat-icon>
                        </button>
                    </ng-container>
                </div>
            </mat-expansion-panel>
        </mat-accordion>
    </ng-scrollbar>
</div>