import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { UnitaDiMisura, UnitaDiMisuraService } from 'src/app/services/unita-di-misura/unita-di-misura.service';
import { Ambito } from 'src/app/services/ambiti/ambiti.service';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { ValoriKpi, Stdr } from 'src/app/services/KPI/kpi.service';
import { UtilityService } from 'src/app/services/utility/utility.service';
import { BilancioService } from 'src/app/services/bilancio/bilancio.service';
import { catchError, filter, firstValueFrom, Observable, of, switchMap, throwError } from 'rxjs';
import { ImportazioniService } from 'src/app/services/importazioni/importazioni.service';
import { Filters } from '../lista-tabellare/classes/lista-tabellare-data-source';
import { TemaService } from 'src/app/services/tema/tema.service';

@Component({
  selector: 'app-compila-sintesi-sostenibilita',
  templateUrl: './compila-sintesi-sostenibilita.component.html',
  styleUrls: ['./compila-sintesi-sostenibilita.component.scss']
})
export class CompilaSintesiSostenibilitaComponent implements AfterViewInit {

  @Output() chiudoCompilazione: EventEmitter<any> = new EventEmitter();

  @Output() loading: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Input() salvaCompilazione!: (compilazione: any, isImportazione?: boolean) => Observable<any>;

  public _compilazione: any;
  private _isAnteprima: boolean = false;
  private _valueToggleAnni: boolean = false;
  private _titolo: string = '';
  private _landingPage: SafeHtml = '';
  private _isCompEsterna: boolean = false;
  private _temporaryToken: string | undefined = undefined;
  private _mappaFileDaCaricare: {
    [concatKey: string]: {
      dati: {
        idAzienda: string;
        idContesto: string;
        idAmbito: string;
        idTematica: string;
        idElemento: string;
      },
      file: File | undefined;
      url: string | undefined;
    }
  } = {};

  public mostraCampiTarget: boolean = false;
  public targetRequired: boolean = false;
  public targetConsigliato: boolean = false;
  public descrizioneErrore: string | undefined = undefined;
  public isIconaGuida: boolean = false;

  tipoAzione: any[] = [
    { chiave: 'RIDURRE', descrizione: 'Ridurre' },
    { chiave: 'RAGGIUNGERE', descrizione: 'Raggiungere' },
    { chiave: 'AUMENTARE', descrizione: 'Aumentare' },
  ];

  public selectedTipoAzione = this.tipoAzione[0].chiave;

  @Input()
  set compilazione(compilazione: any) {
    this._compilazione = compilazione;

    this.ambiti.forEach((ambito, idxTema) => {
      this.totTematiche += ambito.tematiche.length;
    });

  }

  get compilazione() {
    return this._compilazione!;
  }

  private _esercizio: number | undefined;

  @Input()
  set esercizio(esercizio: number | undefined) {
    this._esercizio = esercizio;
  }

  get esercizio() {
    return this._esercizio;
  }

  private _idSintesiSostenibilita!: string;

  @Input()
  set idSintesiSostenibilita(idSintesiSostenibilita: string) {
    this._idSintesiSostenibilita = idSintesiSostenibilita;
  }

  get idSintesiSostenibilita() {
    return this._idSintesiSostenibilita;
  }

  @Input()
  set titolo(titolo: string) {
    this._titolo = titolo;
  }

  get titolo() {
    return this._titolo;
  }

  @Input()
  set landingPage(_landingPage: string) {
    this._landingPage = this.sanitizer.bypassSecurityTrustHtml(_landingPage);
  }

  get landingPage(): SafeHtml {
    return this._landingPage;
  }

  @Input()
  /* anteprima */
  set isAnteprima(isAnteprima: boolean) {
    this._isAnteprima = isAnteprima;
  }

  get isAnteprima() {
    return this._isAnteprima;
  }

  @Input()
  set isCompEsterna(isCompEsterna: boolean) {
    this._isCompEsterna = isCompEsterna;
  }

  get isCompEsterna() {
    return this._isCompEsterna;
  }

  @Input()
  set valueToggleAnni(valueToggleAnni: boolean) {
    this._valueToggleAnni = valueToggleAnni;
  }

  get valueToggleAnni() {
    return this._valueToggleAnni;
  }

  @Input()
  set temporaryToken(temporaryToken: string | undefined) {
    this._temporaryToken = temporaryToken;
  }

  public currentSlideIndex: number = 0;
  public totTematiche = 0;
  public titoloBtnAvanti = 'Avanti';
  public indexAmbitoCorrente = 0;
  public indexTematicaCorrente = 0;

  public arrayUm: { [idKpi: string]: UnitaDiMisura[] } = {};

  public arrayAnni: number[] = [];

  public urlPlaceholder = 'assets/images/logo/logo-sostenibile.png';

  constructor(
    private unitaDiMisuraService: UnitaDiMisuraService,
    private sanitizer: DomSanitizer,
    private utilityService: UtilityService,
    private bilancioService: BilancioService,
    private importazioniService: ImportazioniService,
    private temaService: TemaService
  ) {
    this.temaService.temaUpdated.subscribe((tipoInstallazione) => {
      this.urlPlaceholder = temaService.logoPath;
    });
  }

  ngAfterViewInit(): void {
    setTimeout(() => {



      if (this.esercizio) {
        for (let i = 0; i < 5; i++) {
          this.arrayAnni.push(this.esercizio - i);
        }
      }

      for (const ambito of this.ambiti) {

        if (ambito.tematiche.length) {

          for (const tematica of ambito.tematiche) {

            if (tematica.kpis?.length) {
              tematica.kpis.forEach((kpi) => {
                if (kpi.idCategoriaUnitaDiMisura && !this.arrayUm[kpi.idCategoriaUnitaDiMisura]) {
                  this.arrayUm[kpi.idCategoriaUnitaDiMisura] = []; // si mette intanto array vuoto per evitare di chiamare il ws più volte per una singola categoria

                  const filters: Filters[] = [{
                    chiave: 'idCategoriaUnitaDiMisura',
                    operator: 'eq',
                    values: [kpi.idCategoriaUnitaDiMisura]
                  }];

                  let obs;

                  if (this._temporaryToken) {
                    obs = this.unitaDiMisuraService.getUnitaDiMisuraConTempToken(this._temporaryToken, 0, 1000, '', filters);
                  } else {
                    obs = this.unitaDiMisuraService.getUnitaDiMisura(0, 1000, '', filters);
                  }

                  obs.subscribe((um) => {
                    this.arrayUm[kpi.idCategoriaUnitaDiMisura] = um?.content || [];
                  });
                }
              });
            }
          }
        }

      }

      if (this._compilazione.tipo === 'BILANCIO') {
        this.ctrTargetObbligatorio();
      }
      if (this._compilazione.tipo === 'PIANO' && !this._isCompEsterna) {
        this.targetRequired = true;;
      }

    }, 0);
  }

  private _getIdContesto(): string {
    switch (this._compilazione.tipo) {
      case 'SINTESI':
        return this._compilazione.sintesi[0];
      case 'BILANCIO':
        return this._compilazione.bilancio[0];
      case 'POLICY':
        return this._compilazione.policy[0];
      default:
        return '';
    }
  }

  visualizzaCampiTarget(kpis: any) {

    const nuovoTarget: ValoriKpi = {
      anno: undefined,
      tipo: 'valore_target',
      idCategoriaUnitaDiMisura: kpis.idCategoriaUnitaDiMisura,
      idUnitaDiRiferimento: '',
      valoreAttribuito: undefined,
      tipoAzione: ''
    }

    kpis.valoriTarget.push(nuovoTarget)


  }

  onRadioChange(event: any, idxAmb: number, idxTem: number) {
    this.ambiti[idxAmb].tematiche[idxTem].risposte = [event.value];

    //    let sottoRisposte = this.ambiti[idxAmb].tematiche[idxTem].opzioni[idxOpz].sottoRisposte;
    this.ambiti[idxAmb].tematiche[idxTem].opzioni.forEach((opt, idx) => {
      if (opt.chiave !== event.value) {
        this.ambiti[idxAmb].tematiche[idxTem].opzioni[idx].sottoRisposte = [];
      }
    })

  }

  cambioCheck(event: any, idxAmb: number, idxTem: number, idxOpz: number, chiave: string) {

    let sottoRisposte = this.ambiti[idxAmb].tematiche[idxTem].opzioni[idxOpz].sottoRisposte;

    if (event.checked) {

      if (!this.ambiti[idxAmb].tematiche[idxTem].opzioni[idxOpz].sottoRisposte) {
        this.ambiti[idxAmb].tematiche[idxTem].opzioni[idxOpz].sottoRisposte = [];
      }

      this.ambiti[idxAmb].tematiche[idxTem].opzioni[idxOpz].sottoRisposte?.push(chiave);

    } else {

      sottoRisposte = sottoRisposte?.filter((str) => {
        return str !== chiave;
      })
    }

    // opzioni -> sottoRisposte
  }

  checkSotto(sottoRisposte: string[] | undefined, chiave: string) {
    const isRisposta = sottoRisposte?.find((risp) => {
      return risp === chiave;
    })

    if (isRisposta) {
      return true;
    } else {
      return false;
    }
  }

  salva() {
    /* Controllo obbligatorietà del target, valo solo per il bilancio interno  */
    if (this._compilazione.tipo === 'BILANCIO' && this.targetRequired) {

      let errore: boolean = false;

      this.compilazione.ambiti.forEach((ambiti: any) => {
        ambiti.tematiche.forEach((tematiche: any) => {
          tematiche.kpis[0].valoriTarget.forEach((valore: ValoriKpi) => {

            if (!valore.anno || !valore.valoreAttribuito || !valore.idUnitaDiRiferimento) {
              this.utilityService.opneSnackBar('Uno o più target obbligatori non compilati', '', {
                duration: 2000,
                panelClass: ['red-snackbar']
              });
              errore = true;
            }
          });
        });
      });

      if (errore) {
        return
      }
    }

    this.loading.emit(true);

    return this._caricaFiles(Object.values(this._mappaFileDaCaricare), 0).pipe(
      switchMap((esito) => {
        if (!esito) {
          // C'è stato un problema durante il caricamento
          return throwError(() => "Si è verificato un errore durante il caricamento delle immagini");
        }

        // Se presenti file, si caricano
        return this.salvaCompilazione(this.compilazione);
      }),
      catchError((err) => {

        this.utilityService.openDialog({
          titolo: 'Attenzione',
          descrizione: err?.error?.message || "Errore nell\'importazione",
          bottoni: [{ nome_btn: 'Chiudi' }]
        })
        this.loading.emit(false);
        return of();
      })
    ).subscribe(() => {
      this.loading.emit(false);
    });
  }

  get ambiti(): Ambito[] {
    return this.compilazione?.ambiti || [];
  }

  chiudi() {
    if (this.isAnteprima) {
      window.close();
    } else {
      this.chiudoCompilazione.emit();
    }
  }

  /**
   * Se soglie superate && stdr bilnacio = stdr kpi && toggle target = on, allora target obbligatorio
   * Se soglie NON superate && stdr bilnacio = stdr kpi && toggle target = on, allora target consigliato
   * ALTRIMENTI visualizzato sempre, senza controlli. 
   */
  ctrTargetObbligatorio(): void {
    const soglieSuperate = this.bilancioService.controlloCompilazioneKpi.soglieSuperate;
    const stdrBilancio = this.bilancioService.controlloCompilazioneKpi.stdrBilancio;
    let stdrKpi: any;

    this.compilazione.ambiti.forEach((ambiti: any) => {
      ambiti.tematiche.forEach((tematiche: any) => {
        stdrKpi = tematiche?.kpis[0]?.stdr[0] ? tematiche?.kpis[0]?.stdr[0] : undefined;
      });
    });

    if (stdrBilancio.includes(stdrKpi?.chiave) && stdrKpi?.target) {
      this.targetConsigliato = true;
      if (soglieSuperate) {
        this.targetRequired = true;;
        this.targetConsigliato = false;
      }
    }

  }


  /**
   * Importazione immagine 
   */
  public async fileSelezionato(file: File[], idAmbito: string, idTematica: string, idCertificazione: string, fileInput: HTMLInputElement) {

    if (idCertificazione) {


      // Controllo se il file > 1mb
      if (file[0].size > 1048576) {
        const dialog = await this.utilityService.openDialog({
          titolo: 'Dimensione file eccessiva',
          descrizione: 'La dimensione del file supera 1 Mb, la vuoi ridimensionare?',
          bottoni: [
            {
              nome_btn: 'No',
              id_btn: 'N'
            },
            {
              nome_btn: 'Si',
              id_btn: 'S'
            },
          ]
        });

        const valDialog = await firstValueFrom(dialog.beforeClosed());
        if (valDialog === 'N') {
          return;
        }
      }


      if (file[0].type !== "image/jpeg" && file[0].type !== "image/jpg" && file[0].type !== "image/png" && file[0].type !== "image/gif" && file[0].type !== "image/svg+xml") {
        this.utilityService.opneSnackBar(" Attenzione! Il file d'importazione deve essere un file immagine (.png .jpeg .jpg .gif .svg)", '', {
          duration: 5000,
          panelClass: ['red-snackbar']
        });
        this.descrizioneErrore = " Attenzione! Il file d'importazione deve essere un file immagine (.png, .jpeg, .jpg, .gif, .svg)";
        this.isIconaGuida = true;
        return;
      }

      // Memorizza l'immagine in una mappa per caricarla poi al salvataggio
      this._addFileDaCaricare(this._getIdContesto(), idAmbito, idTematica, idCertificazione, file[0]);

      fileInput.value = '';

    }
  }

  /**
   * Metodo per eliminazione immagine certificazione
   * Viene rimosso da google cloud storage 
   */
  public eliminaImg(idAmbito: string, idTematica: string, idCertificazione: string) {


    // Imposta valore vuoto per l'immagine in una mappa, per eliminarla poi al salvataggio della compilazione
    if (idCertificazione) {

      this._addFileDaCaricare(this._getIdContesto(), idAmbito, idTematica, idCertificazione, undefined);

    }

  }

  getTooltip(preCompilazioneInfos?: string): string {

    if (preCompilazioneInfos) {
      const [codice, anno] = preCompilazioneInfos.split("_");

      switch (codice) {
        case 'PA':
          return 'Risposta proposta dalla Policy di Sostenibilità dell\'anno ' + anno + ' precedentemente associata';
        case 'SA':
          return 'Risposta proposta dalla Sintesi di Sostenibilità dell\'anno ' + anno + ' precedentemente associata';
        case 'AA':
          return 'Risposta proposta dall\'Assessment dell\'anno ' + anno + ' precedentemente associato';
        case 'BA':
          return 'Risposta proposta dal Bilancio di Sostenibilità dell\'anno ' + anno + ' precedentemente associato';
        case 'BANN':
          return 'Risposta proposta dal Bilancio di Sostenibilità dell\'anno ' + anno;
        case 'SANN':
          return 'Risposta proposta dalla Sintesi di Sostenibilità dell\'anno ' + anno;
        case 'AANN':
          return 'Risposta proposta dall\'Assessment dell\'anno ' + anno;
        default:
      }
    }
    return '';
  }

  public getUrlImg(
    idAmbito: string,
    idTematica: string,
    idCertificazione: string,
    url: string | undefined
  ) {

    const idContesto = this._getIdContesto();

    const datiFile = this._mappaFileDaCaricare[idContesto + '_' + idAmbito + '_' + idTematica + '_' + idCertificazione];


    if (datiFile) {
      return datiFile.url || this.urlPlaceholder;
    } else if (url) {

      if (this._isCompEsterna && this._temporaryToken && url) {
        if (!url.includes('?')) {
          url += '?';
        } else {
          url += '&';
        }

        url += 'temporaryToken=' + this._temporaryToken;
      }

      return url;
    } else {
      return this.urlPlaceholder;
    }

  }

  /**
   * Ritorna true se presente un file selezionato dall'utente o se presente un file pre-esistente nella certificazione.
   * 
   * @param idAmbito 
   * @param idTematica 
   * @param idCertificazione 
   * @param urlFileCert 
   * @returns 
   */
  public isFilePresente(idAmbito: string, idTematica: string, idCertificazione: string, urlFileCert: string | undefined) {

    const idContesto = this._getIdContesto();

    const datiFile = this._mappaFileDaCaricare[idContesto + '_' + idAmbito + '_' + idTematica + '_' + idCertificazione];

    if (!datiFile) {
      if (urlFileCert) {
        return true;
      } else {
        return false;
      }
    } else if (datiFile.url) {
      return true
    } else {
      return false;
    }

  }

  private _addFileDaCaricare(
    idContesto: string,
    idAmbito: string,
    idTematica: string,
    idCertificazione: string,
    file: File | undefined
  ) {

    const dati = {
      idAzienda: this._compilazione.aziende[0],
      idContesto,
      idAmbito,
      idTematica,
      idElemento: idCertificazione,
    };

    if (file) {

      const reader = new FileReader();

      reader.onload = (e) => {

        this._mappaFileDaCaricare[idContesto + '_' + idAmbito + '_' + idTematica + '_' + idCertificazione] = {
          dati,
          file,
          url: e.target?.result as string
        };

      };

      reader.readAsDataURL(file);

    } else {
      this._mappaFileDaCaricare[idContesto + '_' + idAmbito + '_' + idTematica + '_' + idCertificazione] = {
        dati,
        file: undefined,
        url: undefined
      };
    }
  }

  private _caricaFiles(datiFile: {
    dati: {
      idAzienda: string;
      idContesto: string;
      idAmbito: string;
      idTematica: string;
      idElemento: string;
    },
    file: File | undefined;
  }[], cont: number): Observable<boolean> {

    let obsImmagine: Observable<{
      inProgress: boolean;
      progress: number;
      type: number;
      body?: any;
    } | undefined> | undefined = undefined;

    if (cont >= datiFile.length) {
      // Fine ciclo
      return of(true);
    }

    const objDati = datiFile[cont];

    const data: { temporaryToken?: string } = {};

    if (objDati.file) {
      // File da caricare
      if (this._temporaryToken && this._isCompEsterna) {

        data.temporaryToken = this._temporaryToken;

        switch (this._compilazione.tipo) {
          case 'SINTESI':
            obsImmagine = this.importazioniService.importImgCertificazioneSintesiDaEsterna(objDati.file, data, objDati.dati).pipe(filter((res) => res?.type === 4))
            break;
          case 'BILANCIO':
            obsImmagine = this.importazioniService.importImgCertificazioneBilancioDaEsterna(objDati.file, data, objDati.dati).pipe(filter((res) => res?.type === 4))
            break;
          case 'POLICY':
            obsImmagine = this.importazioniService.importImgCertificazionePolicyDaEsterna(objDati.file, data, objDati.dati).pipe(filter((res) => res?.type === 4))
            break;
          default:
            break;
        }

      } else {

        switch (this._compilazione.tipo) {
          case 'SINTESI':
            obsImmagine = this.importazioniService.importImgCertificazioneSintesi(objDati.file, data, objDati.dati).pipe(filter((res) => res?.type === 4))
            break;
          case 'BILANCIO':
            obsImmagine = this.importazioniService.importImgCertificazioneBilancio(objDati.file, data, objDati.dati).pipe(filter((res) => res?.type === 4))
            break;
          case 'POLICY':
            obsImmagine = this.importazioniService.importImgCertificazionePolicy(objDati.file, data, objDati.dati).pipe(filter((res) => res?.type === 4))
            break;
          default:
            break;
        }

      }
    } else {
      // File da eliminare
      if (this._temporaryToken && this._isCompEsterna) {

        switch (this._compilazione.tipo) {
          case 'BILANCIO':
            obsImmagine = this.importazioniService.deleteImgCertificazioneBilancioDaEsterna(objDati.dati, this._temporaryToken)
            break;
          case 'SINTESI':
            obsImmagine = this.importazioniService.deleteImgCertificazioneSintesiDaEsterna(objDati.dati, this._temporaryToken)
            break;
          case 'POLICY':
            obsImmagine = this.importazioniService.deleteImgCertificazionePolicyDaEsterna(objDati.dati, this._temporaryToken)
            break;

          default:
            break;
        }

      } else {

        switch (this._compilazione.tipo) {
          case 'BILANCIO':
            obsImmagine = this.importazioniService.deleteImgCertificazioneBilancio(objDati.dati)
            break;
          case 'SINTESI':
            obsImmagine = this.importazioniService.deleteImgCertificazioneSintesi(objDati.dati)
            break;
          case 'POLICY':
            obsImmagine = this.importazioniService.deleteImgCertificazionePolicy(objDati.dati)
            break;

          default:
            break;
        }

      }
    }


    if (!obsImmagine) {
      console.error('NESSUN OBS PER SALVATAGGIO FILE: ', datiFile)
      return of(false);
    }

    return obsImmagine.pipe(
      switchMap((result) => {

        return this._caricaFiles(datiFile, cont + 1);

      })
    );
  }

  checkIfNumber(event: KeyboardEvent): boolean {
    const key = event.key;

    // Controlla se il carattere non è un numero (i caratteri numerici vanno da '0' a '9')
    if (!/^[0-9]$/.test(key)) {
      event.preventDefault(); // Impedisce l'inserimento di caratteri non numerici
      return false;
    }

    return true;
  }

}
