<div mat-dialog-title>Policy di Sostenibilità</div>

<div mat-dialog-content class="dialog-center-cont" style="padding: 0px !important;">
    <app-spinner-overlay></app-spinner-overlay>
    <mat-stepper [linear]="!isPubblicato()" #stepper (selectionChange)="cambioStep($event)">

        <mat-step [stepControl]="formPolicySostenibilita">

            <ng-scrollbar [visibility]="'hover'">
                <ng-template matStepLabel>
                    Policy di sostenibilità
                </ng-template>

                <form [formGroup]="formPolicySostenibilita" appFormContainer [larghezza]="12" [gap]="10" [rowGap]="5"
                    style="align-items: center;">

                    <div appFormElement [larghezza]="12" [riempi]="true">

                        <div class="titolo-sezione-dialog">Creazione Policy di Sostenibilità</div>

                        <div class="descrizione-sezione-dialog">
                            Seleziona l'anno di riferimento per la tua Policy di Sostenibilità
                        </div>
                    </div>

                    <mat-form-field appFormElement [larghezza]="4">
                        <mat-label>Anno</mat-label>
                        <mat-select (selectionChange)="onSelectionChangeAnno()" formControlName="anno"
                            placeholder="Anno">
                            <mat-option *ngFor="let anno of arrayAnni" [value]="anno"
                                [disabled]="ctrAnnoPolicyUtilizzata(anno)">{{anno}}</mat-option>
                        </mat-select>
                    </mat-form-field>

                    <div appFormElement [larghezza]="4" style="display:flex; flex-direction: column">
                        <mat-radio-group class="radio-tipo-sett" style="justify-content: space-around;"
                            aria-label="Tipo settore" formControlName="tipoSettore">
                            <mat-radio-button aria-label="Settore Generico" value="GENERALE"
                                [disabled]="this.formPolicySostenibilita.get('id')?.value || !this.settoreGenerale">Settore
                                Generico</mat-radio-button>
                            <mat-radio-button aria-label="Settore Specifico" value="SPECIFICO"
                                [disabled]="this.formPolicySostenibilita.get('id')?.value || !(this.settoriAzienda.length)">Settore
                                Specifico</mat-radio-button>
                        </mat-radio-group>
                    </div>

                    <mat-form-field appFormElement [larghezza]="4" class="input-form"
                        *ngIf="this.formPolicySostenibilita.get('tipoSettore')?.value === 'SPECIFICO'">
                        <mat-label>Settore</mat-label>
                        <mat-select formControlName="settori" placeholder="Settore" [compareWith]="compareById">
                            <mat-option *ngFor="let set of settoriAzienda" [value]="set"
                                [disabled]="ctrSettorePolicyUtilizzata(set)">{{set.titolo}}</mat-option>
                        </mat-select>
                    </mat-form-field>

                    <mat-form-field appFormElement [larghezza]="12" class="input-form"
                        *ngIf="this.formPolicySostenibilita.get('tipoSettore')?.value === 'SPECIFICO'">
                        <mat-label>Sotto-settori</mat-label>
                        <mat-select formControlName="sottoSettori" placeholder="Sotto-settori"
                            [compareWith]="compareById" multiple>
                            <mat-option *ngFor="let sottoSet of arraySottoSettori"
                                [value]="sottoSet">{{sottoSet.titolo}}</mat-option>
                        </mat-select>
                    </mat-form-field>

                    <div class="separatore" appFormElement [larghezza]="12">
                        <app-separatore-label [styleSeparatore]="'small'"></app-separatore-label>
                    </div>

                    <div appFormElement [larghezza]="12" [riempi]="true">
                        <div class="titolo-sezione-dialog">Non partire da zero, lega la tua
                            Policy ad
                            altre
                            attività</div>

                        <div class="descrizione-sezione-dialog">
                            Lega il bilancio ad una analisi di materialità / bilancio di sostenibilità / sintesi di
                            sostenibilità / Autovalutazione precedentemente creata.
                        </div>
                    </div>

                    <div appFormElement [larghezza]="4">

                        <mat-spinner [diameter]="30" *ngIf="loadingAnalisiMaterialita"></mat-spinner>

                        <mat-form-field
                            *ngIf="!loadingAnalisiMaterialita && (formPolicySostenibilita.get('id')?.value || (formPolicySostenibilita.get('settori')?.value && arrayAnalisiMaterialita.length))"
                            style="width: 100%;">
                            <mat-label>{{mostraDescrizioneMaterialita()}}</mat-label>
                            <mat-select formControlName="idMaterialita" placeholder="Analisi Materialità"
                                (selectionChange)="onSelectionChangeMaterialita($event)">
                                <mat-option *ngFor="let mate of arrayAnalisiMaterialita"
                                    [compareWith]="compareAnalisiMaterialita" [value]="mate">{{mate.nome}}</mat-option>
                            </mat-select>
                            <button [disabled]="hasValue('idMaterialita')" matSuffix mat-icon-button type="button"
                                aria-label="Cancella" tabindex="-1" matTooltip="Cancella"
                                (click)="clearInput('idMaterialita', $event)">
                                <mat-icon>clear</mat-icon>
                            </button>
                        </mat-form-field>

                        <button mat-stroked-button
                            *ngIf="!formPolicySostenibilita.get('id')?.value && !loadingAnalisiMaterialita && formPolicySostenibilita.get('settori')?.value && !arrayAnalisiMaterialita.length"
                            (click)="addMaterialita()"
                            style="height: 50px;width: 100%;font-weight: bold;background-color: #f5f5f5;">
                            + Aggiungi Analisi di Materialità
                        </button>


                        <mat-label
                            *ngIf="!formPolicySostenibilita.get('id')?.value && !loadingAnalisiMaterialita && !formPolicySostenibilita.get('settori')?.value">
                            Seleziona anno e settore per aggiungere altre attività
                        </mat-label>
                    </div>

                    <div appFormElement [larghezza]="4">
                        <mat-form-field
                            *ngIf="formPolicySostenibilita.get('id')?.value || (formPolicySostenibilita.get('settori')?.value && arraySintesiSostenibilita?.length)"
                            style="width: 100%;">
                            <mat-label>{{mostraDescrizioneSintesi()}}</mat-label>
                            <mat-select formControlName="idSintesiSostenibilita" placeholder="Sintesi di Sostenibilità"
                                (selectionChange)="onSelectionChangeSintesi($event)">
                                <mat-option *ngFor="let sintesi of arraySintesiSostenibilita"
                                    [compareWith]="compareSintesiSostenibilita"
                                    [value]="sintesi">{{sintesi.nome}}</mat-option>
                            </mat-select>
                            <button [disabled]="hasValue('idSintesiSostenibilita')" matSuffix mat-icon-button
                                type="button" aria-label="Cancella" tabindex="-1" matTooltip="Cancella"
                                (click)="clearInput('idSintesiSostenibilita', $event)">
                                <mat-icon>clear</mat-icon>
                            </button>
                        </mat-form-field>



                        <button [disabled]="this.formPolicySostenibilita.get('id')?.value" mat-stroked-button
                            *ngIf="!formPolicySostenibilita.get('id')?.value && !arraySintesiSostenibilita?.length"
                            (click)="addSintesi()"
                            style="height: 50px;width: 100%;font-weight: bold;background-color: #f5f5f5;">
                            + Aggiungi Sintesi di Sostenibilità
                        </button>
                    </div>

                    <div appFormElement [larghezza]="4">
                        <mat-form-field
                            *ngIf="formPolicySostenibilita.get('id')?.value || (formPolicySostenibilita.get('settori')?.value && arrayBilancioSostenibilita?.length)"
                            style="width: 100%;">
                            <mat-label>{{mostraDescrizioneBilancio()}}</mat-label>
                            <mat-select formControlName="idBilancioSostenibilita"
                                placeholder="Bilancio di Sostenibilità"
                                (selectionChange)="onSelectionChangeBilancio($event)">
                                <mat-option *ngFor="let bilancio of arrayBilancioSostenibilita"
                                    [compareWith]="compareBilancioSostenibilita"
                                    [value]="bilancio">{{bilancio.nome}}</mat-option>
                            </mat-select>
                            <button [disabled]="hasValue('idBilancioSostenibilita')" matSuffix mat-icon-button
                                type="button" aria-label="Cancella" tabindex="-1" matTooltip="Cancella"
                                (click)="clearInput('idBilancioSostenibilita', $event)">
                                <mat-icon>clear</mat-icon>
                            </button>
                        </mat-form-field>

                        <button [disabled]="this.formPolicySostenibilita.get('id')?.value" mat-stroked-button
                            *ngIf="!formPolicySostenibilita.get('id')?.value && !arrayBilancioSostenibilita?.length"
                            (click)="addBilancio()"
                            style="height: 50px;width: 100%;font-weight: bold;background-color: #f5f5f5;">
                            + Aggiungi Bilancio di Sostenibilità
                        </button>
                    </div>

                    <div appFormElement [larghezza]="4">
                        <mat-form-field
                            *ngIf="formPolicySostenibilita.get('id')?.value || (formPolicySostenibilita.get('settori')?.value && arrayPianoSostenibilita?.length)"
                            style="width: 100%;">
                            <mat-label>{{mostraDescrizionePiano()}}</mat-label>
                            <mat-select formControlName="idPianoSostenibilita" placeholder="Piano di Sostenibilità"
                                (selectionChange)="onSelectionChangePiano($event)">
                                <mat-option *ngFor="let piano of arrayPianoSostenibilita"
                                    [compareWith]="comparePianoSostenibilita"
                                    [value]="piano">{{piano.nome}}</mat-option>
                            </mat-select>
                            <button [disabled]="hasValue('idPianoSostenibilita')" matSuffix mat-icon-button
                                type="button" aria-label="Cancella" tabindex="-1" matTooltip="Cancella"
                                (click)="clearInput('idPianoSostenibilita', $event)">
                                <mat-icon>clear</mat-icon>
                            </button>
                        </mat-form-field>

                        <button [disabled]="this.formPolicySostenibilita.get('id')?.value" mat-stroked-button
                            *ngIf="!formPolicySostenibilita.get('id')?.value && !arrayPianoSostenibilita?.length"
                            (click)="addPiano()"
                            style="height: 50px;width: 100%;font-weight: bold;background-color: #f5f5f5;">
                            + Aggiungi Piano di Sostenibilità
                        </button>
                    </div>

                    <div appFormElement [larghezza]="4">
                        <mat-spinner [diameter]="30" *ngIf="loadingAutovalutazione"></mat-spinner>
                        <mat-form-field
                            *ngIf="!loadingAutovalutazione && (formPolicySostenibilita.get('id')?.value || (formPolicySostenibilita.get('settori')?.value && formPolicySostenibilita.get('anno')?.value && arrayAnalisiAutovalutazione.length))"
                            style="width: 100%;">
                            <mat-label>{{mostraDescrizioneAssessment()}}</mat-label>
                            <mat-select formControlName="idAutovalutazioneCompilata"
                                [placeholder]="arrayAnalisiAutovalutazione.length > 0 ? 'Assessment' : 'Nessun assessment selezionato'"
                                (selectionChange)="onSelectionChangeAutovalutazione($event)">
                                <mat-option *ngFor="let auto of arrayAnalisiAutovalutazione"
                                    [compareWith]="compareAnalisiAutovalutazione"
                                    [value]="auto">{{auto.titolo}}</mat-option>
                            </mat-select>
                            <button [disabled]="hasValue('idAutovalutazioneCompilata')" matSuffix mat-icon-button
                                type="button" aria-label="Cancella" tabindex="-1" matTooltip="Cancella"
                                (click)="clearInput('idAutovalutazioneCompilata', $event)">
                                <mat-icon>clear</mat-icon>
                            </button>
                        </mat-form-field>
                    </div>

                    <div appFormElement [larghezza]="12" class="segnalazione" [ngClass]="{'show': mostraDescrizione}">
                        <div style="display: flex;">
                            <mat-icon>error</mat-icon>
                        </div>
                        <div class="descrizione">
                            {{guidaDescrizione}}
                        </div>
                    </div>

                </form>

            </ng-scrollbar>
        </mat-step>

        <!-- SELEZIONE TEMATICHE   -->
        <mat-step>
            <ng-template matStepLabel>
                Tematiche
            </ng-template>

            <app-cfg-questionario #surveyComp [tipoQuestionario]="'POLICY'" [cfgQuestionario]="policy"
                [idSostenibilita]="formPolicySostenibilita.get('id')?.value"
                [nascondiTitolo]="true"></app-cfg-questionario>

        </mat-step>

        <!--  ---------------------- I M P E G N I ---------- -->
        <mat-step>
            <ng-template matStepLabel>
                Impegni
            </ng-template>
            <div appFormContainer [larghezza]="2" style="align-items: center;">

                <div appFormElement [larghezza]="1" [riempi]="true">
                    <div class="titolo-sezione-dialog">
                        Selezione degli Impegni
                    </div>
                    <div class="descrizione-sezione-dialog">
                        Seleziona gli impegni da seguire per ridurre il proprio impatto ambientale e per promuovere la
                        sostenibilità.
                    </div>

                </div>
            </div>

            <app-selezione-impegni-policy [objQuestionario]="objQuestionarioImpegni" chiaveElemento="elementi"
                [idContest]="this.formPolicySostenibilita.get('id')?.value" [stato]="policy?.stato"
                chiaveElementoSelezione="impegno">

            </app-selezione-impegni-policy>

        </mat-step>

        <!--  ---------------------- Azioni e Buone Pratiche  ---------- -->
        <mat-step>
            <ng-template matStepLabel>
                Azioni e Buone Pratiche
            </ng-template>
            <div appFormContainer [larghezza]="2" style="align-items: center;">

                <div appFormElement [larghezza]="1" [riempi]="true">
                    <div class="titolo-sezione-dialog">
                        Selezione delle Azioni e Buone Pratiche
                    </div>
                    <div class="descrizione-sezione-dialog">
                        Seleziona le iniziative che l'azienda può intraprendere per ridurre il proprio impatto
                        ambientale.
                    </div>

                </div>
            </div>

            <app-selezione-azioni-buonepratiche-policy [objQuestionario]="objQuestionarioAzioniBP"
                chiaveElemento="elementi" [idContest]="this.formPolicySostenibilita.get('id')?.value"
                [stato]="policy?.stato" chiaveElementoSelezione="azionebp">

            </app-selezione-azioni-buonepratiche-policy>

        </mat-step>

        <!--  ---------------------- Certificazioni  ---------- -->
        <mat-step>
            <ng-template matStepLabel>
                Certificazioni
            </ng-template>
            <div appFormContainer [larghezza]="2" style="align-items: center;">

                <div appFormElement [larghezza]="1" [riempi]="true">
                    <div class="titolo-sezione-dialog">
                        Selezione delle Certificazioni
                    </div>
                    <div class="descrizione-sezione-dialog">
                        Scegli le certificazioni pertinenti tra quelle elencate per valorizzare la tua azienda.
                    </div>

                </div>
            </div>

            <app-selezione-certificazioni-policy [objQuestionario]="objQuestionarioCertificazioni"
                chiaveElemento="elementi" [idContest]="this.formPolicySostenibilita.get('id')?.value"
                [stato]="policy?.stato" chiaveElementoSelezione="certif">
            </app-selezione-certificazioni-policy>

        </mat-step>

        <!--  ---------------------- C O M I T A T I  ---------- -->
        <mat-step>
            <ng-template matStepLabel>
                Comitati
            </ng-template>

            <div style="height: 10px;"></div>
            <app-seleziona-comitati [idPolicy]="this.formPolicySostenibilita.get('id')?.value"
                [isPubblicato]="isPubblicato()"></app-seleziona-comitati>

        </mat-step>
        <!--  ---------------------- C R U S C O T T O ---------- -->
        <mat-step>
            <ng-template matStepLabel>
                Cruscotto
            </ng-template>
            <div class="barra-titolo-cruscotto">

                <div style="display: flex;flex-direction: column;flex: 1;">
                    <div class="titolo-sezione-dialog">Cruscotto Policy di Sostenibilità</div>

                    <div class="descrizione-sezione-dialog">Gestisci, Invia e Ricorda. Tutto per la tua
                        organizzazione.
                    </div>
                </div>

                <div class="contatori">
                    <div style="text-align: center;">
                        <div class="titolo-cont">AZIONE E BUONE PRATICHE</div>
                        <div class="contatore">
                            <div class="dato">
                                <div class="valore" style="font-size: 24px;color: #729373;">{{azioneCompilati}}
                                </div>
                                <div class="label">compilate</div>
                            </div>
                            <div class="verticale"></div>
                            <div class="dato">
                                <div class="valore" style="font-size: 24px;color: #CF5B61;">{{azioneNonCompilati}}
                                </div>
                                <div class="label">non compilate</div>
                            </div>
                        </div>
                    </div>

                    <div style="text-align: center;">
                        <div class="titolo-cont">IMPEGNI</div>
                        <div class="contatore">
                            <div class="dato">
                                <div class="valore" style="font-size: 24px;color: #729373;">{{impegniCompilati}}
                                </div>
                                <div class="label">compilati</div>
                            </div>
                            <div class="verticale"></div>
                            <div class="dato">
                                <div class="valore" style="font-size: 24px;color: #CF5B61;">{{impegniNonCompilati}}
                                </div>
                                <div class="label">non compilati</div>
                            </div>
                        </div>
                    </div>

                    <div style="text-align: center;">
                        <div class="titolo-cont">CERTIFICAZIONI</div>
                        <div class="contatore">
                            <div class="dato">
                                <div class="valore" style="font-size: 24px;color: #729373;">{{certificazioniCompilate}}
                                </div>
                                <div class="label">compilate</div>
                            </div>
                            <div class="verticale"></div>
                            <div class="dato">
                                <div class="valore" style="font-size: 24px;color: #CF5B61;">
                                    {{certificazioniNonCompilate}}
                                </div>
                                <div class="label">non compilate</div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <ng-scrollbar>
                <div style="padding-right: 15px;">
                    <app-lista-tabellare #tabella [defaultPageSize]="4" [fnCaricamentoDati]="fnCaricamentoDatiCruscotto"
                        [colonne]="colonne" [maxNrButton]="1" [filtri]="filtri"
                        (bottoniListaClick)="bottoniListaClick($event)"
                        *ngIf="this.formPolicySostenibilita.get('id')?.value">
                        <ng-container btnEnd>

                            <button mat-flat-button class="primary-button" color="primary" (click)="template()">
                                <mat-icon matSuffix>settings</mat-icon>
                                Testi Mail
                            </button>

                            <button mat-flat-button class="primary-button" color="primary" (click)="inviaTutti()">
                                <mat-icon matSuffix>mail</mat-icon>
                                Invia a Tutti
                            </button>

                            <button mat-flat-button class="primary-button" color="primary" (click)="notificaTutti()">
                                <mat-icon matSuffix>notifications_active</mat-icon>
                                Notifica Tutti
                            </button>

                        </ng-container>
                    </app-lista-tabellare>
                </div>
            </ng-scrollbar>

        </mat-step>
        <!--  ---------------------- Perimetro e destinatari  ---------- -->
        <mat-step>
            <ng-template matStepLabel>
                Documento
            </ng-template>

            <div style="height: 10px;"></div>
            <app-seleziona-perimetro-destinatari
                [idPolicy]="this.formPolicySostenibilita.get('id')?.value"></app-seleziona-perimetro-destinatari>

        </mat-step>


        <!--  ---------------------- S T A M P A ---------- -->
        <mat-step>
            <ng-template matStepLabel>
                Configurazione Stampa
            </ng-template>

            <app-cfg-stampa [fnGetConfigStampa]="fnGetConfigStampa" [fnSalvaCfgStampa]="fnSalvaCfgStampa"
                [fnCaricaImg]="fnImportaImmagine" [fnEliminaImg]="fnEliminaImg"
                (loading)="toggleLoading($any($event))"></app-cfg-stampa>

        </mat-step>

    </mat-stepper>

</div>

<div mat-dialog-actions class="dialog-footer-cont">
    <div>
        <button mat-flat-button color="accent" [disabled]="stepper.selectedIndex === 0" (click)="indietro()"
            class="accent-button">Indietro</button>
    </div>

    <div *ngIf="stepper.selectedIndex < 8">
        <button mat-flat-button color="primary" (click)="avantiHTML()" class="primary-button" #btnAvanti>
            Avanti
        </button>
    </div>

    <ng-container *ngIf="stepper.selectedIndex === 5 ">
        <button mat-flat-button color="accent" (click)="creaComitato()"
            class="accent-button">
            Nuovo comitato
        </button>
    </ng-container>

    <div *ngIf="mostraBottoneAssegnazioni">
        <button mat-flat-button color="primary" (click)="assegnazioni(stepper.selectedIndex)" class="primary-button">
            <mat-icon>person_edit</mat-icon> Assegnazioni
        </button>
    </div>

    <ng-container *ngIf="stepper.selectedIndex === 8">
        <button mat-flat-button color="primary" (click)="salvaCfgStampa()" class="primary-button">
            Salva
        </button>
        <button mat-flat-button color="accent" (click)="stampa()" class="accent-button" style="margin: 0 !important;">
            <mat-icon class="material-icons-outlined">
                print
            </mat-icon>Stampa
        </button>
    </ng-container>
</div>