import { Component, Input, AfterViewInit, ViewChild, QueryList, ViewChildren } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { catchError, firstValueFrom, map, Observable, of, switchMap } from 'rxjs';
import { DialogFiltroGenericoComponent } from 'src/app/components/dialog-filtro-generico/dialog-filtro-generico.component';
import { Filters } from 'src/app/components/lista-tabellare/classes/lista-tabellare-data-source';
import { DialogCreaFigureAziendeComponent } from 'src/app/page/aziende/figure-aziende/dialog/dialog-crea-figure-aziende/dialog-crea-figure-aziende.component';
import { Comitati, ComitatiConFigAz, FiguraInComiato, ComitatiService } from 'src/app/services/comitati/comitati.service';
import { UtilityService } from 'src/app/services/utility/utility.service';
import { CompilaComitatiComponent } from './compila-comitati/compila-comitati.component';
import { SpinnerOverlayComponent } from 'src/app/components/spinner-overlay/spinner-overlay.component';
import { PolicyService } from 'src/app/services/policy/policy.service';
import { MatAccordion, MatExpansionPanel } from '@angular/material/expansion';

@Component({
  selector: 'app-seleziona-comitati',
  templateUrl: './seleziona-comitati.component.html',
  styleUrls: ['./seleziona-comitati.component.scss']
})
export class SelezionaComitatiComponent implements AfterViewInit {

  @ViewChild(SpinnerOverlayComponent) spinnerOver!: SpinnerOverlayComponent;

  public arrayGroupComitati: ComitatiConFigAz[] = [];
  private _filteredArrayGroupComitati: ComitatiConFigAz[] = [];

  public nrSelezionati: number = 0;
  public _comitati: Comitati[] = [];
  public searchValue: string = '';

  public btnSelezionaTutto = true;


  private _idPolicy: string | undefined;
  @Input()
  get idPolicy(): string | undefined {
    return this._idPolicy;
  }

  set idPolicy(idPolicy: string | undefined) {
    this._idPolicy = idPolicy;
  }

  private _isPubblicato: boolean | undefined;
  @Input()
  get isPubblicato(): boolean | undefined {
    return this._isPubblicato;
  }

  set isPubblicato(isPubblicato: boolean | undefined) {
    this._isPubblicato = isPubblicato;
  }

  /**
   * Volori del filtro valorizzati dalla dialog del filtro
   */
  private _valoriFiltro: Filters[] = [];

  @Input()
  get valFiltri(): Filters[] {
    return this._valoriFiltro;
  }

  /**
 * Filtri impostati da iterfaccia
 */
  private _filtri: { [key: string]: any }[] = [
    {
      titolo: 'Nome',
      forControlName: 'nome',
      input: 'text',
    },
    {
      titolo: 'Figura',
      forControlName: 'figura',
      input: 'text',
    },
    {
      titolo: 'Email',
      forControlName: 'email',
      input: 'text',
    },
    {
      titolo: 'Comitati',
      forControlName: 'comitati',
      fnDatiOption: () => this._getComitati(),
      idValueOption: 'id',
      descValueOption: 'descrizione',
      input: 'multiple-option'
    },
  ];


  allComplete: { [key: string]: boolean } = {};

  constructor(
    private utilityService: UtilityService,
    public dialog: MatDialog,
    private comitatiService: ComitatiService,
    private policyService: PolicyService,
  ) {

  }

  ngAfterViewInit(): void {
    this._getComitati();
  }

  public applicaFiltroRicerca(event: Event) {

    const filterValue = (event.target as HTMLInputElement).value;
    this.searchValue = filterValue;

    if (!this.searchValue || this.searchValue === '') {
      this.arrayGroupComitati = this._filteredArrayGroupComitati;
      return;
    }

    this.arrayGroupComitati = this._filteredArrayGroupComitati.map(item => {

      let figura = item.figureAziendali.filter((fig) =>
        (fig.nome.toLowerCase().includes(this.searchValue?.toLowerCase() || "") || fig.figura.toLowerCase().includes(this.searchValue?.toLowerCase() || ""))
      );

      return { ...item, figureAziendali: figura }

    }).filter((com) => com.figureAziendali.length);

  }


  public selezionaTutto() {

    this.btnSelezionaTutto = false;

    this.arrayGroupComitati.forEach((comitato) => {

      const filtFig = this._filteredArrayGroupComitati.find((filt) => {
        return filt.id === comitato.id;
      });

      comitato.figureAziendali.forEach((fig) => {
        fig.selected = true;

        let siltFig = filtFig?.figureAziendali.find((findFig) => {
          return findFig.id === fig.id;
        });

        if (siltFig) {
          siltFig.selected = true;
        }
      })

      this.updateAllComplete(comitato);
    })
  }


  public deselezionaTutto() {
    this.btnSelezionaTutto = true;

    this.arrayGroupComitati.forEach((comitato) => {

      const filtFig = this._filteredArrayGroupComitati.find((filt) => {
        return filt.id === comitato.id;
      });

      comitato.figureAziendali.forEach((fig) => {
        fig.selected = false;

        let siltFig = filtFig?.figureAziendali.find((findFig) => {
          return findFig.id === fig.id;
        });

        if (siltFig) {
          siltFig.selected = false;
        }
      })

      this.updateAllComplete(comitato);
    })
  }


  public apriFiltro() {

    const dialogFiltro = this.dialog.open(DialogFiltroGenericoComponent, {
      data: {
        filtri: this._filtri,
        valoriFiltro: this._valoriFiltro
      },
      panelClass: 'dialog-container',
      disableClose: false,
      width: '90%',
      maxHeight: '90%',
      autoFocus: false
    });

    dialogFiltro.afterClosed().subscribe(result => {
      if (result) {
        this._valoriFiltro = result.filtri;

        if (this._valoriFiltro) {

          const comitato = this._valoriFiltro.find((filt) => filt.chiave === 'comitati');

          this.arrayGroupComitati = this._filteredArrayGroupComitati.filter((com) => {
            if (comitato) {
              return comitato.values.includes(com.id);
            } else {
              return true;
            }
          }).map(item => {

            item.descrizione.toLowerCase().includes(this.searchValue.toLowerCase());

            const figFilt = item.figureAziendali.filter(fig => {

              return this._valoriFiltro.map((filtro) => {

                return this._checkFigureAziendali(fig, filtro);

              }).reduce((previous, check) => {

                return previous && check;

              }, true);

            });

            return { ...item, figureAziendali: figFilt };
          });
        }
      }
    });
  }

  private _checkFigureAziendali(figureAziendali: FiguraInComiato, filtro: Filters) {
    const comitatoInFigAz = figureAziendali[filtro.chiave as keyof FiguraInComiato];
    switch (filtro.operator) {
      case 'lk':
        const like = filtro.values.find((val) => {
          if (typeof comitatoInFigAz === 'string') {
            return comitatoInFigAz.includes(val);
          } else {
            return comitatoInFigAz === val;
          }
        })
        return !!like;
      case 'eq':
        return this._checkSingleFigureAziendale(comitatoInFigAz, filtro);
      default:
        return false;
    }
  }

  private _checkSingleFigureAziendale(comitatoInFigAz: any, filtro: Filters): boolean {
    if (typeof comitatoInFigAz === 'object' && 'id' in comitatoInFigAz) {
      // Se è un oggetto con un campo 'id', confronta l'id
      return filtro.values.includes((comitatoInFigAz as Comitati).id);
    } else {
      const idArray = (comitatoInFigAz as Comitati[]).map(comitato => comitato.id);
      return idArray.includes(filtro.values[0]);

    }
  }


  public updateAllComplete(comitati: ComitatiConFigAz): void {
    setTimeout(() => {
      this.allComplete[comitati.id] = comitati.figureAziendali?.length > 0 && comitati.figureAziendali.every(t => t.selected);
    }, 0);
  }

  public someComplete(comitati: ComitatiConFigAz): boolean {
    if (!comitati.figureAziendali) {
      return false;
    }

    this.nrSelezionati = comitati.figureAziendali.filter(figura => figura.selected).length;
    return !!this.nrSelezionati && this.nrSelezionati != comitati.figureAziendali.length;
  }

  public setAll(selected: boolean, comitati: ComitatiConFigAz) {
    if (!comitati.figureAziendali.length) {
      return;
    }

    comitati.figureAziendali.forEach(figura => (figura.selected = selected));

    //Se seleziono direttamente il comitato allora apro dialog di compilazione
    if (!comitati.risposta && selected) {
      this.compilaComitato(comitati);
    }

  }


  /**
   * Metodo che mi aggiunge una figura aziendale su un comitato 
   * @param comitato 
   * @returns 
   */
  public async insUpdFiguraAziendale(comitato: ComitatiConFigAz, figuraAziendale?: any) {

    await firstValueFrom(this.salvaStepComitati())

    const com = this._comitati.find((val) => val.id === comitato.id);

    if (!com) {
      this.utilityService.openDialog({
        titolo: 'Attenzione',
        descrizione: 'Comitato non trovato',
        bottoni: [{ nome_btn: 'Ok' }]
      });
      return;
    }

    const dialogCreaFigAz = this.dialog.open(DialogCreaFigureAziendeComponent, {
      data: {
        comitati: com,
        figAzienda: figuraAziendale
      },
      panelClass: 'dialog-container',
      disableClose: false,
      width: '70%',
      maxHeight: '95%',
      autoFocus: false,
    });
    dialogCreaFigAz.afterClosed().subscribe((result) => {
      if (result) {

        this.spinnerOver.show();

        this.getComitatiConFigureAziendali().subscribe((result) => {
          this.spinnerOver.hide();
        });

      }
    });
  }


  /**
   * lista dei comitati per visualizzazione in step della policy 
   */
  public getComitatiConFigureAziendali() {

    if (this.idPolicy) {
      return this.policyService.getComitatiPolicy(this.idPolicy).pipe(
        map((esito) => {
          this._comitati = esito;
          this.arrayGroupComitati = esito;
          this._filteredArrayGroupComitati = esito;

          this.arrayGroupComitati.forEach(com => this.updateAllComplete(com));

          this.arrayGroupComitati.sort((a, b) => {
            const aHasSelected = a.figureAziendali.some(fig => fig.selected);
            const bHasSelected = b.figureAziendali.some(fig => fig.selected);
            const aLength = a.figureAziendali.length;
            const bLength = b.figureAziendali.length;

            if (aHasSelected && bHasSelected) {
              // Se entrambi hanno figure selezionate, ordino per lunghezza in ordine decrescente
              return bLength - aLength;
            }

            if (aHasSelected) {
              // Se solo "a" ha selezionato le figure, "a" verrà ordinata per prima
              return -1;
            }

            if (bHasSelected) {
              // Se solo "b" ha selezionato le figure, "b" verrà ordinata per primo
              return 1;
            }

            // Se nessuno dei due ha selezionato le figure, ordino per lunghezza in ordine decrescente
            return bLength - aLength;
          });

          return true;
        }),
        catchError((err) => {
          this.utilityService.openDialog({
            titolo: 'Attenzione',
            descrizione: err?.error?.message || 'Errore reperimento Comitati',
            bottoni: [{ nome_btn: 'Ok' }]
          })
          console.error(err);

          return of(false)
        })
      )
    } else {
      return of(false);
    }
  }

  /**
   * Get comitati
   * @returns 
   */
  private _getComitati() {
    return this.comitatiService.getComitatiLibreriaAndAzienda(0, 1000).pipe(map((value) => value?.content || []));
  }

  async compilaComitato(comitato: ComitatiConFigAz) {

    await firstValueFrom(this.salvaStepComitati())

    const dialogCompilaComitato = this.dialog.open(CompilaComitatiComponent, {
      data: {
        comitato: comitato,
        idPolicy: this._idPolicy
      },
      panelClass: 'dialog-container',
      disableClose: true,
      width: '70%',
      maxHeight: '95%',
      autoFocus: false,
    });

    dialogCompilaComitato.afterClosed().subscribe((esito: any) => {
      if (esito) {

        this.spinnerOver.show();

        this.getComitatiConFigureAziendali().subscribe((result) => {
          this.spinnerOver.hide();
        });
      }
    });
  }

  /**
   * Salvataggio step Comitati 
   * @returns
   */
  public salvaStepComitati(): Observable<any> {

    this.spinnerOver.show();

    const comitatiSelezionati = this.arrayGroupComitati.filter(
      comitati => comitati.figureAziendali.some(
        figura => figura.selected
      )
    );

    if (this.idPolicy) {
      return this.policyService.putComitatiPolicySelezionati(this.idPolicy, comitatiSelezionati).pipe(
        map(() => {
          this.spinnerOver.hide();
          return true;
        }),
        catchError((err: any) => {
          console.error(err);
          this.utilityService.openDialog({
            titolo: 'Attenzione',
            descrizione: 'Errore salvataggio Comitati',
            bottoni: [{ nome_btn: 'Ok' }]
          });
          this.spinnerOver.hide();
          return of(false);
        })
      );
    } else {
      this.spinnerOver.hide();
      return of(false);
    }
  }

  /**
   * Un comitato diventa selected se almeno una figura è selezionata
   * @param figuraAziendale 
   * @returns 
   */
  isComitatoSelected(figuraAziendale: FiguraInComiato[]) {
    return figuraAziendale.some(fig => (fig.selected))
  }

  /**
   * Controllo se i comitati selezionati sono stati compilati
   */
  isComitatiSelezionatiCompilati(): boolean {
    const comitatoNonCompilato = this.arrayGroupComitati.some(comitato => {
      return !comitato.risposta && this.isComitatoSelected(comitato.figureAziendali);
    });

    if (comitatoNonCompilato) {
      this.utilityService.opneSnackBar('Compilare tutti i comitati selezionati', '', {
        duration: 2000,
        panelClass: ['red-snackbar']
      });
      return false;
    }
    return true;
  }

}
